
import { CxDataType, CxTableColumn, CxTableConfig } from "@/types/cx-table-config";
import { VariableDto } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            variables: [] as VariableDto,
            variableTypes: [] as any,
            variableValues: [] as any
        };
    },
    mounted() {
        this.loadVariableTypes()
    },
    computed: {
        variableTableConfig() {
            return new CxTableConfig([
                new CxTableColumn("name", this.$c("name")),
                new CxTableColumn("defaultValue", this.$c("globalDefaultValue"))
            ]);
        },
        variableValueTableConfig() {
            return new CxTableConfig([
                new CxTableColumn("variable", this.$t('variable', "lb"), CxDataType.GetName),
                new CxTableColumn("variableType", this.$t("variableLevel", 'lb'), CxDataType.GetName),
                new CxTableColumn("variableValueRels", this.$t("variableLevelRel", 'lb_pl'), CxDataType.GetNameFromArray),
                new CxTableColumn("value", this.$t("variableValue", 'lb')),
            ]);
        },
    },
    methods: {
        // Variables
        loadVariables(filters: any) {
            this.$store
                .dispatch("variable/getWithFilter", filters)
                .then((variables) => {
                    this.variables = variables;
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },

        // VariableTypes
        loadVariableTypes() {
            this.$store
                .dispatch("variableType/getAll")
                .then((variableTypes) => {
                    this.variableTypes = variableTypes;
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },
        onReorderVariableType(ev: any) {
            if (ev.value[ev.value.length - 1].name != "Global") return
            this.variableTypes = ev.value
            for (let i = 0; i < this.variableTypes.length; i++)
                this.variableTypes[i].sortOrder = i
        },

        onSaveVariableTypeOrder() {
            this.$store
                .dispatch("variableType/saveAll", this.variableTypes)
                .then(() => {
                    this.$cx.notifySaved(this.$c("sortOrder"));
                    this.loadVariableTypes()
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },

        // VariableValues
        loadVariableValues(filters: any) {
            this.$store
                .dispatch("variableValue/getWithFilter", filters)
                .then((variableValues) => {
                    this.variableValues = variableValues;
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },


        /**
         * detailed delete confirmation dialog for CxCrudTableFilter
         * listing all relevant relations to the entity
         */
        async onDeleteDialog(id:number, callback: Function)
        {
            (this.$refs["confirmDelete"] as any)?.onDelete(id, callback) 
        }
    },
});
