
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      searchTerm: "",
    };
  },
  computed: {
    gridItems() {
      let items = [
        {
          title: this.$c("basicData"),
          elements: [
            {
              label: this.$t("manufacturer", 'lb_pl'),
              description: this.$t("manufacturer", "desc"),
              meta: "Cisco IOS",
              icon: "pi pi-briefcase",
              to: "/manufacturer",
            },
            {
              label: this.$t("deviceFamily", "lb_pl"),
              description: this.$t("deviceFamily", "desc"),
              icon: "pi pi-sitemap",
              to: "/deviceFamily",
            },
            {
              label: this.$t("deviceModel", "lb_pl"),
              description: this.$t("deviceModel", "desc"),
              meta: "Cisco IOS",
              icon: "pi pi-server",
              to: "/deviceModel",
            },
            {
              label: this.$t("deviceRole", "lb_pl"),
              description: this.$t("deviceRole", "desc"),
              icon: "pi pi-sitemap",
              to: "/deviceRole",
            },
            {
              label: this.$t("deviceTemplate", "lb_pl"),
              description: this.$t("deviceTemplate", "desc"),
              icon: "pi pi-copy",
              to: "/deviceTemplate",
            },
            {
              label: this.$t("os", "lb_pl"),
              description: this.$t("os", "desc"),
              meta: 'Cisco IOS',
              icon: "pi pi-shield",
              to: "/os",
            },

            {
              label: this.$t("portType", "lb_pl"),
              description: this.$t("portType", "desc"),
              icon: "pi pi-sign-in",
              to: "/devicePortType",
            },

            {
              label: this.$t("portUse", "lb_pl"),
              description: this.$t("portUse", "desc"),
              icon: "pi pi-bolt",
              to: "/devicePortUse",
            },

            {
              label: this.$t("virtualNetwork", "lb_pl"),
              description: this.$t("virtualNetwork", "desc"),
              icon: "pi pi-sitemap",
              to: "/virtualNetwork",
            },
          ],
        },
        {
          title: this.$t("management", "layers"),
          elements: [
            {
              label: this.$t("locationCategory", "lb_pl"),
              description: this.$t("locationCategory", "desc"),
              icon: "pi pi-briefcase",
              to: "/location/category",
            },
            {
              label: this.$t("locationType", "lb_pl"),
              description: this.$t("locationType", "desc"),
              icon: "pi pi-briefcase",
              to: "/locationType",
            },
            {
              label: this.$t("logicalInterface", "lb_pl"),
              description: this.$t("logicalInterface", "desc"),
              icon: "pi pi-sitemap",
              to: "/logicalInterface",
            },
          ],
        },
        {
          title: this.$t("management", "misc"),
          elements: [
            {
              label: this.$t("contactType", "lb_pl"),
              description: this.$t("contactType", "desc"),
              icon: "pi pi-user",
              to: "/contactType",
            },
            {
              label: this.$t("role", "lb_pl"),
              description: this.$t("role", "desc"),
              icon: "pi pi-users",
              to: "/role",
            },
            {
              label: this.$t("loginProfile", "lb_pl"),
              description: this.$t("loginProfile", "desc"),
              icon: "pi pi-sign-in",
              to: "/loginProfile",
            },
            {
              label: this.$t("management", "settings"),
              description: this.$t("management", "settings_desc"),
              icon: "pi pi-sliders-v",
              to: "/settings",
            },
            {
              label: this.$t("management", "universalExtender"),
              description: this.$t("management", "universalExtender_desc"),
              icon: "pi pi-cloud-upload",
              to: "/universal",
            },
            {
              label: this.$t("apiParam", "lb"),
              description: this.$t("apiParam", "desc"),
              icon: "pi pi-arrow-right-arrow-left",
              to: "/apiParam",
            },
          ],
        },
      ];

      if (!this.$cx.isNullOrEmpty(this.searchTerm)) {
        items = items.filter((item: any) => {
          let elementsFound = item.elements.filter((element: any) => {
            return JSON.stringify(element)
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())
          })
          // Not found - reset item
          if (elementsFound.length == 0) return item = null;
          item.elements = elementsFound;

          return item;
        })
      }
      return items;
    },
  },
});
