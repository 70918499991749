import { DeviceTemplateRoleDto } from "@/types/dto";
import CxApi from "@/types/cx-api";

const entityName = "deviceTemplateRole";
const cxApi = new CxApi<DeviceTemplateRoleDto>(entityName);

export default {
    namespaced: true,
    state: {
        loading: false
    },
    actions: {
        async getAll(context: any) {
            context.state.loading = true;
            try {
                let response = await cxApi.getAll();
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async getById(context: any, id: number) {
            context.state.loading = true;
            try {
                let response = await cxApi.getById(id);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async save(context: any, entity: DeviceTemplateRoleDto) {
            context.state.loading = true;
            try {
                let response = await cxApi.save(entity);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async create(context: any, entity: DeviceTemplateRoleDto) {
            context.state.loading = true;
            try {
                let response = await cxApi.create(entity);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async delete(context: any, id: number) {
            context.state.loading = true;
            try {
                let response = await cxApi.delete(id);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        }
    }
};

