import { ApiParamDto, CxEntityType } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";
import { api } from "@/_config/api";

const url = "catalystCenter"

export default {
    namespaced: true,
    state: {
        loading: false,
    },
    actions: {
        async getSites(context: any, apiId: number) {
            context.state.loading = true;
            try {
                let response = await api.get(url + `/${apiId}/sites`);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async getLocationStatus(context: any, locationId: number) {
            context.state.loading = true;
            try {
                let response = await api.get(url + `/location/${locationId}/status`);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        }
    }
}
