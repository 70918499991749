import { ApprovalDto, CxEntityType, LocationDeviceDto } from "@/types/dto";
import { api } from "@/_config/api";
import CxBaseStoreModule from "@/types/cx-store-module";

class LocationDeviceStore extends CxBaseStoreModule<LocationDeviceDto> {

  constructor() {
    super("locationDevice")
    this.state.entityType = CxEntityType.LOCATION_DEVICE
  }

  async getWithFilter(context: any, props: any) {
    try {
      context.state.loading = true;
      let response = await api.get(
        `${context.state.entityName}/filter?locationId=${props.id}`,
        {
          params: {
            ...{
              page: props.page,
              size: props.size,
            },
            ...props.filters,
          },
        }
      );
      context.state.loading = false;
      return response.data;
    } catch (error) {
      console.error(error);
      context.state.loading = false;
      return Promise.reject(error);
    }
  }

  defineActions(actions: any) {
    return super.defineActions({
      configs: async (context: any, id: number) => {
        context.state.loading = true;
        try {
          let response = await api.get(`${context.state.entityName}/${id}/config`);
          context.state.loading = false;
          return response.data;
        } catch (error) {
          console.error(error);
          context.state.loading = false;
          return Promise.reject(error);
        }
      },
      generate: async (context: any, id: number) => {
        context.state.loading = true;
        try {
          let response = await api.get(`${context.state.entityName}/generate/${id}?save=false`);
          context.state.loading = false;
          return response.data;
        } catch (error) {
          console.error(error);
          context.state.loading = false;
          return Promise.reject(error);
        }
      },
      generateAndSave: async (context: any, id: number) => {
        context.state.loading = true;
        try {
          let response = await api.get(`${context.state.entityName}/generate/${id}?save=true`);
          context.state.loading = false;
          return response.data;
        } catch (error) {
          console.error(error);
          context.state.loading = false;
          return Promise.reject(error);
        }
      },
      getSerials: async (context: any, id: number) => {
        context.state.loading = true;
        try {
          let response = await api.get(`${context.state.entityName}/${id}/serial`);
          context.state.loading = false;
          return response.data;
        } catch (error) {
          console.error(error);
          context.state.loading = false;
          return Promise.reject(error);
        }
      },
      getForTree: async (context: any) => {
        context.state.loading = true;
        try {
          let response = await api.get(`${context.state.entityName}/tree`);
          context.state.loading = false;
          return response.data;
        } catch (error) {
          console.error(error);
          context.state.loading = false;
          return Promise.reject(error);
        }
      },
      getForPicker: async (context: any) => {
        context.state.loading = true;
        try {
          let response = await api.get(`${context.state.entityName}/picker`);
          context.state.loading = false;
          return response.data;
        } catch (error) {
          console.error(error);
          context.state.loading = false;
          return Promise.reject(error);
        }
      },
    })
  }
}

export default new LocationDeviceStore().createStore()