
import { CustomerDto } from "@/types/dto";
import { LanguageNumber } from "@/types/cx-language-number";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    id: String,
  },
  data() {
    return {
      entity: {} as CustomerDto,
      initialState: {} as CustomerDto,
      Language: LanguageNumber,
      depth: 0
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function (v) {
      this.load();
    },
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.$store
          .dispatch("customer/getById", this.id)
          .then((entity) => {
            this.entity = entity;
            if (this.entity.configs != null)
              if (this.entity.configs.length > 0) {
                this.entity.configs = this.$cx.cleanPath(this.entity.configs);
                this.depth = this.entity.configs[0].split("\\").length - 1;
              }

            let depthChanged = false;

            // detect mismatch in folder depth of mapping and adapt to new depth from the file list
            if (this.entity.configFilters) {
              if (this.entity.configFilters.length > 0) {
                let existingDepth = this.entity.configFilters.length;

                // depth of existing mapping is too deep, remove the last one(s)
                if (this.depth < existingDepth) {
                  this.entity.configFilters.splice(
                    this.depth,
                    existingDepth - this.depth
                  );
                  depthChanged = true;
                }
                // depth of existing mapping is too short, append new depth
                else if (this.depth > existingDepth) {
                  for (let i = 0; i < this.depth - existingDepth; i++) {
                    var placeholderArrayClone = JSON.parse(
                      JSON.stringify(this.entity.configFilters[0])
                    );
                    this.entity.configFilters.push(placeholderArrayClone);
                  }
                  depthChanged = true;
                }
              }
            }

            // no dropdown mapping found, create a new one
            if (this.entity.configFilters == null) {
              this.entity.configFilters = [];

              for (let x = 0; x < this.depth; x++) {
                let placeholderArray = [];

                // generate an entry for every language - FR has the highest index
                for (let y = 0; y < 3; y++) placeholderArray.push("");

                this.entity.configFilters.push({
                  placeholder: placeholderArray,
                });
              }
            }

            if (depthChanged) {
              this.$cx.warn(
                this.$t('customer', 'dropdownDepthChangedTitle'),
                this.$t('customer', 'dropdownDepthChangedText'),
                20000
              );
            }

            this.initialState = this.$cx.getState(this.entity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
      }
    },
    onSave() {
      if (this.isEditing) {
        this.$store
          .dispatch("customer/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("customer", "lb"));
            this.initialState = this.$cx.getState(this.entity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store
          .dispatch("customer/create", this.entity)
          .then((newEntity: CustomerDto) => {
            this.$cx.notifyCreated(this.$t("customer", "lb"));
            this.entity = newEntity;
            this.initialState = this.$cx.getState(this.entity);
            this.$cx.goToById("customerEditor", newEntity.id!);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    },
    onUpload() {
      (this.$refs as any)["cx-zip-input"].click();
    },
    onFileChanged(ev: any) {
      this.$cx.confirm(
        "Zip-Hochladen bestätigen",
        `Soll das Verzeichnis von diesem Kunden mit den Daten aus:
        
        ${ev.target.files[0].name}
        
        wirklich ersetzt werden?`,
        async () => {
          try {
            await this.$store.dispatch("customer/upload", {
              file: ev.target.files[0],
              id: this.id,
            });
            this.$cx.success(this.$t("customer", "upload1"), this.$t("customer", "upload2"));
            await this.load();
          } catch (ex) {
            this.$cx.error(ex, "Error processing Zip-File");
          }
        }
      );
    },
    onDownload() {
      this.$store
        .dispatch("customer/downloadAsZip", this.id)
        .then(() => {
          this.$cx.success(this.$t("customer", "download1"), this.$t("customer", "download2"));
        })
        .catch((error) =>
          this.$cx.error(error, this.$cx.e("downloadingTitle"), this.$cx.e("downloadingMessage"))
        );
    }
  },
});
