
import { defineComponent } from "vue";

export default defineComponent({
    computed: {
        gridItems() {
            return [
                {
                    title: this.$t('aci', 'header1'),
                    elements: [
                        {
                            label: this.$t('aci', 'ACI_EP_information'),
                            description: this.$t('aci', 'ACI_EP_information_desc'),
                            meta: "ep information",
                            icon: "pi pi-sitemap",
                            to: "/apics/epInformation",
                        },
                        {
                            label: this.$t('aci', 'getLogicalStructure'),
                            description: this.$t('aci', 'logicalStructure'),
                            meta: "logical structure",
                            icon: "pi pi-sitemap",
                            to: "/apics/logicalStructure",
                        },
                        {
                            label: this.$t('aci', 'getInterfacesWithError'),
                            description: this.$t('aci', 'interfacesWithError'),
                            meta: "interfaces with errors",
                            icon: "pi pi-sitemap",
                            to: "/apics/interfaceErrors",
                        },
                        {
                            label: this.$t('aci', 'getInterfacesByEPG'),
                            description: this.$t('aci', 'interfacesByEPG'),
                            meta: "",
                            icon: "pi pi-sitemap",
                            to: "/apics/interfacesForEpg",
                        },
                        {
                            label: this.$t('aci', 'getEPGByInterface'),
                            description: this.$t('aci', 'EPGsByInterface'),
                            meta: "",
                            icon: "pi pi-sitemap",
                            to: "/apics/interfaceEpgs",
                        }, {
                            label: this.$t('aci', 'verifyCOOP-DB'),
                            description: this.$t('aci', 'verifyCOOP-DB'),
                            meta: "",
                            icon: "pi pi-sitemap",
                            to: "/apics/spineCoopDb",
                        },
                    ]
                },
                {
                    title: this.$t('aci', 'header2'),
                    elements: [
                        {
                            label: this.$t('aci', 'getListOfTenants'),
                            description: this.$t('aci', 'getListOfTenants'),
                            meta: "",
                            icon: "pi pi-sitemap",
                            to: "/apics/tenants",
                        }, {
                            label: this.$t('aci', 'getAppProfilesList'),
                            description: this.$t('aci', 'getAppProfilesList'),
                            meta: "",
                            icon: "pi pi-sitemap",
                            to: "/apics/applicationProfiles",
                        }, {
                            label: this.$t('aci', 'getListAvailableEPGs'),
                            description: this.$t('aci', 'getListAvailableEPGs'),
                            meta: "",
                            icon: "pi pi-sitemap",
                            to: "/apics/epgs",
                        },
                    ]
                },
                {
                    title: this.$t('aci', 'mgt_others'),
                    elements: [
                        {
                            label: this.$t('aci', 'APIC_settings'),
                            description: this.$t('aci', 'manageAndRegisterAPICs'),
                            meta: "apic einstellungen",
                            icon: "pi pi-briefcase",
                            to: "/apics/settings",
                        },
                    ]
                }]
        }
    }
});
