
import { CxDropdownOptions, CxDropdownOptionsApi } from "@/types/cx-dropdown-options";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
    },
    optionLabel: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    store: {
      type: String,
    },
    dropdownOptions: {
      type: Object as PropType<CxDropdownOptionsApi>,
      default: null
    },
    filters: {
      type: Object,
      default: null,
    },
    overrideSlot: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loadFn: {
      type: Function,
      default: undefined
    }
  },
  data() {
    return {
      id: this.$cx.createUid(),
      options: [],
      cachedFilter: null as any,
      isLoading: false
    };
  },
  watch: {
    filters: function (v: any) {
      if (!this.$cx.isEqual(this.cachedFilter, v) && !this.disabled) {
        this.load();
      }
      this.cachedFilter = v;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (this.filters != null) {
        this.isLoading = true
        this.$store
          .dispatch(this.store + "/getAllByFilter", this.filters)
          .then((options) => {
            this.options = options;
            if (this.modelValue != null && this.options.length > 0 && !this.$cx.contains(this.modelValue.id, this.options, "id"))
              this.$emit("update:modelValue", null);
          }).finally(() => {
            this.isLoading = false
          })
      } else {
        this.isLoading = true
        this.$store.dispatch(this.store + "/getAll").then((options) => {
          this.options = options;
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
    onDropdownOptions($event: any) {
      this.$store.state.ui.dropdownOptions = {
        reloadFn: () => {
          this.selectedValue = undefined
          this.load()
        },
        value: this.selectedValue,
        options: this.dropdownOptions,
        event: $event
      } as CxDropdownOptions
    }
  },
  computed: {
    selectedValue: {
      get() {
        return this.modelValue;
      },
      set(v: any) {
        this.$emit("update:modelValue", v);
      },
    },
  },
});
