import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"-5px"} }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { class: "grid" }
const _hoisted_7 = { class: "col-2" }
const _hoisted_8 = { class: "cx-vartree-node" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Tree = _resolveComponent("Tree")!
  const _component_cx_code_input = _resolveComponent("cx-code-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('name'),
          v$: _ctx.v$.entity.name,
          approvalState: _ctx.approvalState,
          prop: "name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('name_ph'),
              modelValue: _ctx.entity.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.name) = $event)),
              disabled: !_ctx.approvalState.formEnabled || _ctx.entity.locked
            }, null, 8, ["placeholder", "modelValue", "disabled"])
          ]),
          _: 1
        }, 8, ["label", "v$", "approvalState"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('type'),
          approvalState: _ctx.approvalState,
          prop: "type"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Dropdown, {
              placeholder: _ctx.$c('type_ph'),
              "option-label": "label",
              "option-value": "value",
              options: _ctx.typeOptions,
              modelValue: _ctx.entity.type,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.type) = $event)),
              disabled: !_ctx.approvalState.formEnabled || _ctx.entity.locked
            }, null, 8, ["placeholder", "options", "modelValue", "disabled"])
          ]),
          _: 1
        }, 8, ["label", "approvalState"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('requirements'),
          approvalState: _ctx.approvalState,
          prop: "requirements"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Textarea, {
              placeholder: _ctx.$c('requirements_ph'),
              rows: "1",
              modelValue: _ctx.entity.requirements,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.requirements) = $event)),
              disabled: !_ctx.approvalState.formEnabled
            }, null, 8, ["placeholder", "modelValue", "disabled"])
          ]),
          _: 1
        }, 8, ["label", "approvalState"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_cx_label, {
          label: _ctx.$t('configuration', 'variable'),
          nomargin: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Tree, {
              class: "cx-vartree",
              value: _ctx.variableGraph,
              filter: true,
              filterMode: "lenient",
              scrollHeight: "calc(100vh - 265px)",
              style: {"min-height":"calc(100vh - 205px)"},
              filterPlaceholder: _ctx.$c('filter')
            }, {
              default: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", null, _toDisplayString(slotProps.node.label), 1),
                  (slotProps.node.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(slotProps.node.value), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["value", "filterPlaceholder"])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('code'),
          approvalState: _ctx.approvalState,
          prop: "code",
          nomargin: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cx_code_input, {
              height: "calc(100vh - 205px)",
              modelValue: _ctx.entity.code,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.code) = $event)),
              lang: "python",
              disabled: !_ctx.approvalState.formEnabled
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _: 1
        }, 8, ["label", "approvalState"])
      ])
    ])
  ]))
}