
import {
    CxDataType,
    CxTableColumn,
    CxTableConfig,
} from "@/types/cx-table-config";
import { defineComponent } from "vue";

export default defineComponent({
    props: {},
    data() {
        return {
            acis: [],
            templates: [] as any
        };
    },
    computed: {
        tableConfig() {
            return new CxTableConfig([
                new CxTableColumn("groupRef", this.$c('groupRef')),
                new CxTableColumn("hostname", this.$c('hostname')),
                new CxTableColumn("ipAddress", this.$c('ipAddress')),
                new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
            ])
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            this.$store
                .dispatch("ciscoAci/getAcis")
                .then((ciscoAci) => {
                    this.acis = ciscoAci;
                });
        }
    },
});
