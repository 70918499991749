
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        modelValue: {
            type: Object,
            default: {}
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            objArr: [] as any
        };
    },
    watch: {
        modelValue() {
            this.objArr.length = 0
            if (this.modelValue == null) return
            Object.keys(this.modelValue).forEach(key => {
                this.objArr.push({
                    key: key,
                    value: this.modelValue[key]
                })
            })
        }
    },
    computed: {
        objValue() {
            let obj = {} as any
            this.objArr.forEach((o: any) => {
                obj[o.key] = o.value
            })
            return obj
        }
    },
    methods: {
        onAdd() {
            this.objArr.push({
                key: 'Name ' + this.$cx.createUid(),
                value: 'Wert'
            })
            this.updateValue()
        },
        onRemove(arr: any, obj: any) {
            this.$cx.removeFrom(arr, obj, "key")
            this.updateValue()
        },
        updateValue() {
            this.$emit("update:modelValue", this.objValue)
        }
    }
});
