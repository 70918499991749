import axios from "axios";

const apiExtender = axios.create({
  baseURL: (window as any).env?.VUE_APP_EXTENDER_API_URL || process.env.VUE_APP_EXTENDER_API_URL,
  headers: {
    LANG: "",
    Authorization: "",
  },
});

/* if (process.env.VUE_APP_USE_AUTH != 1) {
  delete ((apiExtender.defaults.headers as any)["Authorization"])
  apiExtender.defaults.withCredentials = false
} */

export { apiExtender };
