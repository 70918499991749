
import { CodeTemplateDto, CxApprovalState, CxEntityType, LocationDevice } from "@/types/dto";
import { defineComponent } from "vue";
import { CxFormApi } from "@/types/cx-form-api";
import { ApprovalState } from "@/types/approval-state";

export default defineComponent({
  props: {
    id: String,
  },
  data() {
    return {
      dialogCodeTemplateText: "",
      dialogLocationDevice: {} as LocationDevice,
      isTemplateDialogVisible: false,
      entity: {} as CodeTemplateDto,
      initialEntity: {} as CodeTemplateDto,
      codeTemplateApi: {} as CxFormApi,
      approvalState: new ApprovalState(CxEntityType.CODE_TEMPLATE)
    };
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.codeTemplateApi.notifyUnsavedChanges(next)
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.codeTemplateApi.load(this.id);
        this.approvalState.setStore("codeTemplate", this.id!)
        this.approvalState.fetchApprovalData()
      }
    },
    async save(goBack: Boolean) {
      this.codeTemplateApi.onSave((newEntity: CodeTemplateDto) => {
        if (this.isEditing) {
          if (goBack) this.$cx.goTo("codeTemplate");
        } else {
          if (goBack) this.$cx.goTo("codeTemplate");
          else this.$cx.goToById("codeTemplateEditor", newEntity.id!);
        }
      })
    },
    onApprovalViewChanged(data: any, isViewingChanges: boolean) {
      if (isViewingChanges) {
        (this.$refs as any)['approval'].apply(this.entity, data)
      } else {
        (this.$refs as any)['approval'].apply(this.entity, (this.$refs as any)['codeTemplateForm'].initialEntity)
      }
    },

    onApprovalChanged(state: CxApprovalState) {
      (this.$refs as any)['approval'].reset()
      this.load()
    },

    onEntityChanged(entity: any, initialEntity: any) {
      this.entity = entity
      this.initialEntity = initialEntity
    },
    onCalculateTemplate() {
      if (this.dialogLocationDevice.id == null) return
      if (this.dialogLocationDevice.id < 1) return
      this.$store.dispatch("codeTemplate/calculateTemplate", {
        deviceId: this.dialogLocationDevice.id,
        templateId: this.id
      })
        .then((data) => {
          this.dialogCodeTemplateText = data;
          (this.$refs as any)["code"].forceUpdateValue(this.dialogCodeTemplateText);
          this.$cx.success(this.$c('successful'), this.$t('codeTemplate', "successCalculatedTemplate"));
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
    }
  },
});
