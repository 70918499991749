
import { defineComponent, ref } from "vue";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import SoftwareCompliance from './SoftwareCompliance.vue';
import SoftwareManagementTable from './SoftwareManagementTable.vue';
import { CxTableConfig, CxTableColumn } from "@/types/cx-table-config";

export default defineComponent({
    props: {
        id: String,
    },
    components: {
        SoftwareCompliance,
        SoftwareManagementTable
    },
    data() {
        return {
            softwareTableData: [] as any,
            isSoftwareGlobalTableView: false,
            isSoftwareDetailDialogVisible: false,
            softwareComplianceFilters: ref({
                global: { value: null, matchMode: 'CONTAINS' },
            }),
            chartsSoftwareByLocationsData: {} as any,
            barChartOptions: {
                responsive: false,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        },
                        position: 'right'
                    },
                    datalabels: {
                        color: 'white',
                        labels: {
                            title: {
                                color: 'white'
                            }
                        }
                    }
                },
            },
            filters: {
                roles: [],
                models: [],
                families: [],
                locations: [],
                softwareVersions: []
            },

            configComplianceData: {
                labels: ['C9300-48', 'C9200-12'],
                datasets: [
                    {
                        label: 'Valid',
                        backgroundColor: '#8FD400',
                        borderColor: 'black',
                        data: [25, 15]
                    },
                    {
                        label: 'Invalid',
                        backgroundColor: '#fab710',
                        borderColor: 'black',
                        data: [10, 20]
                    },
                    {
                        label: 'Unknown',
                        backgroundColor: '#bbbbbb',
                        borderColor: 'black',
                        data: [5, 10]
                    }
                ],
            },
            chartPlugin: ChartDataLabels
        };
    },
    computed: {
        softwareComplianceTableConfig() {
            return new CxTableConfig([
                new CxTableColumn("hersteller", "Hersteller"),
                new CxTableColumn("geraeteModel", "Geräte Model"),
                new CxTableColumn("gesamtSumme", "Gesamt Summe"),
                new CxTableColumn("valid", "Valid"),
                new CxTableColumn("invalid", "Invalid"),
                new CxTableColumn("complianceAnzahl", "Compliance in Anzahl"),
            ]);
        },
        chartsSoftwareByLocations() {
            let arr = [] as any[];
            this.addGlobalPieChart(arr)

            for (var [key, value] of Object.entries<any>(this.chartsSoftwareByLocationsData)) {
                let data = [] as any[]
                let labels = [] as string[]
                let items = value.items as any

                for (let i = 0; i < Object.keys(items).length; i++) {
                    data[i] = Object.values(items)[i]
                    labels[i] = `${Object.keys(items)[i]} (${data[i]})`
                }

                let colorIndex = -1
                let colors = this.$cx.generateColors(Math.max(data.length, 6))

                arr.push({
                    name: key,
                    locationId: value.locationId,
                    data: {
                        labels: labels,
                        datasets: [
                            {
                                data: data,
                                backgroundColor: Array.from({ length: colors.length }, () => colors[++colorIndex])
                            }
                        ]
                    }
                })
            }
            return arr
        }
    },
    watch: {
        filters: {
            handler: function (v: any) {
                this.load()
            },
            deep: true
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            this.$store.dispatch("dashboard/getSoftwareDashboardData", {
                locationIds: this.filters.locations.map((l: any) => l.id),
                versionNumbers: this.filters.softwareVersions.map((s: any) => s.version),
                deviceFamilyIds: this.filters.families.map((df: any) => df.id),
                deviceModelIds: this.filters.models.map((dm: any) => dm.id),
                deviceRoleIds: this.filters.roles.map((dr: any) => dr.id)
            })
                .then((data) => {
                    let pieChartData = {} as any;
                    this.softwareTableData = data;
                    data.forEach((item: any) => {
                        if (!(item.locationName in pieChartData))
                            pieChartData[item.locationName] = {
                                items: { "No Version": 0 },
                                locationId: item.locationId
                            }

                        if (item.sourceVersion == null) {
                            pieChartData[item.locationName].items["No Version"]++
                        } else {
                            if (!(item.sourceVersion in pieChartData[item.locationName].items))
                                pieChartData[item.locationName].items[item.sourceVersion] = 0
                            pieChartData[item.locationName].items[item.sourceVersion]++
                        }
                    })
                    this.chartsSoftwareByLocationsData = pieChartData
                    if (this.isSoftwareGlobalTableView)
                        this.$nextTick(() =>
                            (this.$refs as any)['softwareGlobalTable'].load())
                })
        },
        onLoadSoftwareVersions() {
            return this.$store.dispatch("dashboard/getSoftwareVersions")
        },
        onShowSoftwareDetailDialog(chart: any) {
            this.isSoftwareDetailDialogVisible = true;
            this.$nextTick(() =>
                (this.$refs as any)['softwareDialogTable'].load(chart.locationId))
        },
        onSoftwareGlobalTableChanged(ev: any) {
            if (this.isSoftwareGlobalTableView)
                this.$nextTick(() =>
                    (this.$refs as any)['softwareGlobalTable'].load())
        },
        addGlobalPieChart(arr: any[]) {
            if (Object.values(this.chartsSoftwareByLocationsData).length < 1) return
            if (this.filters.locations.length < 1
                && this.filters.softwareVersions.length < 1
                && this.filters.families.length < 1
                && this.filters.models.length < 1
                && this.filters.roles.length < 1) {

                let globalData = {} as { [key: string]: any }
                Object.values(this.chartsSoftwareByLocationsData).forEach((i: any) => {
                    for (const [key, count] of Object.entries(i.items)) {
                        if (!(key in globalData)) globalData[key] = 0
                        globalData[key] += count
                    }
                })

                let globalColorIndex = -1;
                let globalColors = this.$cx.generateColors(Math.max(Object.keys(globalData).length, 6));

                arr.push({
                    name: 'Global',
                    locationId: null,
                    data: {
                        labels: Object.entries(globalData).map(e => `${e[0]} (${e[1]})`),
                        datasets: [
                            {
                                data: Object.values(globalData),
                                backgroundColor: Array.from({ length: globalColors.length }, () => globalColors[++globalColorIndex])
                            }
                        ]
                    }
                })
            }
        }
    }
})
