import { UniversalExtenderDto } from "@/types/dto";
import CxApi from "@/types/cx-api";
import { apiExtender } from "@/_config/api-extender";

const entityName = "extender/api/v1";
const cxApi = new CxApi<UniversalExtenderDto>(entityName);

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    async getImportTemplate(context: any) {
      context.state.loading = true;
      try {
        let response = await apiExtender.get(entityName + `/importTemplate`);
        let attachment =
          response.headers["content-disposition"] ||
          response.headers["Content-Disposition"];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(
          attachment!
        )![0];
        filename = filename.split("=")[1].replaceAll('"', "");
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        context.state.loading = false;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async getExport(context: any) {
      context.state.loading = true;
      try {
        let response = await apiExtender.get(entityName + `/export?type=CSV`);
        let attachment =
          response.headers["content-disposition"] ||
          response.headers["Content-Disposition"];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(
          attachment!
        )![0];
        filename = filename.split("=")[1].replaceAll('"', "");
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        context.state.loading = false;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async importData(context: any, payload: any) {
      context.state.loading = true;
      try {
        let url = `/import/verify?delimiter=${payload.delimiter}`;
        if (payload.save) {
          url = `/import/save?delimiter=${payload.delimiter}`;
        }
        let response = await apiExtender.post(entityName + url, payload.file);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async importCSV(context: any, payload: any) {
      try {
        let data = new FormData()
        data.append('delimiter', payload.delimiter)
        data.append('charset', payload.charset)
        data.append('file', payload.file)
        let response = await apiExtender.post(`${entityName}/import/csv?verify=${payload.verify}`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'LANG': "en"
          }
        });
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
  },
};
