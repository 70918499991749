import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_cx_label, {
      label: _ctx.$t('deviceModel', 'lb'),
      v$: _ctx.v$.entity.deviceTemplateModel
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_dropdown, {
          modelValue: _ctx.entity.deviceTemplateModel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.deviceTemplateModel) = $event)),
          store: "deviceTemplateModel",
          disabled: true,
          placeholder: _ctx.$t('deviceModel', 'ph'),
          filters: {
            locationDeviceId: parseInt(_ctx.locationDeviceId || '-1')
        }
        }, {
          option: _withCtx(({ option }) => [
            _createElementVNode("span", null, _toDisplayString(option.deviceModel.name), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "placeholder", "filters"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('serialNumber', 'lb'),
      v$: _ctx.v$.entity.serialNumber
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          placeholder: _ctx.$t('serialNumber', 'ph'),
          modelValue: _ctx.entity.serialNumber,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.serialNumber) = $event))
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('deviceTemplateModel', 'stackPriority')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputNumber, {
          modelValue: _ctx.entity.stackPriority,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.stackPriority) = $event)),
          format: false,
          min: 0,
          max: 999999,
          disabled: true
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}