
import { DeviceModelDto, LocationCategoryRoleDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { CxFormApi } from "@/types/cx-form-api";
import { defineComponent, PropType } from "vue";
import { required } from "@/_config/ui-framework";

export default defineComponent({
  props: {
    editorApi: {
      type: Object as PropType<CxFormApi>,
    },
    locationCategoryId: String,
  },
  data() {
    return {
      id: -1 as number,
      entity: {} as LocationCategoryRoleDto,
    };
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {
        deviceRole: { required, $autoDirty: true },
        minCount: { required, $autoDirty: true },
        maxCount: { required, $autoDirty: true },
      },
    };
  },
  computed: {
    isEditing() {
      return this.id > 0;
    },
  },
  created() {
    this.editorApi!.onSave = async (closeFn: any) => {
      if (await this.$cx.notifyValidationError(this.v$)) return false;

      if (this.entity.minCount! > this.entity.maxCount!) {
        this.$cx.warn("Min. Anzahl größer als max. Anzahl", `Die min. Anzahl von ${this.entity.minCount} muss vor ${this.entity.maxCount} liegen`)
        return
      }

      if (this.isEditing) {
        this.$store
          .dispatch("locationCategory/saveRole", { id: this.locationCategoryId, entity: this.entity })
          .then(() => {
            this.$cx.notifySaved(this.$t("locationCategoryRole", "lb"));
            closeFn();
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store
          .dispatch("locationCategory/createRole", { id: this.locationCategoryId, entity: this.entity })
          .then(() => {
            this.$cx.notifyCreated(this.$t("locationCategoryRole", "lb"));
            closeFn();
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    };
    this.editorApi!.load = (id: number) => {
      this.id = id;
      this.$store.dispatch("locationCategory/getRoleById", id).then((entity) => {
        this.entity = entity;
      });
    };
    this.editorApi!.delete = (id: number) => {
      this.$store.dispatch("locationCategory/deleteRoleById", id).then(() => {
        this.$cx.notifyDeleted(this.$t("locationCategoryRole", "lb"));
      });
    };
    this.editorApi!.reset = () => {
      this.entity = {} as DeviceModelDto;
    };
    this.editorApi!.isEditing = () => {
      return this.id > 0;
    };
  },
});
