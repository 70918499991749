import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cx-compliance-line cx-compliance-line-selected"
}
const _hoisted_2 = { class: "cx-compliance-line-no" }
const _hoisted_3 = { class: "cx-compliance-line-no" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_compliance_view = _resolveComponent("cx-compliance-view", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.skipChildren)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.source?.line || 0), 1),
          _createElementVNode("div", {
            style: _normalizeStyle('padding-left:' + _ctx.source?.level || 1 * 10.0 + 'px')
          }, _toDisplayString(_ctx.source?.text || '* Missing *'), 5)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.skipChildren)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.source?.children || [], (child, c) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: c }, [
            _createElementVNode("div", {
              class: _normalizeClass('cx-compliance-line ' + _ctx.getClass(child))
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(child.line), 1),
              _createElementVNode("div", {
                style: _normalizeStyle('padding-left:' + child.level * 10.0 + 'px')
              }, _toDisplayString(child.text), 5)
            ], 2),
            _createVNode(_component_cx_compliance_view, {
              source: child,
              errors: _ctx.errors,
              isSource: _ctx.isSource
            }, null, 8, ["source", "errors", "isSource"])
          ], 64))
        }), 128))
      : _createCommentVNode("", true)
  ], 64))
}