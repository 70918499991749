
import { DashboardStatsDto } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      stats: {} as DashboardStatsDto,
      locationsByCategories: {},
      devicesByLocations: {},
      subnetsByPools: {},
      chartData: {} as any,
      doughnutOptions: {
        responsive: false,
        cutout: 60,
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            },
            position: 'right'
          }
        }
      },
      lineOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      },
      colorIndex: -1,
      colorSize: 20,
      colors: [
        "#009DDC",
        "#0690D0",
        "#0D83C3",
        "#1476B6",
        "#1B69A9",
        "#225C9C",
        "#294F8F",
        "#314282",
        "#383575",
        "#3F2868",
        "#462B5B",
        "#4D1E4E",
        "#541141",
        "#5B042D",
        "#610819",
        "#681006",
        "#6F0700",
        "#760600",
        "#7D0500",
        "#840400"
      ]
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    lineData() {
      let jobsOverTime = this.chartData["jobs_over_time"]

      let data = { labels: [], datasets: [] } as any
      data.labels = jobsOverTime?.map((m: any) => m.name)

      /*this.lineStylesData.datasets.push({
        label: 'Jobs nach Lokation',
        data: jobsByLocations.map((m: any) => m.count),
        fill: false,
        tension: .4,
        borderColor: '#009ddc'
      })

      this.lineStylesData.datasets.push({
        label: 'Geräte über Zeit',
        data: devicesOverTime.map((d: any) => d.count * 100),
        fill: false,
        borderDash: [5, 5],
        tension: .4,
        borderColor: '#24387f'
      })*/
      data.datasets.push({
        label: this.$t('dashboard', 'byTime'),
        data: jobsOverTime?.map((m: any) => m.count),
        fill: true,
        borderColor: '#8FD400',
        tension: .4,
        backgroundColor: 'rgba(143,212,0,0.2)'
      })
      return data
    }
  },
  methods: {
    load() {
      this.$store
        .dispatch("dashboard/getStats")
        .then((stats: DashboardStatsDto) => {
          this.stats = stats
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
      this.$store
        .dispatch("dashboard/getChartData")
        .then((chartData: any) => {
          this.locationsByCategories = this.computeChartData("locations_by_categories", chartData)
          this.devicesByLocations = this.computeChartData("devices_by_locations", chartData)
          this.subnetsByPools = this.computeChartData("subnets_by_pools", chartData)
          this.chartData = chartData
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    computeChartData(key: string, chartData: any) {
      this.colorIndex = -1
      let labels = {} as any
      if (!(key in chartData)) return {}
      chartData[key].forEach((c: any) => {
        labels[c.name] = c.count
      })

      if (Object.values(labels).length > this.colorSize) {
        try {
          this.colors = this.$cx.generateColors(Object.values(labels).length)
        } catch (err) {
          console.error(err)
        } finally {
          this.colorSize = Object.values(labels).length
        }
      }

      return {
        labels: Object.keys(labels),
        datasets: [
          {
            data: Object.values(labels),
            backgroundColor: Object.values(labels).map((l: any) => this.colors[++this.colorIndex])
          }
        ]
      }
    }
  },
});
