import { api } from "@/_config/api";
import CxApi from "@/types/cx-api";

const entityName = "update";
const cxApi = new CxApi<any>(entityName);

export default {
  namespaced: true,
  state: {
    loading: false
  },
  actions: {
    async getLatest(context: any) {
      try {
        let response = await api.get(cxApi.entityName + `/latest`);
        return response.data;
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    async downloadLatest() {
      try {
        let response = await api.get(cxApi.entityName + `/download`, {
          responseType: 'arraybuffer',
          headers: {
            'Accept': 'application/octet-stream'
          }
        });
        const filename = (response as any).headers["content-disposition"].split('filename=')[1].split(';')[0].replaceAll("\"", "")
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    async upload(context: any, files: File[]) {
      try {
        context.state.loading = true;
        let formData = new FormData();
        if (files.length > 0) formData.append('file', files[0]);
        else throw "Invalid file"
        let response = await api.post(cxApi.entityName + `/upload`, formData);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    }
  },
};
