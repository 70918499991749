
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";
import { CxDefaultPermissions } from "@/types/cx-permission"
import { defineComponent } from "vue";
import { UpdateRoleRequest } from "@/types/dto";

export default defineComponent({
  props: {},
  data() {
    return {
      role: {} as any,
      roles: [],
      filters: { global: {} } as any,
      isRoleEditorVisible: false,
      roleEntity: {} as UpdateRoleRequest,
      isEditingRole: false,

      users: [],
      userEntity: {} as any,
      isUserEditorVisible: false
    }
  },
  computed: {
    tableConfig() {
      let tc = new CxTableConfig([
        new CxTableColumn("name", this.$t("role", 'lb'))
      ]);
      tc.filters = this.filters;
      return tc;
    },
    userTableConfig() {
      return new CxTableConfig([
        new CxTableColumn("username", this.$c("user")),
        new CxTableColumn("roles", this.$t("role", 'lb_pl'), CxDataType.GetNameFromArray)
      ]);
    },
    roleOptions() {
      return this.roles
    }
  },
  mounted() {
    this.load();
    this.loadUsers();
  },
  methods: {
    load() {
      this.$store
        .dispatch("role/getAll")
        .then((roles) => {
          this.roles = roles;
          this.roles.forEach((role: any) => {
            if (role.attributes.permissions != null) {
              if (!this.$cx.isNullOrEmpty(role.attributes.permissions[0])) {
                role.permissions = role.attributes.permissions[0]
              } else {
                role.permissions = CxDefaultPermissions
              }
            }
          })
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },

    // Role Create and Edit
    onRoleSaved() {
      if (this.isEditingRole) {
        this.$store.dispatch("role/save", this.roleEntity)
          .then(() => {
            this.isRoleEditorVisible = false
            this.load()
            this.loadUsers()
            this.$cx.notifySaved(this.$t("role", "lb"));
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store.dispatch("role/create", this.roleEntity)
          .then(() => {
            this.isRoleEditorVisible = false
            this.load()
            this.loadUsers()
            this.$cx.notifyCreated(this.$t("role", "lb"));
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }

    },
    onRoleEditClosed() {
      this.isRoleEditorVisible = false
    },
    onRoleEditOpened(role: any) {
      this.roleEntity = {
        oldName: role.name,
        newName: role.name,
        permissions: role.permissions
      }
      this.isEditingRole = true
      this.isRoleEditorVisible = true
    },
    onRoleCreateOpened() {
      this.roleEntity = {}
      this.isEditingRole = false
      this.isRoleEditorVisible = true
    },
    onRoleConfirmDelete(role: any) {
      this.$cx.confirmDelete(() => {
        this.$store.dispatch("role/delete", role)
          .then(() => {
            this.load()
            this.loadUsers()
            this.$cx.notifyDeleted(this.$t("role", "lb"));
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
      })
    },

    // User section
    loadUsers() {
      this.$store
        .dispatch("role/getAllUsers")
        .then((users) => {
          this.users = users;
          /*this.roles.forEach((role: any) => {
            if (role.attributes.permissions != null) {
              if (!this.$cx.isNullOrEmpty(role.attributes.permissions[0])) {
                role.permissions = role.attributes.permissions[0]
              } else {
                role.permissions = CxDefaultPermissions
              }
            }
          })*/
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onUserEditOpened(user: any) {
      this.isUserEditorVisible = true
      this.userEntity = this.$cx.deepClone(user)
    },
    onUserEditClosed() {
      this.isUserEditorVisible = false
    },
    onUserSaved() {
      this.$store.dispatch("role/saveUser", this.userEntity)
        .then(() => {
          this.isUserEditorVisible = false
          this.load()
          this.loadUsers()
          this.$cx.notifySaved(this.$t("user", "lb"));
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
    },
    onRemoveRole(value: any) {
      this.$cx.removeFrom(this.userEntity.roles as any[], value, "id");
    }
  },
});
