import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-2" }
const _hoisted_3 = { class: "cx-cfg-version" }
const _hoisted_4 = { class: "col-5" }
const _hoisted_5 = { class: "mb-1 flex justify-content-end" }
const _hoisted_6 = { class: "col-5" }
const _hoisted_7 = { class: "mb-1 flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_cx_code_input = _resolveComponent("cx-code-input")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: false,
    title: _ctx.$t('configuration', 'wizardTitle')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_cx_label, {
            label: _ctx.$t('locationDevice', 'lb_pl'),
            style: {"margin-top":"12px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cx_dropdown, {
                store: "locationDevice",
                placeholder: _ctx.$t('locationDevice', 'ph_pl'),
                optionLabel: "name",
                modelValue: _ctx.selectedLocationDevice,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLocationDevice) = $event)),
                onChange: _ctx.onLocationDeviceChanged
              }, null, 8, ["placeholder", "modelValue", "onChange"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_Listbox, {
            modelValue: _ctx.selectedConfiguration,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedConfiguration) = $event)),
            options: _ctx.configurations,
            onChange: _ctx.onConfigurationSelected,
            listStyle: "max-height:calc(100vh - 180px)"
          }, {
            option: _withCtx((props) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", null, [
                  _createElementVNode("b", null, _toDisplayString(props.option.filename) + " (" + _toDisplayString(props.option.version) + ")", 1)
                ]),
                _createElementVNode("p", null, _toDisplayString(props.option.source) + ", " + _toDisplayString(props.option.status), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$cx.toTime(props.option.createdAt)), 1)
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "options", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Button, {
              icon: "pi pi-angle-double-right",
              class: "align-self-end",
              label: _ctx.$t('configuration', 'copySelection'),
              onClick: _ctx.onCopySelection
            }, null, 8, ["label", "onClick"])
          ]),
          _createVNode(_component_cx_code_input, {
            height: "calc(100vh - 140px)",
            modelValue: _ctx.selectedConfiguration.text,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedConfiguration.text) = $event)),
            lang: "python",
            ref: "configText"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Button, {
              icon: "pi pi-undo",
              class: "align-self-end mr-1",
              onClick: _ctx.undo
            }, null, 8, ["onClick"]),
            _createVNode(_component_Button, {
              icon: "pi pi-window-maximize",
              class: "align-self-end",
              label: _ctx.$t('configuration', 'createSnippet'),
              onClick: _ctx.openConfig
            }, null, 8, ["label", "onClick"])
          ]),
          _createVNode(_component_cx_code_input, {
            height: "calc(100vh - 140px)",
            modelValue: _ctx.newConfigurationText,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newConfigurationText) = $event)),
            lang: "python",
            ref: "newConfigText"
          }, null, 8, ["modelValue"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}