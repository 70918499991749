import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_cx_label, {
      label: _ctx.$c('name'),
      v$: _ctx.v$.entity.name
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          placeholder: _ctx.$c('name_ph'),
          modelValue: _ctx.entity.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.name) = $event)),
          onBlur: _ctx.onNameChanged,
          disabled: _ctx.disabled
        }, null, 8, ["placeholder", "modelValue", "onBlur", "disabled"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$c('globalDefaultValue')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Textarea, {
          placeholder: _ctx.$c('globalDefaultValue_ph'),
          modelValue: _ctx.entity.defaultValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.defaultValue) = $event)),
          disabled: _ctx.disabled
        }, null, 8, ["placeholder", "modelValue", "disabled"])
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}