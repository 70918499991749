import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.visible) = $event)),
    header: _ctx.$t('ipPool', 'createSubnet'),
    modal: true,
    closable: false,
    class: "custom-dialog-overlay"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_cx_label, {
          label: _ctx.$t('ipPool', 'lb')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cx_dropdown, {
              store: "ipPool",
              optionLabel: "name",
              placeholder: _ctx.$t('ipPool', 'ph'),
              modelValue: _ctx.ipPool,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ipPool) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('ipPool', 'subnetIp'),
          v$: _ctx.v$.subnet.subnetIp
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              modelValue: _ctx.subnet.subnetIp,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.subnet.subnetIp) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('ipPool', 'subnetPrefix'),
          v$: _ctx.v$.subnet.subnetPrefix
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputNumber, {
              modelValue: _ctx.subnet.subnetPrefix,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.subnet.subnetPrefix) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('ipPool', 'networkAssigned')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.subnet.isAssigned,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.subnet.isAssigned) = $event)),
              class: "assigned-switch"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Button, {
            label: _ctx.$t('common', 'close'),
            icon: "pi pi-times",
            class: "p-button-secondary cancel-button",
            onClick: _ctx.close
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_Button, {
            label: _ctx.$t('common', 'save'),
            icon: "pi pi-check",
            onClick: _ctx.save,
            class: "save-button p-button-success"
          }, null, 8, ["label", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}