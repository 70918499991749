import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex w-full" }
const _hoisted_2 = { class: "cx-chips" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "cx-chip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MultiSelect, {
      disabled: _ctx.disabled,
      modelValue: _ctx.selectedValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
      options: _ctx.selectableOptions,
      filter: true,
      "filter-fields": ['name'],
      class: "flex-1",
      dataKey: "id",
      placeholder: _ctx.placeholder,
      filterPlaceholder: _ctx.$c('filter'),
      loading: _ctx.isLoading
    }, {
      value: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.value, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "cx-chip",
              key: option.id
            }, [
              _createTextVNode(_toDisplayString(option.name || option.version), 1),
              _createElementVNode("i", {
                "aria-hidden": "true",
                class: "pi pi-times",
                onClick: ($event: any) => (_ctx.onRemove(option))
              }, null, 8, _hoisted_3)
            ]))
          }), 128))
        ]),
        (!slotProps.value || slotProps.value.length === 0)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.placeholder), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      option: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(slotProps.option.name || slotProps.option.version), 1)
      ]),
      _: 1
    }, 8, ["disabled", "modelValue", "options", "placeholder", "filterPlaceholder", "loading"]),
    ((_ctx.dropdownOptions != null && !_ctx.disabled))
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          icon: "pi pi-cog",
          class: "ml-2 p-button-text",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDropdownOptions($event)))
        }))
      : _createCommentVNode("", true)
  ]))
}