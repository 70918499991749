
import { cxUtils } from "@/types/cx-utils";
import { defineComponent } from "vue";
import { CxDataType, CxTableConfig } from "../types/cx-table-config";

export default defineComponent({
  props: {
    config: {
      type: CxTableConfig,
    },
    data: {
      type: Array,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: true
    },
    loading: Boolean
  },
  data() {
    return {
      CxDataType: CxDataType,
      utils: cxUtils,
      innerSelection: [] as any[],
    };
  },
  computed: {
    selection: {
      get(): any[] {
        return this.innerSelection;
      },
      set(v: any) {
        this.innerSelection = v;
        this.$emit("update:selection", v);
      },
    },
  },
});
