import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pi pi-check",
  style: {"color":"#22c55e"}
}
const _hoisted_2 = {
  key: 1,
  class: "pi pi-times",
  style: {"color":"#ff3d32"}
}
const _hoisted_3 = { class: "cx-chips" }
const _hoisted_4 = { class: "p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_cx_audit = _resolveComponent("cx-audit")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.tableStyle)
  }, [
    _createVNode(_component_DataTable, {
      sortMode: "multiple",
      removableSort: true,
      onPage: _ctx.onRefreshFilter,
      onSort: _ctx.onRefreshFilter,
      class: "cx-table-filter",
      scrollHeight: "flex",
      first: _ctx.pageOffset,
      rows: _ctx.tableFilter.size,
      stripedRows: "",
      value: _ctx.data?.content,
      showGridlines: "",
      paginator: true,
      loading: _ctx.loading,
      lazy: true,
      resizableColumns: true,
      columnResizeMode: "fit",
      filterDisplay: "row",
      ref: _ctx.uid,
      dataKey: "id",
      totalRecords: _ctx.totalRecords,
      filters: _ctx.filters,
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters) = $event)),
      rowHover: true,
      scrollable: true,
      rowsPerPageOptions: [5, 15, 25, 50, 100, 200]
    }, {
      empty: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getNoDataLabel()), 1)
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config!.columns, (col, c) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: c }, [
            (col.type == _ctx.CxDataType.Text)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  sortable: true,
                  field: col.field,
                  header: col.label,
                  showFilterMenu: false,
                  headerStyle: col.style || 'min-width:250px',
                  columnKey: col.field
                }, {
                  body: _withCtx((slotProps) => [
                    _createTextVNode(_toDisplayString(slotProps.data[col.field]), 1)
                  ]),
                  filter: _withCtx(() => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      onInput: ($event: any) => (_ctx.onFilter($event, col.field)),
                      class: "p-column-filter",
                      id: ('f_' + _ctx.uid + '_' + col.field),
                      placeholder: _ctx.$cx.tF('common', 'search', [col.label])
                    }, null, 8, ["onInput", "id", "placeholder"])
                  ]),
                  _: 2
                }, 1032, ["field", "header", "headerStyle", "columnKey"]))
              : _createCommentVNode("", true),
            (col.type == _ctx.CxDataType.Date)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 1,
                  dataType: "date",
                  sortable: true,
                  field: col.field,
                  filterField: "Date",
                  header: col.label,
                  columnKey: col.field,
                  showFilterMenu: false
                }, {
                  body: _withCtx((slotProps) => [
                    _createTextVNode(_toDisplayString(_ctx.$cx.toDate(slotProps.data[col.field])), 1)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" > ")
                  ]),
                  _: 2
                }, 1032, ["field", "header", "columnKey"]))
              : _createCommentVNode("", true),
            (col.type == _ctx.CxDataType.DateTime)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 2,
                  dataType: "date",
                  sortable: true,
                  field: col.field,
                  filterField: "Date",
                  header: col.label,
                  columnKey: col.field,
                  headerStyle: "width:130px"
                }, {
                  body: _withCtx((slotProps) => [
                    _createTextVNode(_toDisplayString(_ctx.$cx.toTime(slotProps.data[col.field])), 1)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" > ")
                  ]),
                  _: 2
                }, 1032, ["field", "header", "columnKey"]))
              : _createCommentVNode("", true),
            (col.type == _ctx.CxDataType.Checkbox)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 3,
                  sortable: true,
                  field: col.field,
                  header: col.label,
                  headerStyle: "min-width:250px",
                  bodyStyle: "text-align:center !important",
                  columnKey: col.field,
                  showFilterMenu: false
                }, {
                  body: _withCtx((slotProps) => [
                    (slotProps.data[col.field])
                      ? (_openBlock(), _createElementBlock("i", _hoisted_1))
                      : (_openBlock(), _createElementBlock("i", _hoisted_2))
                  ]),
                  _: 2
                }, 1032, ["field", "header", "columnKey"]))
              : _createCommentVNode("", true),
            (col.type == _ctx.CxDataType.Audit)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 4,
                  field: col.field,
                  header: col.label,
                  headerStyle: "width:200px",
                  columnKey: col.field,
                  showFilterMenu: false
                }, {
                  body: _withCtx((slotProps) => [
                    _createVNode(_component_cx_audit, {
                      entity: slotProps.data
                    }, null, 8, ["entity"])
                  ]),
                  _: 2
                }, 1032, ["field", "header", "columnKey"]))
              : _createCommentVNode("", true),
            (col.type == _ctx.CxDataType.GetType)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 5,
                  sortable: true,
                  field: col.field,
                  header: col.label,
                  headerStyle: "min-width:250px",
                  columnKey: col.field,
                  showFilterMenu: false
                }, {
                  body: _withCtx((slotProps) => [
                    _createTextVNode(_toDisplayString(slotProps.data[col.field].type), 1)
                  ]),
                  _: 2
                }, 1032, ["field", "header", "columnKey"]))
              : _createCommentVNode("", true),
            (col.type == _ctx.CxDataType.GetName)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 6,
                  sortable: true,
                  field: col.field,
                  header: col.label,
                  headerStyle: "min-width:250px",
                  sortField: col.field + '.name',
                  columnKey: col.field,
                  showFilterMenu: false
                }, {
                  body: _withCtx((slotProps) => [
                    _createTextVNode(_toDisplayString(slotProps.data[col.field]?.name), 1)
                  ]),
                  filter: _withCtx(() => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      onInput: ($event: any) => (_ctx.onFilter($event, col.field)),
                      class: "p-column-filter",
                      id: ('f_' + _ctx.uid + '_' + col.field),
                      placeholder: _ctx.$cx.tF('common', 'search', [col.label])
                    }, null, 8, ["onInput", "id", "placeholder"])
                  ]),
                  _: 2
                }, 1032, ["field", "header", "sortField", "columnKey"]))
              : _createCommentVNode("", true),
            (col.type == _ctx.CxDataType.GetNameFromArray)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 7,
                  field: col.field,
                  header: col.label,
                  headerStyle: "min-width:250px",
                  showFilterMenu: false,
                  columnKey: col.field
                }, {
                  body: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_3, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data[col.field] || [], (item, i) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "cx-chip",
                          key: i
                        }, _toDisplayString(item.name), 1))
                      }), 128))
                    ])
                  ]),
                  filter: _withCtx(() => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      onInput: ($event: any) => (_ctx.onFilter($event, col.field)),
                      class: "p-column-filter",
                      id: ('f_' + _ctx.uid + '_' + col.field),
                      placeholder: _ctx.$cx.tF('common', 'search', [col.label])
                    }, null, 8, ["onInput", "id", "placeholder"])
                  ]),
                  _: 2
                }, 1032, ["field", "header", "columnKey"]))
              : _createCommentVNode("", true),
            (col.type == _ctx.CxDataType.Custom)
              ? _renderSlot(_ctx.$slots, col.field, {
                  key: 8,
                  columnKey: col.field
                })
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        _renderSlot(_ctx.$slots, "columns")
      ]),
      _: 3
    }, 8, ["onPage", "onSort", "first", "rows", "value", "loading", "totalRecords", "filters"])
  ], 4))
}