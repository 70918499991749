import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "serials-wrapper" }
const _hoisted_2 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: false,
    title: _ctx.$t('meraki', 'deviceTitle')
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_Button, {
        onClick: _ctx.claim,
        disabled: _ctx.entity == null
      }, {
        default: _withCtx(() => [
          _createTextVNode("Gerät zuordnen")
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_cx_label, {
        label: "Token",
        v$: _ctx.v$.entity.token
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputText, {
            modelValue: _ctx.entity.token,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.token) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["v$"]),
      _createVNode(_component_cx_label, {
        label: "Network-Id",
        v$: _ctx.v$.entity.networkId
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputText, {
            modelValue: _ctx.entity.networkId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.networkId) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["v$"]),
      _createVNode(_component_cx_label, {
        label: _ctx.$t('meraki', 'deviceSerials')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serialsArray, (serial, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createVNode(_component_cx_label, {
                  label: _ctx.$t('meraki', 'deviceSerial')
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": ($event: any) => ((serial.value) = $event)
                    }, null, 8, _hoisted_2), [
                      [_vModelText, serial.value]
                    ])
                  ]),
                  _: 2
                }, 1032, ["label"])
              ]))
            }), 128)),
            _createVNode(_component_Button, {
              onClick: _ctx.addSerial,
              icon: "pi pi-plus-circle",
              iconPos: "left",
              label: _ctx.$t('meraki', 'deviceNewSerial')
            }, null, 8, ["onClick", "label"])
          ])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _: 1
  }, 8, ["title"]))
}