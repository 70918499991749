import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_approval = _resolveComponent("cx-approval")!
  const _component_cx_approval_save_button = _resolveComponent("cx-approval-save-button")!
  const _component_Button = _resolveComponent("Button")!
  const _component_schedule_form = _resolveComponent("schedule-form")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: _ctx.$store.state.schedule.loading,
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'schedule'),
    detail: _ctx.entity.name,
    disableBodyStyle: true
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_approval, {
        approvalState: _ctx.approvalState,
        ref: "approval",
        entity: _ctx.entity,
        onViewChanged: _ctx.onApprovalViewChanged
      }, null, 8, ["approvalState", "entity", "onViewChanged"]),
      (!_ctx.entity.activated)
        ? (_openBlock(), _createBlock(_component_cx_approval_save_button, {
            key: 0,
            approvalState: _ctx.approvalState,
            isEditing: _ctx.isEditing,
            onSave: _ctx.save,
            entity: _ctx.entity,
            onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('schedule'))),
            disableSaveAndBack: true,
            onStateChanged: _ctx.onApprovalChanged,
            initialEntity: _ctx.initialEntity,
            grant: {
          perm: _ctx.$perm.SCHEDULER_JOB,
          lvl: _ctx.$lvl.WRITE
        },
            canCreate: true
          }, null, 8, ["approvalState", "isEditing", "onSave", "entity", "onStateChanged", "initialEntity", "grant"]))
        : _createCommentVNode("", true),
      (_ctx.entity.activated)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            icon: "pi pi-times",
            label: _ctx.$t('schedule', 'deactivate'),
            class: "mr-2 p-button-warning",
            disabled: !_ctx.$auth.permits(_ctx.$perm.SCHEDULER_JOB, _ctx.$lvl.APPROVAL) || !_ctx.isEditing,
            onClick: _ctx.onDeactivate,
            loading: _ctx.isDeactivating
          }, null, 8, ["label", "disabled", "onClick", "loading"]))
        : _createCommentVNode("", true),
      (!_ctx.entity.activated)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 2,
            icon: "pi pi-play",
            label: _ctx.$t('schedule', 'activate'),
            class: "mr-2 p-button-primary",
            disabled: !_ctx.$auth.permits(_ctx.$perm.SCHEDULER_JOB, _ctx.$lvl.APPROVAL) || !_ctx.isEditing,
            onClick: _ctx.onActivate
          }, null, 8, ["label", "disabled", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_schedule_form, {
        ref: "scheduleForm",
        editorApi: _ctx.scheduleApi,
        onEntity: _ctx.onEntityChanged,
        approvalState: _ctx.approvalState,
        enableLocation: true
      }, null, 8, ["editorApi", "onEntity", "approvalState"])
    ]),
    _: 1
  }, 8, ["loading", "title", "detail"]))
}