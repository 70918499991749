import { VariableTypeDto } from "@/types/dto";
import CxApi from "@/types/cx-api";
import { api } from "@/_config/api";

const entityName = "variable/type";
const cxApi = new CxApi<VariableTypeDto>(entityName);

export default {
    namespaced: true,
    state: {
        loading: false
    },
    actions: {
        async getAll(context: any) {
            context.state.loading = true;
            try {
                let response = await cxApi.getAll();
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async getById(context: any, id: number) {
            context.state.loading = true;
            try {
                let response = await cxApi.getById(id);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async saveAll(context: any, entities: VariableTypeDto[]) {
            context.state.loading = true;
            try {
                let response = await api.put(entityName, entities);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        }
    }
};


