import { App } from "vue";

// Load files that start with Cx- (core components), end with -Form (nested forms) and end with -Tab (nested tabs)
const autoComponents = require.context("../", true, /(Cx.*|.*Form|.*Tab)\.vue$/)

export function autoLoadComponents(app: App) {
  autoComponents.keys().map((file) => [file.replace(/(^.\/.*\/)|(\.vue$)/g, ""), autoComponents(file)])
    .reduce((components: any, [name, component]) => {
      app.component(name, component.default || component);
    }, {})
}

// Function will be called in main.ts