
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: String,
    detail: String,
    loading: Boolean,
    disableBodyStyle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    pageTitle() {
      window.document.title = (this.detail ? this.detail + " | " : "") + (this.title || "ConEx Core")
      return this.title + (this.detail ? ": " + this.detail : "")
    }
  }
});
