import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "col-6" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CxDropdown = _resolveComponent("CxDropdown")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_cx_label, {
          label: _ctx.$t('contactType', 'lb'),
          v$: _ctx.v$.entity.contactType
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CxDropdown, {
              store: "contactType",
              optionLabel: "type",
              modelValue: _ctx.entity.contactType,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.contactType) = $event)),
              placeholder: _ctx.$t('contactType', 'ph'),
              dropdownOptions: {
                route: 'contactTypeEditor',
                getId: (en) => en?.id,
                editable: true
              }
            }, null, 8, ["modelValue", "placeholder", "dropdownOptions"])
          ]),
          _: 1
        }, 8, ["label", "v$"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('lastName'),
          v$: _ctx.v$.entity.lastName
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('lastName_ph'),
              modelValue: _ctx.entity.lastName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.lastName) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('firstName'),
          v$: _ctx.v$.entity.firstName
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('firstName_ph'),
              modelValue: _ctx.entity.firstName,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.firstName) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('company'),
          v$: _ctx.v$.entity.company
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('company_ph'),
              modelValue: _ctx.entity.company,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.company) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$c('email'),
          v$: _ctx.v$.entity.email
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('email_ph'),
              modelValue: _ctx.entity.email,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.entity.email) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('mobile'),
          v$: _ctx.v$.entity.mobilePhoneNumber
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('mobile_ph'),
              modelValue: _ctx.entity.mobilePhoneNumber,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.entity.mobilePhoneNumber) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$c('address'),
          v$: _ctx.v$.entity.address
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('address_ph'),
              modelValue: _ctx.entity.address,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.entity.address) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('phone'),
          v$: _ctx.v$.entity.phoneNumber
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('phone_ph'),
              modelValue: _ctx.entity.phoneNumber,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.entity.phoneNumber) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$c('country'),
          v$: _ctx.v$.entity.country
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('country_ph'),
              modelValue: _ctx.entity.country,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.entity.country) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"])
      ])
    ])
  ]))
}