import CxBaseStoreModule from "@/types/cx-store-module";
import { ApprovalDto, CxEntityType } from "@/types/dto";
import { api } from "@/_config/api";

class ApprovalStore extends CxBaseStoreModule<ApprovalDto> {

    constructor() { super("approval") }

    defineActions(actions: any) {
        return super.defineActions({
            getNewDatasets: async (context: any, type: CxEntityType) => {
                context.state.loading = true;
                try {
                    let response = await api.get(`approval/all/${type}`);
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
        })
    }
}

export default new ApprovalStore().createStore()


