import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "cx-chips" }
const _hoisted_2 = {
  key: 0,
  class: "pi pi-check",
  style: {"color":"#22c55e"}
}
const _hoisted_3 = {
  key: 1,
  class: "pi pi-times",
  style: {"color":"#ff3d32"}
}
const _hoisted_4 = { style: {"float":"right","margin-top":"-50px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DataTable, {
      class: "smgmt-table",
      value: _ctx.softwareTableDataFiltered,
      filters: _ctx.softwareDetailFilters,
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.softwareDetailFilters) = $event)),
      filterDisplay: "row",
      showGridlines: "",
      paginator: "",
      rows: _ctx.visibleRows,
      rowsPerPageOptions: [5, 10, 20, 50],
      scrollable: "",
      "scroll-height": _ctx.height,
      selection: _ctx.selectedDevices,
      "onUpdate:selection": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedDevices) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          selectionMode: "multiple",
          headerStyle: "width: 3rem"
        }),
        _createVNode(_component_Column, {
          field: "locationName",
          header: _ctx.$t('location', 'lb'),
          sortable: ""
        }, {
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "deviceRoles",
          header: _ctx.$t('deviceRole', 'lb_pl')
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.deviceRoles, (item, i) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (item.name != null)
                    ? (_openBlock(), _createElementBlock("div", {
                        class: "cx-chip",
                        key: i
                      }, _toDisplayString(item.name), 1))
                    : _createCommentVNode("", true)
                ], 64))
              }), 256))
            ])
          ]),
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "deviceFamilies",
          header: _ctx.$t('deviceFamily', 'lb_pl')
        }, {
          body: _withCtx(({ data }) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.deviceFamilies, (item, i) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (item.name != null)
                  ? (_openBlock(), _createElementBlock("div", {
                      class: "cx-chip",
                      key: i
                    }, _toDisplayString(item.name), 1))
                  : _createCommentVNode("", true)
              ], 64))
            }), 256))
          ]),
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "deviceModels",
          header: _ctx.$t('deviceModel', 'lb_pl')
        }, {
          body: _withCtx(({ data }) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.deviceModels, (item, i) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (item.name != null)
                  ? (_openBlock(), _createElementBlock("div", {
                      class: "cx-chip",
                      key: i
                    }, _toDisplayString(item.name), 1))
                  : _createCommentVNode("", true)
              ], 64))
            }), 256))
          ]),
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "locationDeviceName",
          header: _ctx.$c('hostname'),
          sortable: ""
        }, {
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "managementIp",
          header: _ctx.$c('managementIp'),
          sortable: ""
        }, {
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "sourceVersion",
          header: _ctx.$t('softwareManagement', 'isVersion'),
          sortable: ""
        }, {
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "targetVersion",
          header: _ctx.$t('softwareManagement', 'shouldVersion'),
          sortable: ""
        }, {
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "valid",
          header: _ctx.$t('softwareManagement', 'isValid'),
          sortable: ""
        }, {
          body: _withCtx((slotProps) => [
            (slotProps.data.valid)
              ? (_openBlock(), _createElementBlock("i", _hoisted_2))
              : (_openBlock(), _createElementBlock("i", _hoisted_3))
          ]),
          _: 1
        }, 8, ["header"])
      ]),
      _: 1
    }, 8, ["value", "filters", "rows", "scroll-height", "selection"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Button, {
        disabled: _ctx.selectedJobDevices.length < 1,
        label: _ctx.$cx.tF('softwareManagement', 'scheduleBtn', [_ctx.selectedJobDevices.length]),
        icon: "pi pi-window-maximize",
        onClick: _ctx.goToScheduler
      }, null, 8, ["disabled", "label", "onClick"])
    ])
  ], 64))
}