import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_create_button = _resolveComponent("cx-create-button")!
  const _component_Button = _resolveComponent("Button")!
  const _component_cx_edit_button = _resolveComponent("cx-edit-button")!
  const _component_cx_delete_button = _resolveComponent("cx-delete-button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_cx_table_filter = _resolveComponent("cx-table-filter")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    title: _ctx.$t('portUse', 'lb')
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_create_button, {
        route: "devicePortUseEditorNew",
        grant: {
        perm: _ctx.$perm.PORT_USE,
        lvl: _ctx.$lvl.WRITE
      }
      }, null, 8, ["grant"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_cx_table_filter, {
        stateKey: "devicePortUse",
        data: _ctx.devicePortUses,
        config: _ctx.tableConfig,
        ref: "tbl",
        loading: _ctx.$store.state.devicePortUse.loading,
        onFilter: _ctx.load,
        tableStyle: "height: calc(100vh - 78px)"
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_Column, {
            header: _ctx.$c('actions'),
            frozen: "",
            alignFrozen: "right",
            headerStyle: "width:110px"
          }, {
            filter: _withCtx(() => [
              _createVNode(_component_Button, {
                icon: "pi pi-filter-slash",
                class: "align-self-end p-button-text",
                onClick: _ctx.onClearFilters
              }, null, 8, ["onClick"])
            ]),
            body: _withCtx((props) => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_cx_edit_button, {
                  route: "devicePortUseEditor",
                  id: props.data.id,
                  grant: {
                perm: _ctx.$perm.PORT_USE,
                lvl: _ctx.$lvl.WRITE
              }
                }, null, 8, ["id", "grant"]),
                _createVNode(_component_cx_delete_button, {
                  onClick: ($event: any) => (_ctx.onDelete(props.data)),
                  grant: {
                perm: _ctx.$perm.PORT_USE,
                lvl: _ctx.$lvl.WRITE
              }
                }, null, 8, ["onClick", "grant"])
              ])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["data", "config", "loading", "onFilter"])
    ]),
    _: 1
  }, 8, ["title"]))
}