
import { MenuItem } from "primevue/menuitem";
import { defineComponent } from "vue";
import { Language } from "./types/dto";
import { defaultLocale, switchLocale } from "./_config/ui-framework";

// @ts-ignore
import config from './assets/env.js'

export default defineComponent({
  data() {
    return {
      isExpanded: this.$cx.getStorageBool("cx.nav", true),
      toolbarExpandedKeys: {},
      selectedLanguage: defaultLocale,
      languages: [
        { name: "DE", value: Language.DE },
        { name: "EN", value: Language.EN },
        { name: "FR", value: Language.FR },
      ],
    };
  },
  methods: {
    onToggleNav() {
      this.isExpanded = !this.isExpanded
      localStorage.setItem("cx.nav", this.isExpanded.toString())
    },
    onLanguageChanged($event: any) {
      switchLocale(this.$primevue, $event.value);
    },
    onReloadDropdown() {
      this.$store.state.ui.dropdownOptions.reloadFn();
      (this.$refs as any).dropdownOptions.hide();
    },
    onEditDropdownEntry() {
      this.$cx.goToByIdNewTab(
        this.$store.state.ui.dropdownOptions.options.route,
        this.$store.state.ui.dropdownOptions.options.getId(
          this.$store.state.ui.dropdownOptions.value))
    },
    onCreateDropdownEntry() {
      this.$cx.goToNewTab(this.$store.state.ui.dropdownOptions.options.route + 'New')
    },
    showUserOverlay(ev: any) {
      (this.$refs as any)["userOverlay"].toggle(ev)
    },
    onLogout() {
      this.$cx.confirm("Logout bestätigen", "Möchten Sie sich wirklich abmelden?", () => {
        this.$auth.logout()
      })
    },
    isHighlighted(name: string) {

      //if (this.$route.name?.toString().toLocaleLowerCase().includes(name.toLowerCase()))
      //return 'cx-menu-highlight'


      if (this.$route.meta == null) return ""
      if (!("menu" in this.$route.meta)) return ""

      if ((this.$route.meta as any)["menu"].toString().toLowerCase() == name.toLowerCase())
        return 'cx-menu-highlight'

      return ""
    },
  },
  watch: {
    "$store.state.ui.dropdownOptions": function () {
      (this.$refs as any).dropdownOptions.toggle(this.$store.state.ui.dropdownOptions.event)
    },
  },
  computed: {
    getToolbarItems() {
      let items = [
        {
          key: 'location',
          label: this.$t("location", "lb_pl"),
          icon: "pi pi-building",
          class: this.isHighlighted("location"),
          to: "/location",
        },
        {
          key: 'dashboard',
          label: this.$t("dashboard", 'lb'),
          icon: "pi pi-home",
          class: "cx-panel-header",
          //to: "/",
          items: [
            {
              key: 'goldenImage',
              label: "Golden Image",
              icon: "pi pi-image",
              class: "cx-panel-header",
              items: [
                {
                  key: 'softwareManagement',
                  label: this.$t("softwareManagement", 'lb'),
                  icon: "pi pi-cloud-download",
                  class: this.isHighlighted("software") + " cx-panel-leaf",
                  to: "/softwareManagement",
                },
              ]
            },
            {
              key: 'goldenConfig',
              label: "Golden Config",
              icon: "pi pi-cog",
              class: "cx-panel-header",
              items: [
                {
                  key: 'configuration',
                  label: this.$t("configuration", "lb_pl"),
                  icon: "pi pi-book",
                  class: this.isHighlighted("configuration") + " cx-panel-leaf",
                  to: "/configuration",
                },
              ]
            },
            {
              key: 'ipOverview',
              label: this.$t("common", "overview") + " " + this.$t("common", "ips"),
              icon: "pi pi-list",
              class: this.isHighlighted("dashboard") + " cx-panel-leaf",
              to: "/",
            },
            {
              key: 'jobOverview',
              label: this.$t("common", "overview") + " " + this.$t("job", "active"),
              icon: "pi pi-history",
              class: this.isHighlighted("job") + " cx-panel-leaf",
              to: "/jobs",
            },
          ]
        },
        {
          key: 'goldenConfig',
          label: "Golden Config",
          icon: "pi pi-cog",
          class: "cx-panel-header",
          items: [
            {
              key: 'configuration',
              label: this.$t("configuration", "lb_pl"),
              icon: "pi pi-book",
              class: this.isHighlighted("configuration") + " cx-panel-leaf",
              to: "/configuration",
            },
            {
              key: 'compliance',
              label: this.$t("compliance", "lb"),
              icon: "pi pi-book",
              class: this.isHighlighted("compliance") + " cx-panel-leaf",
              to: "/compliance-overview",
            },
            {
              key: 'variable',
              label: this.$t("variable", "lb_pl"),
              icon: "pi pi-code",
              class: this.isHighlighted("variable") + " cx-panel-leaf",
              to: "/variables",
            }
          ]
        },
        {
          key: 'goldenImage',
          label: "Golden Image",
          icon: "pi pi-image",
          class: "cx-panel-header",
          items: [
            {
              key: 'softwareManagement',
              label: this.$t("softwareManagement", 'lb'),
              icon: "pi pi-cloud-download",
              class: this.isHighlighted("software") + " cx-panel-leaf",
              to: "/softwareManagement",
            }
          ]
        },
        {
          key: 'ipPool',
          label: this.$t("ipPool", "lb_pl"),
          description: "IP-Adressenverwaltung",
          class: this.isHighlighted("ipPool"),
          icon: "pi pi-users",
          to: "/ipPool",
        },
      ] as MenuItem[]

      /*if (process.env.VUE_APP_ENABLE_SATELLITE == 1) {
        items.push({
          label: this.$t("satellite", 'lb'),
          class: this.isHighlighted("satellite"),
          icon: "pi pi-globe",
          to: "/satellite",
        })
      }*/

      items = items.concat([{
        key: 'jobs',
        label: this.$t("job", "lb_pl"),
        icon: "pi pi-history",
        class: this.isHighlighted("job"),
        to: "/jobs",
      }, {
        key: 'logs',
        label: this.$t("log", "lb_pl"),
        icon: "pi pi-bars",
        class: this.isHighlighted("logs"),
        to: "/logs",
      },
      {
        key: 'bridge',
        label: "Bridge",
        icon: "pi pi-shield",
        class: "cx-panel-header",
        items: [
          {
            key: 'codeTemplate',
            label: this.$t("codeTemplate", 'lb'),
            icon: "pi pi-sitemap",
            class: this.isHighlighted("bridge codeTemplate") + " cx-panel-leaf",
            //class: "cx-panel-leaf",
            to: "/codeTemplate",
          }
        ]
      },
      {
        key: 'manufacturer',
        label: this.$t("manufacturer", 'lb'),
        icon: "pi pi-wrench",
        class: "cx-panel-header",
        //to: "/",
        items: [
          {
            key: 'cisco',
            label: "Cisco",
            icon: "pi pi-circle",
            class: "cx-panel-header",
            items: [
              {
                key: 'aci',
                label: this.$t("aci", 'lb'),
                icon: "pi pi-bolt",
                class: this.isHighlighted("apics") + " cx-panel-leaf",
                to: "/apics",
              },
              {
                key: 'meraki',
                label: this.$t("meraki", 'lb'),
                icon: "pi pi-map",
                class: this.isHighlighted("meraki") + " cx-panel-leaf",
                to: "/meraki",
              },
            ]
          },
        ]
      },
      {
        key: 'management',
        label: this.$t("management", "lb"),
        class: this.isHighlighted("management"),
        icon: "pi pi-book",
        to: "/management",
      },
      {
        key: 'expand',
        label: this.isExpanded ? this.$t("common", "collapse") : '',
        icon: 'pi' + (this.isExpanded ? ' pi-angle-double-left' : ' pi-angle-double-right'),
        command: () => this.onToggleNav()
      },
      ] as MenuItem[]);
      return items
    },
  },
});
