import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex w-full" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dropdown, {
      disabled: _ctx.disabled,
      modelValue: _ctx.selectedValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
      options: _ctx.options,
      filter: true,
      showClear: true,
      dataKey: "id",
      filterFields: ['name'],
      optionLabel: _ctx.optionLabel,
      placeholder: _ctx.placeholder,
      loading: _ctx.isLoading,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change', $event))),
      class: "flex-1",
      filterPlaceholder: _ctx.$c('filter')
    }, _createSlots({ _: 2 }, [
      (_ctx.$slots['option'])
        ? {
            name: "value",
            fn: _withCtx((slotProps) => [
              (slotProps.value)
                ? _renderSlot(_ctx.$slots, "option", {
                    key: 0,
                    option: slotProps.value
                  })
                : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(slotProps.placeholder), 1))
            ]),
            key: "0"
          }
        : undefined,
      (_ctx.$slots['option'])
        ? {
            name: "option",
            fn: _withCtx((slotProps) => [
              _renderSlot(_ctx.$slots, "option", {
                option: slotProps.option
              })
            ]),
            key: "1"
          }
        : undefined
    ]), 1032, ["disabled", "modelValue", "options", "optionLabel", "placeholder", "loading", "filterPlaceholder"]),
    ((_ctx.dropdownOptions != null && !_ctx.disabled))
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          icon: "pi pi-cog",
          class: "ml-2 p-button-text",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDropdownOptions($event)))
        }))
      : _createCommentVNode("", true)
  ]))
}