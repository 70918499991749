import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: false,
    title: _ctx.$t('meraki', 'networkTitle')
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_Button, {
        onClick: _ctx.create,
        disabled: _ctx.entity == null
      }, {
        default: _withCtx(() => [
          _createTextVNode("Network erstellen")
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_cx_label, {
        label: _ctx.$c('name'),
        v$: _ctx.v$.entity.name
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputText, {
            placeholder: _ctx.$c('name_ph'),
            modelValue: _ctx.entity.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.name) = $event))
          }, null, 8, ["placeholder", "modelValue"])
        ]),
        _: 1
      }, 8, ["label", "v$"]),
      _createVNode(_component_cx_label, {
        label: "OrganizationId",
        v$: _ctx.v$.entity.organizationId
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputText, {
            modelValue: _ctx.entity.organizationId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.organizationId) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["v$"]),
      _createVNode(_component_cx_label, {
        label: "Token",
        v$: _ctx.v$.entity.token
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputText, {
            modelValue: _ctx.entity.token,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.token) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["v$"]),
      _createVNode(_component_MultiSelect, {
        modelValue: _ctx.selectedProductTypes,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedProductTypes) = $event)),
        options: _ctx.productTypes,
        optionLabel: "label",
        optionValue: "value",
        placeholder: "Select a productType",
        display: "chip"
      }, null, 8, ["modelValue", "options"])
    ]),
    _: 1
  }, 8, ["title"]))
}