import App from "./App.vue";
import router from "./router";
import { createApp } from "vue";
import store from "./store-modules";
import { autoLoadComponents } from "./_config/autoload";
import { loadUiFramework } from "./_config/ui-framework";

const app = createApp(App);

createApp(App);
app.use(store);
app.use(router);
autoLoadComponents(app);
loadUiFramework(app);

// Check if Auth is activated, this flag is set at build-time
if (((window as any).env?.VUE_APP_USE_AUTH || process.env.VUE_APP_USE_AUTH) == 1) {
  // when the application starts, 
  // authorize with keycloak,
  // then render the app
  app.config.globalProperties.$auth.initialize()
    .then(() => {
      app.mount("#app");
      let el = document.getElementById("ht-logo")
      if (el != null) el.remove()
    })
} else {
  try {
    await app.config.globalProperties.$auth.loadState()
  } catch { }
  app.mount("#app");
  app.config.globalProperties.$auth.loadStateInterval()
  let el = document.getElementById("ht-logo")
  if (el != null) el.remove()
}

export default app;
