import { CxEntityType, ComplianceDto } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";
import { api } from "@/_config/api";

class ComparisonStore extends CxBaseStoreModule<Object> {

    constructor() {
        super("comparison")
        this.state.entityType = CxEntityType.COMPARISON_CONFIGURATION
    }

    defineState(state: any) {
        return super.defineState({
            bulkImportProgress: 0,
            bulkImportLoading: false
        })
    }

    defineActions(actions: any) {
        return super.defineActions({
            bulkImportZip: async (context: any, data: any) => {
                context.state.bulkImportLoading = true;
                context.state.bulkImportProgress = 0;
                try {
                    let files = (data.files as File[])
                    let file = files[0]
                    let form = new FormData()
                    form.append("file", file)

                    let response = await api.post(context.state.entityName, form, {
                        onUploadProgress: (progressEvent) => {
                            context.state.bulkImportProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total!)
                        },
                    });
                    context.state.bulkImportLoading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.bulkImportLoading = false;
                    return Promise.reject(error);
                }
            },
        })
    }
}

export default new ComparisonStore().createStore()