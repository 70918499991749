
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import { VirtualNetworkDto, VlanDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";
import { CxTableConfig, CxTableColumn, CxDataType } from "@/types/cx-table-config";

export default defineComponent({
  props: {
    id: String,
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {
        name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true },
        description: { maxLength: maxLength(vMaxLen.description), $autoDirty: true },
      },
    };
  },
  data() {
    return {
      entity: {} as VirtualNetworkDto,
      initialState: {} as VirtualNetworkDto,
      vlans: [] as VlanDto,
      filters: { global: {} } as any,
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
    vlanTableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("vlanId", this.$t('virtualNetwork', 'vlanId')),
        new CxTableColumn("hsrpGroup", this.$t('virtualNetwork', "hsrpGroupNumber")),
        new CxTableColumn("locationTypes", this.$t("locationType", 'lb_pl'), CxDataType.GetNameFromArray),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.$store
          .dispatch("virtualNetwork/getById", this.id)
          .then((entity) => {
            this.entity = entity;
            this.initialState = this.$cx.getState(this.entity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        this.loadVlans()
      }
    },
    async save(goBack: Boolean) {
      if (await this.$cx.notifyValidationError(this.v$)) return;

      if (this.isEditing) {
        this.$store
          .dispatch("virtualNetwork/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("virtualNetwork", "lb"));
            this.initialState = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("virtualNetwork")
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store
          .dispatch("virtualNetwork/create", this.entity)
          .then((newEntity: VirtualNetworkDto) => {
            this.$cx.notifyCreated(this.$t("virtualNetwork", "lb"));
            this.entity = newEntity;
            this.initialState = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("virtualNetwork")
            else
              this.$cx.goToById("virtualNetworkEditor", newEntity.id!);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    },
    loadVlans() {
      this.$store
        .dispatch("virtualNetwork/getVlans", this.id)
        .then((vlans) => {
          this.vlans = vlans;
        });
    },
  },
});
