import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex w-full" }
const _hoisted_2 = { class: "flex-1 flex justify-content-between" }
const _hoisted_3 = {
  key: 0,
  class: "cx-summary mr-4",
  style: {"margin-top":"-5px"}
}
const _hoisted_4 = { class: "flex mr-4" }
const _hoisted_5 = { style: {"height":"100%"} }
const _hoisted_6 = { class: "grid" }
const _hoisted_7 = { class: "col-3" }
const _hoisted_8 = { class: "cx-vartree-node" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "mt-2 flex justify-content-between" }
const _hoisted_11 = { class: "col-9" }
const _hoisted_12 = {
  key: 0,
  class: "cf-container"
}
const _hoisted_13 = {
  key: 1,
  class: "grid"
}
const _hoisted_14 = { class: "col-6" }
const _hoisted_15 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Tree = _resolveComponent("Tree")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.visible) = $event)),
    modal: true,
    style: { width: '95vw' },
    "content-style": {}
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.$t('configuration', 'createdFor')) + " " + _toDisplayString(_ctx.device.name), 1),
          (_ctx.showSaveHint)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('configuration', 'saveConfigBeforeCreation')), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('configuration', 'mapView')), 1),
          _createVNode(_component_InputSwitch, {
            class: "ml-2",
            modelValue: _ctx.isGridView,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isGridView) = $event))
          }, null, 8, ["modelValue"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Tree, {
              class: "cf-tree cx-vartree",
              value: _ctx.variables,
              filter: true,
              filterMode: "lenient",
              scrollHeight: "90%",
              style: {"height":"72vh"},
              expandedKeys: _ctx.expandedKeys,
              "onUpdate:expandedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.expandedKeys) = $event)),
              filterPlaceholder: _ctx.$c('filter')
            }, {
              default: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", null, _toDisplayString(slotProps.node.label), 1),
                  (slotProps.node.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(slotProps.node.value), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["value", "expandedKeys", "filterPlaceholder"]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_Button, {
                type: "button",
                icon: "pi pi-plus",
                label: _ctx.$c('expand'),
                onClick: _ctx.expandAll
              }, null, 8, ["label", "onClick"]),
              _createVNode(_component_Button, {
                type: "button",
                icon: "pi pi-minus",
                label: _ctx.$c('collapse'),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.expandedKeys = {}))
              }, null, 8, ["label"]),
              _createVNode(_component_Button, {
                icon: "pi pi-window-maximize",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$cx.goToNewTab('variableEditor')))
              }),
              _createVNode(_component_Button, {
                icon: "pi pi-server",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$cx.goToByIdNewTab('locationDeviceEditor', _ctx.device.id!)))
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            (_ctx.isGridView)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRowView, (row, r) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "flex flex-nowrap mb-2 mr-2",
                      key: r
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass('flex-1 cf-card relative ' + (_ctx.highlightConfigId == row.left.configId ? 'cf-red' : ''))
                      }, [
                        (row.right.configId != _ctx.highlightConfigId)
                          ? (_openBlock(), _createBlock(_component_Button, {
                              key: 0,
                              icon: "pi pi-window-maximize",
                              class: "p-button-text cf-open",
                              onClick: ($event: any) => (_ctx.onOpenConfig(row.left.configId))
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("p", null, _toDisplayString(row.left.configName) + " (" + _toDisplayString(_ctx.$c('weight')) + ": " + _toDisplayString(row.left.configWeight) + ")", 1),
                        _createVNode(_component_Textarea, {
                          readonly: true,
                          "auto-resize": true,
                          value: row.left.text
                        }, null, 8, ["value"])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass('flex-1 ml-2 cf-card relative ' + (_ctx.highlightConfigId == row.left.configId ? 'cf-red' : ''))
                      }, [
                        (row.right.configId != _ctx.highlightConfigId)
                          ? (_openBlock(), _createBlock(_component_Button, {
                              key: 0,
                              icon: "pi pi-window-maximize",
                              class: "p-button-text cf-open",
                              onClick: ($event: any) => (_ctx.onOpenConfig(row.right.configId))
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("p", null, _toDisplayString(row.right.configName) + " (" + _toDisplayString(_ctx.$c('weight')) + ": " + _toDisplayString(row.right.configWeight) + ")", 1),
                        _createVNode(_component_Textarea, {
                          readonly: true,
                          "auto-resize": true,
                          value: row.right.text
                        }, null, 8, ["value"])
                      ], 2)
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_cx_label, {
                      label: _ctx.$t('configuration', 'source')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Textarea, {
                          style: {"height":"calc(100vh - 207px)"},
                          readonly: true,
                          autoResize: false,
                          value: _ctx.getSourceText,
                          placeholder: _ctx.$t('configuration', 'foundTemplates')
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_cx_label, {
                      label: _ctx.$t('configuration', 'outputs')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Textarea, {
                          style: {"height":"calc(100vh - 207px)"},
                          readonly: true,
                          autoResize: false,
                          value: _ctx.getResultText,
                          placeholder: _ctx.$t('configuration', 'generatedConfiguration')
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]))
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}