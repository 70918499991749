import { RouteRecordRaw } from "vue-router"

import ZTPLogs from "./ZTPLogs.vue"
import LogOverview from "./LogOverview.vue"

const logRoutes: Array<RouteRecordRaw> = [
    {
        path: '/logs',
        name: 'logs',
        component: LogOverview,
        meta: { menu: "logs" }
    },
    {
        path: '/logs/ztp',
        name: 'logsZtp',
        component: ZTPLogs,
        meta: { menu: "logs" }
    }
]

export default logRoutes