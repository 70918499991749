
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";
import { CxExportType } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      locationCategories: []
    };
  },
  computed: {
    tableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("description", this.$c("description")),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
    importTableConfig(): CxTableConfig {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("description", this.$c("description")),
      ])
    },
  },
  methods: {
    load(filters: any) {
      this.$store
        .dispatch("locationCategory/getWithFilter", filters)
        .then((locationCategories) => {
          this.locationCategories = locationCategories;
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onExport() {
      this.$store.dispatch("locationCategory/export", CxExportType.CSV);
    },
    onDelete(data: any) {
      (<any>this.$refs)["confirmDelete"].onDelete(data.id, () => {
        this.$cx.notifyDeleted(this.$t("locationCategory", "lb"));
        (<any>this.$refs)['tbl'].reload()
      })
    },
    onClearFilters() {
      (<any>this.$refs)['tbl'].clearFilters()
    }
  },
});
