import { api } from "@/_config/api";
import { AppContextDto } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";

class AppContextStore extends CxBaseStoreModule<AppContextDto> {

    constructor() { super("appContext") }

    defineState(state: any) {
        return super.defineState({
            settingsLoading: false
        })
    }

    defineActions(actions: any) {
        return super.defineActions({
            getState: async (context: any) => {
                context.state.loading = true;
                try {
                    let response = await api.get(`${context.state.entityName}/state`);
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            getSettings: async (context: any) => {
                context.state.settingsLoading = true;
                try {
                    let response = await api.get(`${context.state.entityName}/settings`);
                    context.state.settingsLoading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.settingsLoading = false;
                    return Promise.reject(error);
                }
            },
            saveSettings: async (context: any, settings: any) => {
                context.state.settingsLoading = true;
                try {
                    let response = await api.put(`${context.state.entityName}/settings`, settings);
                    context.state.settingsLoading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.settingsLoading = false;
                    return Promise.reject(error);
                }
            },
        })
    }
}

export default new AppContextStore().createStore()
