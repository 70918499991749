enum CxDataType {
    None,
    Text,
    Date,
    DateTime,
    Checkbox,
    GetType,
    GetName,
    GetNameFromArray,
    Audit,
    Custom
}

class CxTableColumn {
    field: string;
    label: string;
    style?: string;
    type: CxDataType = CxDataType.Text
    constructor(field: string, label: string, type: CxDataType = CxDataType.Text, style?: string) {
        this.field = field
        this.style = style
        this.label = label
        this.type = type
    }
}

class CxTableConfig {
    filters = <any>{ 'global': {} }
    filterFields = ["name"]
    columns: CxTableColumn[]
    totalRecords?: number;
    lazy = false
    constructor(columns: CxTableColumn[]) {
        this.columns = columns
    }
}

export { CxDataType, CxTableColumn, CxTableConfig }