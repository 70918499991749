import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_cx_import_dialog = _resolveComponent("cx-import-dialog")!
  const _component_cx_base_data_import = _resolveComponent("cx-base-data-import")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Button, {
      label: _ctx.$c('export'),
      icon: "pi pi-cloud-download",
      onClick: _ctx.exportData
    }, null, 8, ["label", "onClick"]),
    _createVNode(_component_SplitButton, {
      label: _ctx.$t('import', 'btn'),
      icon: "pi pi-cloud-upload",
      onClick: _ctx.importData,
      model: _ctx.items
    }, null, 8, ["label", "onClick", "model"]),
    (_ctx.v2)
      ? (_openBlock(), _createBlock(_component_cx_import_dialog, {
          key: 0,
          ref: "importDlg",
          onReload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reload'))),
          store: _ctx.store,
          importTableConfig: _ctx.importTableConfig
        }, null, 8, ["store", "importTableConfig"]))
      : _createCommentVNode("", true),
    (!_ctx.v2)
      ? (_openBlock(), _createBlock(_component_cx_base_data_import, {
          key: 1,
          visible: _ctx.displayImport,
          "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.displayImport) = $event)),
          store: _ctx.store,
          importTableConfig: _ctx.importTableConfig
        }, null, 8, ["visible", "store", "importTableConfig"]))
      : _createCommentVNode("", true)
  ], 64))
}