import { VariableValueDto } from "@/types/dto";
import CxApi from "@/types/cx-api";
import { api } from "@/_config/api";

const entityName = "variable/value";
const cxApi = new CxApi<VariableValueDto>(entityName);

export default {
    namespaced: true,
    state: {
        loading: false
    },
    actions: {

        async getWithFilter(context: any, props: any) {
            try {
                context.state.loading = true
                let response = await api.get(`${entityName}/filter`, {
                    params: {
                        ...{
                            page: props.page,
                            size: props.size
                        }, ...props.filters
                    }
                });
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async getAll(context: any) {
            context.state.loading = true;
            try {
                let response = await cxApi.getAll();
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async getById(context: any, id: number) {
            context.state.loading = true;
            try {
                let response = await cxApi.getById(id);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async save(context: any, entity: VariableValueDto) {
            context.state.loading = true;
            try {
                let response = await cxApi.save(entity);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async create(context: any, entity: VariableValueDto) {
            context.state.loading = true;
            try {
                let response = await cxApi.create(entity);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async delete(context: any, id: number) {
            context.state.loading = true;
            try {
                let response = await cxApi.delete(id);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        }
    }
};


