import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-form-h-30" }
const _hoisted_2 = { class: "cx-summary" }
const _hoisted_3 = { class: "cx-summary" }
const _hoisted_4 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    title: _ctx.$t('satellite', 'update'),
    loading: _ctx.$store.state.update.loading
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('satellite', 'uploadUpdate'),
        disabled: !_ctx.canUpload,
        icon: "pi pi-save",
        onClick: _ctx.onSave
      }, null, 8, ["label", "disabled", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('satellite', "satelliteText1")), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('satellite', "latestUpdate")), 1),
            _createVNode(_component_Button, {
              class: "ml-3",
              icon: "pi pi-download",
              label: _ctx.$cx.tF('satellite', 'download', [_ctx.latestUpdateName]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch('update/downloadLatest')))
            }, null, 8, ["label"])
          ])
        ]),
        _createVNode(_component_FileUpload, {
          ref: "file",
          mode: "basic",
          accept: ".zip",
          chooseLabel: _ctx.$t('satellite', 'phPickZip'),
          onSelect: _ctx.onFileChanged
        }, null, 8, ["chooseLabel", "onSelect"])
      ])
    ]),
    _: 1
  }, 8, ["title", "loading"]))
}