import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_location_device_picker = _resolveComponent("cx-location-device-picker")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: false,
    title: "DayN Wizard"
  }, {
    center: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_cx_location_device_picker, {
        modelValue: _ctx.locationDevices,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.locationDevices) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}