import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "smgmt-tab-actions" }
const _hoisted_2 = { class: "flex mr-4" }
const _hoisted_3 = { class: "smgmt-top-panel" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "smgmtd-grid"
}
const _hoisted_6 = { class: "smgmtd-title" }
const _hoisted_7 = { class: "smgmtd-actions" }
const _hoisted_8 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_cx_multi_chip_select = _resolveComponent("cx-multi-chip-select")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_SoftwareManagementTable = _resolveComponent("SoftwareManagementTable")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Chart = _resolveComponent("Chart")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_SoftwareCompliance = _resolveComponent("SoftwareCompliance")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: false,
    title: " ",
    class: "smgmt-page"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_TabView, { class: "smgmt-tabs" }, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('softwareManagement', 'lb')
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('softwareManagement', 'tableView')), 1),
                    _createVNode(_component_InputSwitch, {
                      class: "ml-2",
                      modelValue: _ctx.isSoftwareGlobalTableView,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSoftwareGlobalTableView) = $event)),
                      onChange: _ctx.onSoftwareGlobalTableChanged
                    }, null, 8, ["modelValue", "onChange"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_cx_label, {
                    label: _ctx.$t('location', 'lb_pl')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_cx_multi_chip_select, {
                        modelValue: _ctx.filters.locations,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.locations) = $event)),
                        store: "location",
                        placeholder: _ctx.$t('location', 'ph_pl')
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_cx_label, {
                    label: _ctx.$t('softwareVersion', 'lb_pl')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_cx_multi_chip_select, {
                        modelValue: _ctx.filters.softwareVersions,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.softwareVersions) = $event)),
                        placeholder: _ctx.$t('softwareVersion', 'ph_pl'),
                        loadFn: _ctx.onLoadSoftwareVersions
                      }, null, 8, ["modelValue", "placeholder", "loadFn"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_cx_label, {
                    label: _ctx.$t('deviceFamily', 'lb_pl')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_cx_multi_chip_select, {
                        modelValue: _ctx.filters.families,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filters.families) = $event)),
                        store: "deviceFamily",
                        placeholder: _ctx.$t('deviceFamily', 'ph_pl')
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_cx_label, {
                    label: _ctx.$t('deviceModel', 'lb_pl')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_cx_multi_chip_select, {
                        modelValue: _ctx.filters.models,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filters.models) = $event)),
                        store: "deviceModel",
                        placeholder: _ctx.$t('deviceModel', 'ph_pl')
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_cx_label, {
                    label: _ctx.$t('deviceRole', 'lb_pl')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_cx_multi_chip_select, {
                        modelValue: _ctx.filters.roles,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filters.roles) = $event)),
                        store: "deviceRole",
                        placeholder: _ctx.$t('deviceRole', 'ph_pl')
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                (_ctx.isSoftwareGlobalTableView)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_SoftwareManagementTable, {
                        data: _ctx.softwareTableData,
                        ref: "softwareGlobalTable",
                        height: "calc(100vh - 215px)",
                        visibleRows: 20
                      }, null, 8, ["data"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartsSoftwareByLocations, (chart, c) => {
                        return (_openBlock(), _createElementBlock("div", { key: c }, [
                          _createElementVNode("div", _hoisted_6, [
                            _createTextVNode(_toDisplayString(chart.name) + " ", 1),
                            _createElementVNode("div", _hoisted_7, [
                              (chart.locationId)
                                ? (_openBlock(), _createBlock(_component_Button, {
                                    key: 0,
                                    icon: "pi pi-eye",
                                    class: "p-button-text",
                                    onClick: ($event: any) => (_ctx.onShowSoftwareDetailDialog(chart))
                                  }, null, 8, ["onClick"]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_Chart, {
                              height: 200,
                              width: 400,
                              type: "doughnut",
                              data: chart.data,
                              options: {
                                    responsive: false,
                                    cutout: 75,
                                    plugins: {
                                        legend: {
                                            labels: {
                                                color: '#495057'
                                            },
                                            position: 'right'
                                        }
                                    },
                                }
                            }, null, 8, ["data"])
                          ])
                        ]))
                      }), 128))
                    ]))
              ])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('softwareManagement', 'softwareComplianceTab')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SoftwareCompliance)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('softwareManagement', 'configComplianceTab')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Chart, {
                type: "bar",
                data: _ctx.configComplianceData,
                options: _ctx.barChartOptions,
                width: 500,
                plugins: [_ctx.chartPlugin]
              }, null, 8, ["data", "options", "plugins"]),
              _createVNode(_component_DataTable, {
                class: "smgmt-table",
                filterDisplay: "row",
                filters: _ctx.softwareComplianceFilters,
                "onUpdate:filters": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.softwareComplianceFilters) = $event)),
                showGridlines: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.softwareComplianceTableConfig.columns, (col, c) => {
                    return (_openBlock(), _createBlock(_component_Column, {
                      key: c,
                      field: col.field,
                      header: col.label
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(data[col.field]), 1)
                      ]),
                      filter: _withCtx(({ filterModel, filterCallback }) => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          onInput: ($event: any) => (filterCallback()),
                          class: "p-column-filter",
                          placeholder: _ctx.$c('searchText')
                        }, null, 8, ["onInput", "placeholder"])
                      ]),
                      _: 2
                    }, 1032, ["field", "header"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["filters"])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }),
      _createVNode(_component_Dialog, {
        header: "Software-Management Details",
        visible: _ctx.isSoftwareDetailDialogVisible,
        "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isSoftwareDetailDialogVisible) = $event)),
        modal: true,
        style: { width: '95vw' },
        "content-style": { overflow: 'hidden' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SoftwareManagementTable, {
            data: _ctx.softwareTableData,
            ref: "softwareDialogTable"
          }, null, 8, ["data"])
        ]),
        _: 1
      }, 8, ["visible"])
    ]),
    _: 1
  }))
}