
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";
import { CxExportType } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      deviceFamilies: []
    };
  },
  computed: {
    tableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("manufacturer", this.$t('manufacturer', 'lb'), CxDataType.GetName),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
    importTableConfig(): CxTableConfig {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("manufacturer", "", CxDataType.Custom),
      ])
    },
  },
  methods: {
    load(filters: any) {
      this.$store
        .dispatch("deviceFamily/getWithFilter", filters)
        .then((deviceFamilies) => {
          this.deviceFamilies = deviceFamilies;
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onExport() {
      this.$store.dispatch("deviceFamily/export", CxExportType.CSV);
    },
    onDelete(data: any) {
      this.$cx.confirmDeleteDetail(
        data.name,
        () => {
          this.$store
            .dispatch("deviceFamily/delete", data.id)
            .then(() => {
              this.$cx.notifyDeleted(this.$t("deviceFamily", "lb"));
              (<any>this.$refs)['tbl'].reload()
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
        })
    },
    onClearFilters() {
      (<any>this.$refs)['tbl'].clearFilters()
    }
  },
});
