
import {CxStackMember, DevicePortDto, DeviceTemplateDto, DeviceTemplateModelDto, StateDiffDto} from "@/types/dto";
import {maxLength, required, vMaxLen} from "@/_config/ui-framework";
import useVuelidate from "@vuelidate/core";
import {defineComponent} from "vue";
import {CxDataType, CxTableColumn, CxTableConfig} from "@/types/cx-table-config";

export default defineComponent({
  props: {
    id: String,
  },
  setup: () => ({v$: useVuelidate() as any}),
  validations() {
    return {
      entity: {
        name: {required, maxLength: maxLength(vMaxLen.name), $autoDirty: true},
        description: {maxLength: maxLength(vMaxLen.description), $autoDirty: true},
        deviceRoles: {required, autoDirty: true},
        os: {required, autoDirty: true}
      },
    };
  },
  data() {
    return {
      entity: {
        stackOrder: []
      } as DeviceTemplateDto,
      initialState: {} as any,
      initialDevicePortState: [] as any,
      devicePorts: [] as any,
      selectedPortGenerationMode: "COUNT",
      portDescription: "",
      portPrefix: "0/0/",
      portStartIndex: 0,
      portEndIndex: 0,
      portCount: 1,
      portUse: null as any,
      portType: null as any,
      editorPortType: null as any,
      editorPortDeviceRole: null as any,
      editorPortDescription: null as any,
      editorPortInterface: null as any,
      selectedPorts: [] as any[],
      isPortEditorVisible: false,
      deletedPorts: [] as any[],
      portSortMeta: [] as any[],
      portFilters: {
        portInterface: {value: null, matchMode: 'contains'},
        'devicePortType.name': {value: null, matchMode: 'contains'},
        'deviceRole.name': {value: null, matchMode: 'contains'},
        description: {value: null, matchMode: 'contains'}
      } as any,
      deviceModels: [
        {
          deviceModel: {
            name: 'Test1'
          },
          stackOrder: 1,
          stackPriority: 5
        }
      ] as DeviceTemplateModelDto[],
      stackOrder: 0,
      stackPriority: 0,
      deviceModelOrderChanged: false
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
    stackMemberCount: {
      get() {
        return this.entity.stackOrder!.length
      },
      set(v: number) {
        if (this.entity.stackOrder!.length < v)
          this.entity.stackOrder?.push({
            memberId: v,
            priority: v
          })
        else {
          this.entity.stackOrder!.length = v
        }
      }
    },
    deviceTemplateModelTableConfig() {
      return new CxTableConfig([
        new CxTableColumn(
            "stackOrder",
            this.$t("deviceTemplateModel", "stackOrder"),
            CxDataType.Custom
        ),
        new CxTableColumn("stackOrder", this.$t("deviceTemplateModel", "stackOrder")),
        new CxTableColumn("deviceModel", this.$t("deviceModel", "lb"), CxDataType.GetName),
        new CxTableColumn("stackPriority", this.$t("deviceTemplateModel", "stackPriority")),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.$cx.notifyUnsavedChangesMultiple(
        next,
        [this.entity, this.devicePorts],
        [this.initialState, this.initialDevicePortState]
    );
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.$store
            .dispatch("deviceTemplate/getById", this.id)
            .then((entity) => {
              this.entity = entity;
              if (this.entity.stackOrder == null)
                this.entity.stackOrder = [] as CxStackMember[]
              this.initialState = this.$cx.getState(this.entity);
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("loading")));

        this.loadDeviceModels();
        this.loadDevicePorts();
      }
    },
    loadDevicePorts() {
      this.$store
          .dispatch("deviceTemplate/getDevicePortsByDeviceTemplateId", this.id)
          .then((ports: DevicePortDto) => {
            this.devicePorts = ports;
            this.selectedPorts = []
            this.initialDevicePortState = this.$cx.getState(this.devicePorts);
          });
    },
    async save(goBack = false) {
      if (await this.$cx.notifyValidationError(this.v$)) return;

      if (this.isEditing) {
        this.$store
            .dispatch("deviceTemplate/save", this.entity)
            .then(() => {
              this.$cx.notifySaved(this.$t("deviceTemplate", "lb"));
              this.initialState = this.$cx.getState(this.entity);

              if (goBack)
                this.$cx.goTo("deviceTemplate")
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("saving")));

        await this.saveDevicePortChanges();

      } else {
        this.$store
            .dispatch("deviceTemplate/create", this.entity)
            .then((newEntity: DeviceTemplateDto) => {
              this.$cx.notifyCreated(this.$t("deviceTemplate", "lb"));
              this.entity = newEntity;
              this.initialState = this.$cx.getState(this.entity);

              if (goBack)
                this.$cx.goTo("deviceTemplate")
              else
                this.$cx.goToById("deviceTemplateEditor", newEntity.id!);
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    },

    loadDeviceModels() {
      this.$store
          .dispatch("deviceTemplate/getModels", this.id)
          .then((models) => {
            this.deviceModels = models;
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onDeviceModelReorder(ev: any) {
      this.deviceModels = ev.value
      for (let i = 0; i < this.deviceModels.length; i++) {
        this.deviceModels[i].stackOrder = i + 1
      }
      this.deviceModelOrderChanged = true
    },
    saveDeviceModelStackOrder() {
      this.$store
          .dispatch("deviceTemplateModel/saveStackOrder", this.deviceModels)
          .then(() => {
            this.$cx.success(this.$c('successful'), this.$c('savedChanges'))
            this.deviceModelOrderChanged = false
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
    },
    onDelete(data: any) {
      (<any>this.$refs)["confirmDelete"].onDelete(data.id, () => {
        this.$cx.notifyDeleted(this.$t("deviceTemplateModel", "lb"));
        this.loadDeviceModels();
      })
    },

    // Port-Tab

    async saveDevicePortChanges() {
      let stateDiff = {} as StateDiffDto<DevicePortDto>;
      stateDiff.entitiesToRemove = this.deletedPorts;
      stateDiff.entitiesToAdd = this.devicePorts.filter((p: any) => p.isNew);
      stateDiff.entitiesToAdd?.forEach((p: any) => {
        // reset the id from UUID, so the backend gives it a new LONG from the DB
        p.id = undefined
      })
      stateDiff.entitiesToUpdate = this.devicePorts.filter(
          (p: any) => p.isModified && !p.isNew
      );
      await this.$store.dispatch("devicePort/bulkUpdateState", stateDiff);
      this.loadDevicePorts();
    },
    onGeneratePorts() {
      try {
        let newPorts = [];
        switch (this.selectedPortGenerationMode) {
          case "COUNT":
            for (let i = 0; i < this.portCount; i++) {
              var newPort1 = new DevicePortDto();
              (newPort1 as any).isNew = true;
              newPort1.id = this.$cx.createUid() as any
              newPort1.deviceTemplate = {id: parseInt(this.id!)};
              newPort1.devicePortType = this.portType;
              newPort1.description = this.portDescription;
              newPort1.portInterface = `${this.portPrefix}${this.portStartIndex + i
              }`;

              let collisions = this.devicePorts.filter(
                  (p: any) => p.portInterface == newPort1.portInterface
              );
              if (collisions != null && collisions.length > 0)
                throw new Error(
                    this.$cx.tF('deviceTemplate', 'portBlocked', [newPort1.portInterface])
                );

              newPorts.push(newPort1);
            }
            break;
          case "RANGE":
            if (this.portEndIndex < this.portStartIndex)
              throw new Error(
                  this.$cx.tF('deviceTemplate', 'portOutside', [this.portStartIndex, this.portEndIndex])
              );
            for (
                let i = 0;
                i < this.portEndIndex + 1 - this.portStartIndex;
                i++
            ) {
              var newPort2 = new DevicePortDto();
              (newPort2 as any).isNew = true;
              newPort2.id = this.$cx.createUid() as any
              newPort2.deviceTemplate = {id: parseInt(this.id!)};
              newPort2.devicePortType = this.portType;
              newPort2.description = this.portDescription;
              newPort2.portInterface = `${this.portPrefix}${this.portStartIndex + i
              }`;

              let collisions = this.devicePorts.filter(
                  (p: any) => p.portInterface == newPort2.portInterface
              );
              if (collisions != null && collisions.length > 0)
                throw new Error(
                    this.$cx.tF('deviceTemplate', 'portBlocked', [newPort2.portInterface])
                );
              newPorts.push(newPort2);
            }
            break;
        }
        this.devicePorts = this.devicePorts.concat(newPorts);
        this.$cx.success(
            this.$t('deviceTemplate', 'portsCreated'),
            this.$cx.tF('deviceTemplate', 'portCount', [newPorts.length])
        );
      } catch (error: any) {
        this.$cx.error(error, this.$t("deviceTemplate", "noPortsCreated"));
      }
    },
    onDeleteSelectedPorts() {
      this.$cx.confirm(
          this.$t('deviceTemplate', 'deleteSelectedPorts'),
          this.$cx.tF('deviceTemplate', 'deletePorts', [this.selectedPorts.length]),
          () => {
            this.deletedPorts = this.deletedPorts.concat(
                this.selectedPorts.filter((p: any) => !p.isNew)
            );
            this.devicePorts = this.$cx.removeRange(
                this.devicePorts,
                this.selectedPorts
            );
            this.selectedPorts = [];
          }
      );
    },
    onEditPorts() {
      this.editorPortType = null;
      this.editorPortInterface = null;
      this.editorPortDeviceRole = null;
      this.editorPortDescription = null;

      // load the state of a single port
      if (this.selectedPorts.length == 1) {
        this.editorPortType = this.selectedPorts[0].devicePortType;
        this.editorPortDeviceRole = this.selectedPorts[0].deviceRole;
        this.editorPortDescription = this.selectedPorts[0].description;
        this.editorPortInterface = this.selectedPorts[0].portInterface;
      } else {
        // loop all ports and attempt to detect and set the common properties among them
        // for the dialog
        for (let port of this.selectedPorts) {
          if (this.editorPortDescription == null)
            this.editorPortDescription = port.description;
          else if (this.editorPortDescription != port.description)
            this.editorPortDescription = "";

          if (this.editorPortType == null)
            this.editorPortType = port.devicePortType;
          else if (this.editorPortType.id != port.devicePortType.id)
            this.editorPortType = null;

          if (this.editorPortDeviceRole == null)
            this.editorPortDeviceRole = port.deviceRole;
          else if (this.editorPortDeviceRole.id != port.deviceRole.id)
            this.editorPortDeviceRole = null;
        }
      }
      this.isPortEditorVisible = true;
    },
    onApplyPortChanges() {
      for (let port of this.selectedPorts) {
        let isModified = false;
        if (this.editorPortType != null) {
          port.devicePortType = this.editorPortType;
          isModified = true;
        }
        if (this.editorPortDeviceRole != null) {
          port.deviceRole = this.editorPortDeviceRole;
          isModified = true;
        }
        if (
            this.editorPortDescription != null &&
            this.editorPortDescription.length > 0
        ) {
          port.description = this.editorPortDescription;
          isModified = true;
        }
        if (isModified) {
          port.isModified = true;
        }
      }
      if (this.selectedPorts.length == 1) {
        this.selectedPorts[0].portInterface = this.editorPortInterface;
        this.selectedPorts[0].deviceRole = this.editorPortDeviceRole;
        this.selectedPorts[0].isModified = true;
      }
      this.isPortEditorVisible = false;
    },
  },
});
