import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "p-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('cx-label ' + (_ctx.hasApproval ? 'cx-approve ' : ' ') + _ctx.$cx.ternary(_ctx.checkbox, 'cx-label-checkbox', '') + _ctx.$cx.ternary(_ctx.nomargin, 'cx-label-nomargin', ''))
  }, [
    (_ctx.label != null)
      ? _withDirectives((_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass({
      'cx-required': _ctx.isRequired,
    })
        }, [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ], 2)), [
          [
            _directive_tooltip,
            _ctx.approvalChange,
            void 0,
            { bottom: true }
          ]
        ])
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.errors.length > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, e) => {
            return (_openBlock(), _createElementBlock("span", { key: e }, [
              _createElementVNode("small", _hoisted_2, _toDisplayString(_ctx.format(error.$message)), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}