import { ApiParamDto, CxEntityType } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";
import { api } from "@/_config/api";

class ApiParamStore extends CxBaseStoreModule<ApiParamDto> {

    constructor() {
        super("apiParam")
        this.state.entityType = CxEntityType.API_PARAM
    }

    defineState(state: any) {
        return super.defineState({
            testConnectionLoading: false
        })
    }

    defineActions(actions: any) {
        return super.defineActions({
            getAllByFilter: async (context: any, filters: any) => {
                context.state.loading = true;
                try {
                    let response = await api.post(context.state.entityName + '/ddfilter', { filters: filters });
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            testConnection: async (context: any, id: any) => {
                context.state.testConnectionLoading = true;
                try {
                    let response = await api.get(`${context.state.entityName}/testConnection/${id}`);
                    context.state.testConnectionLoading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.testConnectionLoading = false;
                    return Promise.reject(error);
                }
            },
        })
    }
}

export default new ApiParamStore().createStore()
