import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "cx-form-h-30" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-6" }
const _hoisted_4 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_save_button = _resolveComponent("cx-save-button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_cx_json_editor = _resolveComponent("cx-json-editor")!
  const _component_vlan_form = _resolveComponent("vlan-form")!
  const _component_cx_crud_table = _resolveComponent("cx-crud-table")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: _ctx.$store.state.virtualNetwork.loading,
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'virtualNetwork'),
    detail: _ctx.entity.name
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_save_button, {
        isEditing: _ctx.isEditing,
        onSave: _ctx.save,
        onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('virtualNetwork')))
      }, null, 8, ["isEditing", "onSave"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('name'),
          v$: _ctx.v$.entity.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('name_ph'),
              modelValue: _ctx.entity.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.name) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$c('description'),
          v$: _ctx.v$.entity.description
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Textarea, {
              placeholder: _ctx.$c('description_ph'),
              modelValue: _ctx.entity.description,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.description) = $event)),
              rows: "5",
              cols: "30"
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_cx_label, {
              label: _ctx.$t('virtualNetwork', 'asn')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  placeholder: _ctx.$t('virtualNetwork', 'asn_ph'),
                  modelValue: _ctx.entity.asn,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.asn) = $event))
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_cx_label, {
              label: _ctx.$t('virtualNetwork', 'vpnNumber')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputNumber, {
                  placeholder: _ctx.$t('virtualNetwork', 'vpnNumber_ph'),
                  modelValue: _ctx.entity.vpnNumber,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.entity.vpnNumber) = $event)),
                  showButtons: ""
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }, 8, ["label"])
          ])
        ]),
        _createVNode(_component_cx_label, {
          label: _ctx.$c('meta')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cx_json_editor, {
              modelValue: _ctx.entity.meta,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.entity.meta) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      (_ctx.isEditing)
        ? (_openBlock(), _createBlock(_component_cx_crud_table, {
            key: 0,
            onLoad: _ctx.loadVlans,
            data: _ctx.vlans,
            entityName: "vlan",
            config: _ctx.vlanTableConfig,
            loading: false
          }, {
            editor: _withCtx(({ props }) => [
              _createVNode(_component_vlan_form, {
                editorApi: props,
                parentId: _ctx.id
              }, null, 8, ["editorApi", "parentId"])
            ]),
            _: 1
          }, 8, ["onLoad", "data", "config"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading", "title", "detail"]))
}