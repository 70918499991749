
import { CxGrant, CxPermission, CxPermissionLevel } from "@/types/cx-permission";
import SplitButton from "primevue/splitbutton";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        isEditing: {
            type: Boolean
        },
        disableSaveAndBack: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        grant: {
            type: Object as PropType<CxGrant>,
            default: {
                perm: CxPermission.NONE,
                lvl: CxPermissionLevel.NONE
            } as CxGrant
        },
        approve: {
            type: String,
            default: null
        }
    },
    methods: {
        onClick(ev: any) {
            if (!this.enabled)
                this.$emit('cancel')
            else if (this.isEditing && !this.disableSaveAndBack)
                this.$emit('save', true)
            else
                this.$emit('save', false)
        }
    },
    computed: {
        saveLabel() {
            if (this.$auth.approvalRequired(this.approve))
                return this.$c("approve")
            return this.$c("save")
        },
        saveAndBackLabel() {
            if (this.$auth.approvalRequired(this.approve))
                return this.$c("approveAndBack")
            return this.$c("saveAndBack")
        },
        items() {
            if (!this.enabled) return []
            if (this.isEditing) {
                let items = []
                if (!this.disableSaveAndBack) {
                    items.push({
                        label: this.saveLabel,
                        icon: 'pi pi-save',
                        command: () => this.$emit('save', false)
                    })
                }
                items.push(
                    {
                        label: this.$c("back"),
                        icon: 'pi pi-arrow-left',
                        command: () => this.$emit('cancel')
                    })
                return items
            }

            return [{
                label: this.$c("createAndBack"),
                icon: 'pi pi-plus',
                command: () => this.$emit('save', true)
            },
            {
                label: this.$c("back"),
                icon: 'pi pi-arrow-left',
                command: () => this.$emit('cancel')
            }]
        },
        enabled() {
            return this.$auth.permits(this.grant.perm, this.grant.lvl) && !this.disabled
        },
        label() {
            if (!this.enabled)
                return this.$c("back")

            if (this.isEditing) {
                if (this.disableSaveAndBack)
                    return this.saveLabel
                else
                    return this.saveAndBackLabel
            } else
                return this.$c("saveButtonCreate")
        },
        icons() {
            if (!this.enabled)
                return 'pi pi-arrow-left'
            if (this.isEditing)
                return 'pi pi-save'
            return 'pi pi-plus'
        },
        classes() {
            return 'align-self-end'
        }
    },
});
