import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cx-summary"
}
const _hoisted_2 = {
  key: 1,
  class: "cx-summary"
}
const _hoisted_3 = { class: "cx-form-h-30" }
const _hoisted_4 = { class: "cx-summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'customer'),
    loading: _ctx.$store.state.customer.loading
  }, {
    actions: _withCtx(() => [
      (_ctx.isEditing)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: "align-self-end p-button-secondary",
            label: _ctx.$t('customer', 'downloadZip'),
            icon: "pi pi-download",
            onClick: _ctx.onDownload
          }, null, 8, ["label", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.isEditing)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            class: "align-self-end p-button-success",
            label: _ctx.$c('upload'),
            icon: "pi pi-upload",
            onClick: _ctx.onUpload
          }, null, 8, ["label", "onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Button, {
        label: _ctx.$c('save'),
        class: "align-self-end",
        icon: "pi pi-save",
        onClick: _ctx.onSave
      }, null, 8, ["label", "onClick"])
    ]),
    default: _withCtx(() => [
      (_ctx.isEditing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('customer', 'customerZipText')), 1))
        : _createCommentVNode("", true),
      (!_ctx.isEditing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('customer', 'customerNewOptions')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('name')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('name_ph'),
              readonly: _ctx.isEditing,
              modelValue: _ctx.entity.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.name) = $event))
            }, null, 8, ["placeholder", "readonly", "modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        (_ctx.isEditing)
          ? (_openBlock(), _createBlock(_component_cx_label, {
              key: 0,
              label: _ctx.$t('customer', 'configurations')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Textarea, {
                  rows: "7",
                  readonly: true,
                  autoResize: false,
                  value: _ctx.entity.configs?.join('\n'),
                  placeholder: _ctx.$t('customer', 'noConfigOnShare_ph')
                }, null, 8, ["value", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isEditing)
        ? (_openBlock(), _createBlock(_component_TabView, { key: 2 }, {
            default: _withCtx(() => [
              _createVNode(_component_TabPanel, {
                header: _ctx.$t('customer', 'folderDropdownMap')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('customer', 'customerPlaceHolderText')), 1),
                  _createVNode(_component_DataTable, {
                    value: _ctx.entity.configFilters,
                    responsiveLayout: "scroll",
                    resizableColumns: true,
                    columnResizeMode: "fit",
                    showGridlines: ""
                  }, {
                    empty: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('customer', "noConfigFound")), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        header: _ctx.$t('customer', 'depth')
                      }, {
                        body: _withCtx((slotProps) => [
                          _createTextVNode(_toDisplayString(slotProps.index), 1)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        header: _ctx.$t('customer', 'hTextDE')
                      }, {
                        body: _withCtx((slotProps) => [
                          _createVNode(_component_InputText, {
                            placeholder: _ctx.$t('customer', 'description_ph'),
                            modelValue: slotProps.data.placeholder[_ctx.Language.DE],
                            "onUpdate:modelValue": ($event: any) => ((slotProps.data.placeholder[_ctx.Language.DE]) = $event)
                          }, null, 8, ["placeholder", "modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        header: _ctx.$t('customer', 'hTextEN')
                      }, {
                        body: _withCtx((slotProps) => [
                          _createVNode(_component_InputText, {
                            placeholder: _ctx.$t('customer', 'description_ph'),
                            modelValue: slotProps.data.placeholder[_ctx.Language.EN],
                            "onUpdate:modelValue": ($event: any) => ((slotProps.data.placeholder[_ctx.Language.EN]) = $event)
                          }, null, 8, ["placeholder", "modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        header: _ctx.$t('customer', 'hTextFR')
                      }, {
                        body: _withCtx((slotProps) => [
                          _createVNode(_component_InputText, {
                            placeholder: _ctx.$t('customer', 'description_ph'),
                            modelValue: slotProps.data.placeholder[_ctx.Language.FR],
                            "onUpdate:modelValue": ($event: any) => ((slotProps.data.placeholder[_ctx.Language.FR]) = $event)
                          }, null, 8, ["placeholder", "modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 1
                      }, 8, ["header"])
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }, 8, ["header"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        class: "cx-zip-uploader",
        type: "file",
        accept: "zip,application/zip,application/x-zip,application/x-zip-compressed",
        ref: "cx-zip-input",
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFileChanged && _ctx.onFileChanged(...args)))
      }, null, 544)
    ]),
    _: 1
  }, 8, ["title", "loading"]))
}