import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-dashboard-grid" }
const _hoisted_2 = { class: "cx-dashboard-doughnuts" }
const _hoisted_3 = { class: "cx-stats-row" }
const _hoisted_4 = { class: "cx-dashboard-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!
  const _component_Card = _resolveComponent("Card")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    title: _ctx.$t('dashboard', 'lb')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Card, null, {
            title: _withCtx(() => [
              _createElementVNode("h5", null, _toDisplayString(_ctx.$t('dashboard', 'byLocation')), 1)
            ]),
            content: _withCtx(() => [
              _createVNode(_component_Chart, {
                height: 200,
                width: 400,
                type: "doughnut",
                data: _ctx.devicesByLocations,
                options: _ctx.doughnutOptions
              }, null, 8, ["data", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_Card, null, {
            title: _withCtx(() => [
              _createElementVNode("h5", null, _toDisplayString(_ctx.$t('dashboard', 'byCategory')), 1)
            ]),
            content: _withCtx(() => [
              _createVNode(_component_Chart, {
                height: 200,
                type: "doughnut",
                data: _ctx.locationsByCategories,
                options: _ctx.doughnutOptions
              }, null, 8, ["data", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_Card, null, {
            title: _withCtx(() => [
              _createElementVNode("h5", null, _toDisplayString(_ctx.$t('dashboard', 'byPools')), 1)
            ]),
            content: _withCtx(() => [
              _createVNode(_component_Chart, {
                height: 200,
                type: "doughnut",
                data: _ctx.subnetsByPools,
                options: _ctx.doughnutOptions
              }, null, 8, ["data", "options"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createVNode(_component_Card, null, {
              title: _withCtx(() => [
                _createElementVNode("h5", null, _toDisplayString(_ctx.$c('status')), 1)
              ]),
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('location', 'lb')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.stats.locationCount), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('locationDevice', 'lb')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.stats.locationDeviceCount), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('ipPool', 'lb')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.stats.ipPoolCount), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$c('subnets')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.stats.ipPoolSubnetCount), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$c('vrf')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.stats.vrfCount), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$c('vlan')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.stats.vlanCount), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('variable', 'lb')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.stats.variableCount), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('variableValue', 'lb')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.stats.variableValueCount), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('configuration', 'lb')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.stats.configCount), 1)
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_Card, null, {
              title: _withCtx(() => [
                _createElementVNode("h5", null, _toDisplayString(_ctx.$t('configuration', 'lb')), 1)
              ]),
              content: _withCtx(() => [
                _createVNode(_component_Chart, {
                  height: 92,
                  type: "line",
                  data: _ctx.lineData,
                  options: _ctx.lineOptions
                }, null, 8, ["data", "options"])
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}