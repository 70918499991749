import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_cx_label, {
      label: _ctx.$t('portUse', 'lb')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_dropdown, {
          modelValue: _ctx.entity.devicePortUse,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.devicePortUse) = $event)),
          store: "devicePortUse",
          optionLabel: "name",
          placeholder: _ctx.$t('portUse', 'ph'),
          dropdownOptions: {
                        route: 'devicePortUseEditor',
                        getId: (en) => en?.id,
                        editable: true
                    }
        }, null, 8, ["modelValue", "placeholder", "dropdownOptions"])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$c('count')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputNumber, {
          placeholder: _ctx.$c('count_ph'),
          modelValue: _ctx.entity.count,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.count) = $event)),
          showButtons: ""
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}