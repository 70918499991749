
import { CxTableColumn, CxTableConfig } from "@/types/cx-table-config";
import { CiscoAciDto, CiscoAciQueryRequest } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            apic: {} as CiscoAciDto,
            acis: [] as CiscoAciDto[],
            result: [] as any,
            endpoint: "172.18.30.10",
            columns: [] as { [key: string]: string }[],


            testdata: [{ 'name': 'uni/tn-CCLOUD/ap-APP_Conex/epg-EPG_Conex/cep-00:50:56:BE:C8:DB', 'endpoints': [{ 'mac': '00:50:56:BE:C8:DB', 'enc': 'vlan-2009', 'lcc': 'learned,vmm', 'mod': '2023-02-02T19:44:40.281+01:00', 'ips': ['172.18.30.10'], 'leafs': [{ 'name': 'topology/pod-1/node-103', 'epmes': [] }, { 'name': 'topology/pod-1/node-105', 'epmes': [] }, { 'name': 'topology/pod-1/node-101', 'epmes': [] }, { 'name': 'topology/pod-1/node-102', 'epmes': [] }, { 'name': 'topology/pod-1/node-106', 'epmes': [] }, { 'name': 'topology/pod-1/node-104', 'epmes': [] }, { 'name': 'topology/pod-2/node-203', 'epmes': [{ 'int': 'tunnel16', 'flag': 'ip,mac,peer-attached' }] }, { 'name': 'topology/pod-2/node-201', 'epmes': [] }, { 'name': 'topology/pod-2/node-202', 'epmes': [] }, { 'name': 'topology/pod-2/node-204', 'epmes': [{ 'int': 'eth1/5', 'flag': 'ip,local,mac' }] }], 'spines': [{ 'name': 'topology/pod-2/node-292', 'coops': [{ 'leaf': '10.20.104.66', 'time': '2023-02-10T11:17:43.134+01:00' }] }, { 'name': 'topology/pod-1/node-192', 'coops': [{ 'leaf': '10.0.88.66', 'time': '1970-01-01T01:00:00.000+01:00' }] }, { 'name': 'topology/pod-2/node-291', 'coops': [{ 'leaf': '10.20.104.66', 'time': '2023-02-10T11:17:43.134+01:00' }] }, { 'name': 'topology/pod-1/node-191', 'coops': [{ 'leaf': '10.0.88.66', 'time': '1970-01-01T01:00:00.000+01:00' }] }] }] }]
        };
    },
    created() {
        this.load()
    },
    computed: {
        tableConfig() {
            return new CxTableConfig(this.columns.map(c => new CxTableColumn(c["field"], c["label"])))
        },
        groupedAcis() {
            let groups = {} as any
            this.acis.forEach(aci => {
                if (!(aci.groupRef! in groups))
                    groups[aci.groupRef!] = []
                groups[aci.groupRef!].push(aci)
            })

            return Object.entries(groups).map(g => ({
                label: g[0],
                items: g[1]
            }))
        }
    },
    methods: {
        load() {
            this.$store.dispatch("ciscoAci/getAcis")
                .then((acis: any) => {
                    this.acis = acis
                })
        },
        onRun() {
            this.$store.dispatch("ciscoAci/queryAci", {
                aciId: this.apic.id,
                templateName: 'ACI_Get_EP_Information',
                props: {
                    "endpoint": this.endpoint
                }
            } as CiscoAciQueryRequest)
                .then(r => {
                    let result = JSON.parse(r.replaceAll("'", '"'))
                    this.result = result
                })
        }
    },
});
