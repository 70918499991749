
import useVuelidate from "@vuelidate/core";
import { defineComponent, nextTick } from "vue";
import { CxDataType, CxTableColumn, CxTableConfig } from "@/types/cx-table-config";
import { CxExportType, ExistsDto } from "@/types/dto";
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
  props: {
    id: String,
    acceptedFileType: {
      type: String,
      default: ".csv",
    },
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {},
    };
  },
  data() {
    return {
      items: [
        {
          label: this.$t('import', 'template'),
          icon: "pi pi-download",
          command: () => {
            this.downloadImportTemplate();
          },
        },
      ],
      importData: [] as any,
      files: [] as any,
      delimiter: ",",
      showImportTable: false,
      showVariableTable: false,
      canSave: false,
      showDialog: false,
      variableNotExist: [] as any,
      dataMap: [] as any,
      isLoading: false,
      showExportButton: false
    };
  },
  computed: {
    importTableConfig(): CxTableConfig {
      return new CxTableConfig([
        new CxTableColumn("location", "location"),
        new CxTableColumn("locationTypes", "locationTypes"),
        new CxTableColumn("locationCategory", "locationCategory"),
        new CxTableColumn("street", "street"),
        new CxTableColumn("buildingNumber", "buildingNumber"),
        new CxTableColumn("postalCode", "postalCode"),
        new CxTableColumn("city", "city"),
        new CxTableColumn("continent", "continent"),
        new CxTableColumn("country", "country"),
        new CxTableColumn("state", "state"),
        new CxTableColumn("hostname", "hostname"),
        new CxTableColumn("deviceRole", "deviceRole"),
        new CxTableColumn("deviceTemplate", "deviceTemplate"),
        new CxTableColumn("managementIp", "managementIp"),
        new CxTableColumn("manufacturer", "manufacturer"),
        new CxTableColumn("deviceFamily", "deviceFamily"),
        new CxTableColumn("deviceModel", "deviceModel"),
        new CxTableColumn("operatingSystem", "operatingSystem"),
        new CxTableColumn("serialNumbers", "serialNumbers")
      ])
    },
    variableTableConfig(): CxTableConfig {
      return new CxTableConfig([
        new CxTableColumn("name", this.$t('import', 'variableNotExists'))
      ])
    },
    dataTableConfig(): CxTableConfig {
      let columns = [new CxTableColumn("location", this.$t('import', 'locationName')),
      new CxTableColumn("name", this.$t('import', 'deviceName')),
      new CxTableColumn("deviceRole", this.$t('import', 'deviceRoleNames')),
      new CxTableColumn("deviceTemplate", this.$t('import', 'deviceTemplateNames')),
      new CxTableColumn("managementIp", this.$t('import', 'managementIp')),
      new CxTableColumn("serialNumbers", this.$t('import', 'serialNumbers'))];

      if (this.importData.length > 0) {
        Object.keys(this.importData[0].dto.variableValueMap).forEach((key: any) => {
          columns.push(new CxTableColumn(key, key))
        })
      }
      return new CxTableConfig(
        columns
      )
    },
    getColumns() {
      if (this.dataMap == null) {
        return {}
      } if (this.dataMap.length < 1) {
        return {}
      }
      return Object.keys(this.dataMap[0]).filter(f => f != "variableValueMap")
    }
  },
  methods: {
    onFileChanged($ev: any) {
      if ((<any>this.$refs["file"]).files.length > 0) {
        this.files = (<any>this.$refs["file"]).files;
      }
    },

    removeFile(file: any) {
      var index = this.files.indexOf(file);
      this.showImportTable = false;
      this.showVariableTable = false;
      this.canSave = false;
      this.showExportButton = false;
      this.importData = [];
      this.dataMap = [];
      this.files.splice(index, 1);
    },

    formatSize(bytes: any) {
      if (bytes === 0) {
        return "0 B";
      }
      let k = 1000,
        dm = 3,
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    openImportDialog() {
      this.showDialog = true
    },

    exportData() {
      this.$store.dispatch("universalExtender/getExport", CxExportType.CSV);
    },
    downloadImportTemplate() {
      this.$store.dispatch("universalExtender/getImportTemplate");
    },

    importFile(file: any, save: boolean) {
      let f = this.files.find((x: any) => x !== undefined);
      let formData = new FormData();
      formData.append("file", f);

      if (formData) {
        let importPayload = {
          file: formData,
          save,
          delimiter: this.delimiter,
        }

        if (save == true) {
          this.isLoading = true
          this.importData = [];
          this.dataMap = [];
          this.$store.dispatch("universalExtender/importData", importPayload)
            .then((data) => {
              this.isLoading = false;
              this.importData = data;

              this.importData.forEach((record: any) => {
                let dto: any = {}

                Object.entries(record.dto).forEach(item => {
                  dto[item[0]] = { field: item[0], oldValue: item[1], newValue: null }
                })

                Object.keys(dto.variableValueMap.oldValue).forEach(value => {
                  if (dto.variableValueMap.newValue != null && value in dto.variableValueMap.newValue) {
                    dto[value] = {
                      newValue: dto.variableValueMap.newValue[value],
                      oldValue: dto.variableValueMap.oldValue[value]
                    }
                  } else {
                    dto[value] = {
                      newValue: null,
                      oldValue: dto.variableValueMap.oldValue[value]
                    }
                  }
                })

                if (record.updated != null) {
                  record.updated.forEach((update: any) => {
                    if (update.field == "serialNumbers") {
                      var newSerialArray = this.trimSerials(update.newValue)
                      var oldSerialArray = this.trimSerials(update.oldValue)
                      dto[update.field].newValue = newSerialArray
                      dto[update.field].oldValue = oldSerialArray
                    } else {
                      dto[update.field] = update
                    }
                  })
                }

                if (record.created != null) {
                  record.created.forEach((create: any) => {
                    if (create.field == "serialNumbers") {
                      dto[create.field].oldValue = null
                      var newSerialArray = this.trimSerials(create.newValue)
                      dto[create.field].newValue = newSerialArray
                    } else {
                      dto[create.field] = create
                    }
                  });
                }

                this.dataMap.push(dto)
              })
              this.showVariableTable = false;
              this.showImportTable = true;
              this.showExportButton = true;
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("error")));
        } else {
          this.$store.dispatch("universalExtender/importData", importPayload)
            .then((data: ExistsDto) => {
              this.variableNotExist = data.doesNotExist;
              if (this.variableNotExist.length > 0) {
                this.showVariableTable = true;
              }
              if (this.variableNotExist.length == 0) {
                this.canSave = true;
                this.showVariableTable = false;
              }
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("error")));
        }
      }

    },
    getLabel(dto: any, name: any) {
      return `old: ${dto[name].oldValue}, new: ${dto[name].newValue}`
    },
    getStyle(dto: any, name: any) {
      if (dto[name].oldValue != null && dto[name].newValue != null)
        return `background-color: orange`
      if (dto[name].oldValue == null && dto[name].newValue != null)
        return `background-color: lightgreen`
      return ``
    },
    getMessageStyle(index: number) {
      if (this.importData[index].importMessage == null)
        return ''
      if (this.importData[index].importMessage.type == "ERROR")
        return `background-color: red`
      if (this.importData[index].importMessage.type == "UPDATED")
        return `background-color: orange`
      if (this.importData[index].importMessage.type == "CREATED")
        return `background-color: lightgreen`
    },
    getField(dto: any, name: any) {
      if (dto[name].newValue != null)
        return dto[name].newValue as any[]
      return dto[name].oldValue as any[]
    },
    getMessage(index: number) {
      if (this.importData == null)
        return ''
      if (this.importData.length < 1)
        return ''
      if (this.importData[index] == null)
        return ''
      if (this.importData[index].importMessage == null)
        return ''
      return this.importData[index].importMessage.message
    },
    trimSerials(serialString: String) {
      const trimmedString = serialString.slice(1, -1);
      const splitString = trimmedString.split(",");
      const result = splitString.map((str: any) => str.trim());
      var serList: any = []
      result.forEach((element: any) => {
        var serialNumberObj = {
          "serialNumber": element
        }
        serList.push(serialNumberObj)
      });
      return serList;
    },
    exportResult() {
      const headers: any = [this.$t('import', 'message')]
      this.dataTableConfig.columns.forEach((header: any) => headers.push(header.field))
      const rows = [headers.join(",")];
      this.dataMap.forEach((item: any) => {
        const values: any = []
        headers.map((header: any) => {
          if (header == this.$t('import', 'message')) {
            values.push(this.getMessage(this.dataMap.indexOf(item)))
          }
          else if (header == "serialNumbers") {
            let serialString = "";
            if (item[header].newValue == null) {
              item[header].oldValue.forEach((serial: any) => {
                serialString += serial.serialNumber + "|"
              })
            } else {
              item[header].newValue.forEach((serial: any) => {
                serialString += serial.serialNumber + "|"
              })
            }
            values.push(serialString.slice(0, -1))
          } else {
            if (item[header].newValue == null) {
              values.push(item[header].oldValue)
            } else {
              values.push(item[header].newValue)
            }
          }
        }
        );
        rows.push(values.join(","));
      });

      const csvContent = rows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      const links = document.createElement("a");
      if (links.download !== undefined) {
        const url = URL.createObjectURL(blob);
        links.setAttribute("href", url);
        links.setAttribute("download", "importResult.csv");
        links.style.visibility = "hidden";
        document.body.appendChild(links);
        links.click();
        document.body.removeChild(links);
      }
    },
    onOpenImportDialogV2() {
      (this.$refs as any)["importDlg"].onShow()
    }
  },
});
