import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-form-v" }
const _hoisted_2 = { class: "cx-dt-dd-option" }
const _hoisted_3 = {
  key: 0,
  class: "flex"
}
const _hoisted_4 = { class: "cx-chips" }
const _hoisted_5 = {
  key: 1,
  class: "flex"
}
const _hoisted_6 = { class: "cx-chips" }
const _hoisted_7 = { class: "grid" }
const _hoisted_8 = { class: "col-6" }
const _hoisted_9 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_cx_json_editor = _resolveComponent("cx-json-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_cx_label, {
      label: _ctx.$c('hostname'),
      v$: _ctx.v$.entity.name,
      approvalState: _ctx.approvalState,
      prop: "name"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          placeholder: _ctx.$c('hostname_ph'),
          modelValue: _ctx.entity.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.name) = $event)),
          disabled: !_ctx.approvalState.formEnabled
        }, null, 8, ["placeholder", "modelValue", "disabled"])
      ]),
      _: 1
    }, 8, ["label", "v$", "approvalState"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('deviceRole', 'lb'),
      v$: _ctx.v$.entity.deviceRole,
      approvalState: _ctx.approvalState,
      prop: "deviceRole"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_dropdown, {
          modelValue: _ctx.entity.deviceRole,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.deviceRole) = $event)),
          store: "deviceRole",
          optionLabel: "name",
          placeholder: _ctx.$t('deviceRole', 'ph_pl'),
          dropdownOptions: {
          route: 'deviceRoleEditor',
          getId: (en) => en?.id,
          editable: true
        },
          disabled: !_ctx.approvalState.formEnabled
        }, null, 8, ["modelValue", "placeholder", "dropdownOptions", "disabled"])
      ]),
      _: 1
    }, 8, ["label", "v$", "approvalState"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('deviceTemplate', 'lb'),
      v$: _ctx.v$.entity.deviceTemplate,
      approvalState: _ctx.approvalState,
      prop: "deviceTemplate"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_dropdown, {
          filters: {
        roleId: _ctx.entity.deviceRole?.id,
      },
          modelValue: _ctx.entity.deviceTemplate,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.deviceTemplate) = $event)),
          store: "deviceTemplate",
          optionLabel: "name",
          placeholder: _ctx.$t('deviceTemplate', 'ph_pl'),
          disabled: !_ctx.approvalState.formEnabled,
          dropdownOptions: {
          route: 'deviceTemplateEditor',
          getId: (en) => en?.id,
          editable: true
        }
        }, {
          option: _withCtx(({ option }) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", null, [
                _createElementVNode("strong", null, _toDisplayString(option?.name), 1)
              ]),
              (option?.deviceRoles.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('deviceTemplate', 'roles')), 1),
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option?.deviceRoles, (role, r) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "cx-chip",
                          key: r
                        }, _toDisplayString(role.name), 1))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (option?.deviceTemplateModels.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('deviceTemplate', 'models')), 1),
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getModels(option), (deviceModel, dm) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "cx-chip",
                          key: dm
                        }, _toDisplayString(deviceModel.name), 1))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["filters", "modelValue", "placeholder", "disabled", "dropdownOptions"])
      ]),
      _: 1
    }, 8, ["label", "v$", "approvalState"]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('managementIp'),
          approvalState: _ctx.approvalState,
          prop: "managementIp"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              modelValue: _ctx.entity.managementIp,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.managementIp) = $event)),
              placeholder: _ctx.$c('managementIp_ph'),
              disabled: !_ctx.approvalState.formEnabled
            }, null, 8, ["modelValue", "placeholder", "disabled"])
          ]),
          _: 1
        }, 8, ["label", "approvalState"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_cx_label, {
          label: _ctx.$t('loginProfile', 'lb'),
          approvalState: _ctx.approvalState,
          prop: "loginProfile"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cx_dropdown, {
              modelValue: _ctx.entity.loginProfile,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.entity.loginProfile) = $event)),
              store: "loginProfile",
              optionLabel: "name",
              placeholder: _ctx.$t('loginProfile', 'ph'),
              dropdownOptions: {
              route: 'loginProfileEditor',
              getId: (en) => en?.id,
              editable: true
            },
              disabled: !_ctx.approvalState.formEnabled
            }, null, 8, ["modelValue", "placeholder", "dropdownOptions", "disabled"])
          ]),
          _: 1
        }, 8, ["label", "approvalState"])
      ])
    ]),
    (!_ctx.isEditing)
      ? (_openBlock(), _createBlock(_component_cx_label, {
          key: 0,
          label: _ctx.$c('serialNumbers'),
          v$: _ctx.v$.serials
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Textarea, {
              placeholder: _ctx.$c('serialNumbers_ph'),
              rows: "3",
              modelValue: _ctx.serials,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.serials) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]))
      : _createCommentVNode("", true),
    _createVNode(_component_cx_label, {
      label: _ctx.$c('meta')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_json_editor, {
          modelValue: _ctx.entity.meta,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.entity.meta) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}