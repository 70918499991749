import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "grid" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "cx-cfg-version" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "cx-cfg-version" }
const _hoisted_8 = {
  key: 0,
  class: "cx-cfg-actions"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 0,
  class: "col-4 fullheight"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Diff = _resolveComponent("Diff")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (!_ctx.isDiff)
          ? (_openBlock(), _createBlock(_component_cx_label, {
              key: 0,
              label: _ctx.$t('locationDevice', 'lb')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_cx_dropdown, {
                  modelValue: _ctx.selectedDevice,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDevice) = $event)),
                  store: "locationDevice",
                  optionLabel: "name",
                  placeholder: _ctx.$t('locationDevice', 'ph'),
                  onChange: _ctx.onDeviceChanged
                }, null, 8, ["modelValue", "placeholder", "onChange"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_cx_label, { label: "Filter" }, {
          default: _withCtx(() => [
            _createVNode(_component_MultiSelect, {
              modelValue: _ctx.configFilter,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.configFilter) = $event)),
              options: _ctx.configFilterOptions,
              placeholder: _ctx.$c('filter'),
              optionLabel: "label",
              optionValue: "value",
              dataKey: "value"
            }, null, 8, ["modelValue", "options", "placeholder"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Listbox, {
            modelValue: _ctx.sourceConfiguration,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sourceConfiguration) = $event)),
            options: _ctx.filteredConfigurations,
            onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onConfigurationSelected($event, 'left'))),
            listStyle: "max-height:calc(100vh - 240px)"
          }, {
            option: _withCtx((props) => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", null, [
                  _createElementVNode("b", null, _toDisplayString(props.option.filename) + " (" + _toDisplayString(props.option.version) + ")", 1)
                ]),
                _createElementVNode("p", null, _toDisplayString(props.option.source) + ", " + _toDisplayString(props.option.status), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$cx.toTime(props.option.createdAt)), 1)
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Listbox, {
            modelValue: _ctx.targetConfiguration,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.targetConfiguration) = $event)),
            options: _ctx.filteredConfigurations,
            onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onConfigurationSelected($event, 'right'))),
            listStyle: "max-height:calc(100vh - 240px)"
          }, {
            option: _withCtx((props) => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("p", null, [
                  _createElementVNode("b", null, _toDisplayString(props.option.filename) + " (" + _toDisplayString(props.option.version) + ")", 1)
                ]),
                _createElementVNode("p", null, _toDisplayString(props.option.source) + ", " + _toDisplayString(props.option.status), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$cx.toTime(props.option.createdAt)), 1),
                (_ctx.isDiff)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      (_ctx.isRunningConfig(props.option))
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            icon: "pi pi-window-maximize",
                            class: "p-button-text",
                            onClick: ($event: any) => (_ctx.onOpenConfigWizard(props.option))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "options"])
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass((_ctx.hasThirdColumn ? 'col-4' : 'col-8') + ' fullheight')
    }, [
      (_ctx.isDiff)
        ? (_openBlock(), _createBlock(_component_Diff, {
            key: 0,
            theme: "dark",
            language: "plaintext",
            prev: _ctx.sourceConfiguration?.text,
            current: _ctx.targetConfiguration?.text
          }, null, 8, ["prev", "current"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _renderSlot(_ctx.$slots, "diff")
          ]))
    ], 2),
    (_ctx.hasThirdColumn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _renderSlot(_ctx.$slots, "third")
        ]))
      : _createCommentVNode("", true)
  ]))
}