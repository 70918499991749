import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: false,
    title: _ctx.$t('aci', 'getEPGByInterface')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_cx_label, {
        label: _ctx.$t('aci', 'sel_APIC_lb')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.apic,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.apic) = $event)),
            options: _ctx.groupedAcis,
            optionLabel: "hostname",
            optionGroupLabel: "label",
            optionGroupChildren: "items",
            placeholder: _ctx.$t('aci', 'sel_APIC_ph'),
            onChange: _ctx.onApicSelected,
            disabled: _ctx.$store.state.ciscoAci.loading,
            loading: _ctx.$store.state.ciscoAci.loading
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("span", null, _toDisplayString(option.hostname) + " (" + _toDisplayString(option.ipAddress) + ")", 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "placeholder", "onChange", "disabled", "loading"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_cx_label, {
            label: _ctx.$t('aci', 'sel_pod_lb') + ' (' + _ctx.pods.length + ')'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Listbox, {
                listStyle: "max-height:250px",
                modelValue: _ctx.pod,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pod) = $event)),
                options: _ctx.pods,
                optionLabel: "name",
                disabled: _ctx.$store.state.ciscoAci.loading,
                onChange: _ctx.onPodSelected,
                filter: true
              }, null, 8, ["modelValue", "options", "disabled", "onChange"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_cx_label, {
            label: _ctx.$t('aci', 'sel_node_lb') + ' (' + _ctx.nodes.length + ')'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Listbox, {
                listStyle: "max-height:250px",
                modelValue: _ctx.node,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.node) = $event)),
                options: _ctx.nodes,
                optionLabel: "name",
                disabled: _ctx.$store.state.ciscoAci.loading,
                onChange: _ctx.onNodeSelected,
                filter: true
              }, null, 8, ["modelValue", "options", "disabled", "onChange"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_cx_label, {
            label: _ctx.$t('aci', 'sel_interface_lb') + ' (' + _ctx.interfaces.length + ')'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Listbox, {
                listStyle: "max-height:250px",
                modelValue: _ctx.interface,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.interface) = $event)),
                options: _ctx.interfaces,
                optionLabel: "name",
                disabled: _ctx.$store.state.ciscoAci.loading,
                onChange: _ctx.onInterfaceSelected,
                filter: true
              }, null, 8, ["modelValue", "options", "disabled", "onChange"])
            ]),
            _: 1
          }, 8, ["label"])
        ])
      ]),
      _createVNode(_component_cx_label, {
        label: _ctx.$t('aci', 'result_lb')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Textarea, {
            modelValue: _ctx.text,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.text) = $event)),
            rows: 10,
            disabled: _ctx.$store.state.ciscoAci.loading
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _: 1
  }, 8, ["title"]))
}