
import { defineComponent, PropType } from "vue";
import { CxFormApi } from "@/types/cx-form-api";
import { CxTableConfig } from "../types/cx-table-config";
import {
  CxGrant,
  CxPermission,
  CxPermissionLevel,
} from "@/types/cx-permission";
export default defineComponent({
  props: {
    config: {
      type: CxTableConfig,
    },
    data: {
      type: Array,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: true
    },
    grant: {
      type: Object as PropType<CxGrant>,
      default: {
        perm: CxPermission.NONE,
        lvl: CxPermissionLevel.NONE,
      } as CxGrant,
    },
    disableCreate: {
      type: Boolean,
      default: false
    },
    title: String,
    loading: Boolean,
    entityName: String,
    deleteStore: String,
    deleteFn: Function,
    deleteDlgFn: Function,
    canDeleteFn: Function,
    dialogWidth: {
      type: String,
      default: "50vw"
    }
  },
  data() {
    return {
      dialogVisible: false,
      editorApi: {} as CxFormApi,
      editorEntity: {} as any
    };
  },
  computed: {
    isEditing() {
      if (this.editorApi == null) return false
      if (this.editorApi.isEditing == null) return false
      return this.editorApi.isEditing()
    },
    disabled() {
      if (this.grant == null) return false;
      if (this.grant.perm == CxPermission.NONE) return false;
      return !this.$auth.permits(this.grant.perm, this.$lvl.READ);
    },
    disableWrite() {
      if (this.grant == null) return false;
      if (this.grant.perm == CxPermission.NONE) return false;
      return !this.$auth.permits(this.grant.perm, this.$lvl.WRITE);
    },
    cssEditIcon() {
      if (this.grant == null) return "pi-plus";
      if (this.$auth.permits(this.grant.perm, this.grant.lvl))
        return "pi pi-plus";
      return "pi pi-lock";
    },
  },
  methods: {
    onOpenEdit(item: any) {
      this.editorEntity = item;
      this.dialogVisible = true;
      this.$nextTick(() => this.editorApi.load(item.id));
    },
    onOpenCreate() {
      this.dialogVisible = true;
      this.$nextTick(() => this.editorApi.reset());
    },
    onOpenDelete(id: number) {
      if (this.deleteDlgFn != null) {
        this.deleteDlgFn({ id: id });
        return;
      }
      this.$cx.confirmDelete(() => {
        if (this.deleteFn != null) {
          this.deleteFn(id).then(() => {
            this.$cx.notifyDeleted(this.$t(this.entityName!, 'lb'));
            this.$emit("load");
          });
          return;
        }
        let del = "/delete"
        if (!this.$cx.isNullOrEmpty(this.deleteStore))
          del = this.deleteStore!
        this.$store.dispatch(this.entityName + del, id).then(() => {
          this.$cx.notifyDeleted(this.$t(this.entityName!, 'lb'));
          this.$emit("load");
        }).catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
      });
    },
    onCancel() {
      this.dialogVisible = false;
      this.$emit("load");
    },
    onSave() {
      this.editorApi.onSave(() => {
        this.dialogVisible = false;
        this.$emit("load");
      });
    },
    canDelete(id: any) {
      if (this.canDeleteFn != null) return this.canDeleteFn(id)
      return true
    }
  },
});
