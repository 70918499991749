import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error"
}
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_cx_delete_button = _resolveComponent("cx-delete-button")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.visible) = $event)),
    header: _ctx.$t('ipPool', 'editSubnet'),
    modal: true,
    closable: false,
    class: "custom-dialog-overlay"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_cx_label, {
          label: _ctx.$t('ipPool', 'startIp'),
          v$: _ctx.v$.subnet.startIp
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              modelValue: _ctx.subnet.startIp,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.subnet.startIp) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('ipPool', 'endIp'),
          v$: _ctx.v$.subnet.endIp
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              modelValue: _ctx.subnet.endIp,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.subnet.endIp) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('ipPool', 'subnetIp'),
          v$: _ctx.v$.subnet.subnetIp
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              modelValue: _ctx.subnet.subnetIp,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.subnet.subnetIp) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('ipPool', 'gatewayIp'),
          v$: _ctx.v$.subnet.gatewayIp
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              modelValue: _ctx.subnet.gatewayIp,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.subnet.gatewayIp) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('ipPool', 'subnetPrefix'),
          v$: _ctx.v$.subnet.subnetPrefix
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputNumber, {
              modelValue: _ctx.subnet.subnetPrefix,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.subnet.subnetPrefix) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('ipPool', 'broadcastIp'),
          v$: _ctx.v$.subnet.broadcastIp
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              modelValue: _ctx.subnet.broadcastIp,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.subnet.broadcastIp) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('vlan', 'lb_pl')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MultiSelect, {
              modelValue: _ctx.subnet.vlans,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.subnet.vlans) = $event)),
              options: _ctx.vlanOptions,
              filter: true,
              autoFilterFocus: true,
              selectOnFocus: true,
              resetFilterOnHide: true,
              autoOptionFocus: true,
              optionLabel: "name",
              placeholder: _ctx.$t('vlan', 'ph_pl')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('ipPool', 'networkAssigned')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.subnet.isAssigned,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.subnet.isAssigned) = $event)),
              class: "assigned-switch"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('ipPool', 'usedAddresses')
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subnet.usedAddresses, (ip, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "ip-field"
              }, [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: _ctx.subnet.usedAddresses![index],
                  "onUpdate:modelValue": ($event: any) => ((_ctx.subnet.usedAddresses![index]) = $event),
                  onInput: ($event: any) => (_ctx.validateIP(index)),
                  placeholder: _ctx.$t('common', 'ipAddress_ph'),
                  class: "ip-input"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"]),
                _createVNode(_component_cx_delete_button, {
                  onClick: ($event: any) => (_ctx.removeIP(index))
                }, null, 8, ["onClick"]),
                (_ctx.errors[index])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.errors[index]), 1))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            _createVNode(_component_Button, {
              label: _ctx.$t('common', 'add'),
              icon: "pi pi-plus",
              onClick: _ctx.addIP,
              class: "ip-add-button"
            }, null, 8, ["label", "onClick"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            label: _ctx.$t('common', 'close'),
            icon: "pi pi-times",
            class: "p-button-secondary cancel-button",
            onClick: _ctx.close
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_Button, {
            label: _ctx.$t('common', 'save'),
            icon: "pi pi-check",
            onClick: _ctx.save,
            class: "save-button p-button-success"
          }, null, 8, ["label", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}