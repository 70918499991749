
import { VlanDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { CxFormApi } from "@/types/cx-form-api";
import { defineComponent, PropType } from "vue";
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";

export default defineComponent({
    props: {
        editorApi: {
            type: Object as PropType<CxFormApi>,
        },
        parentId: String,
    },
    data() {
        return {
            id: -1 as number,
            entity: {} as VlanDto,
        };
    },
    setup: () => ({ v$: useVuelidate() as any }),
    validations() {
        return {
            entity: {
                name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true },
            },
        };
    },
    computed: {
        isEditing() {
            return this.id > 0;
        },
    },
    created() {
        this.editorApi!.onSave = async (closeFn: any) => {
            if (await this.$cx.notifyValidationError(this.v$)) return false;

            if (this.isEditing) {
                this.$store
                    .dispatch("vlan/save", this.entity)
                    .then(() => {
                        this.$cx.notifySaved(this.$t("vlan", "lb"));
                        closeFn();
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
            } else {
                this.entity.virtualNetwork = { id: parseInt(this.parentId!) };
                this.$store
                    .dispatch("vlan/create", this.entity)
                    .then((data) => {
                        this.$cx.notifyCreated(this.$t("vlan", "lb"));
                        this.editorApi!.load(data.id);
                        closeFn()
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
            }
        };
        this.editorApi!.load = (id: number) => {
            this.id = id;
            this.$store.dispatch("vlan/getById", id).then((entity) => {
                this.entity = entity;
            });
        };
        this.editorApi!.delete = (id: number) => {
            this.$store.dispatch("vlan/delete", id).then(() => {
                this.$cx.notifyDeleted(this.$t("vlan", "lb"));
            });
        };
        this.editorApi!.reset = () => {
            this.entity = {} as VlanDto;
        };
        this.editorApi!.isEditing = () => this.id > 0;
    },
});
