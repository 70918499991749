
import { defineComponent } from "vue";

export default defineComponent({

    data() {
        return {
            showErrors: false,
            showDto: false,
            dataArrays: {},
            customizeErrorColumns: ['hostnames', 'serialNumbers']
        }
    },

    methods: {
        showImportErrors() {
            this.showDto = true;
            this.showErrors = true;
        },

        closeImportErrors() {
            this.showDto = false;
            this.showErrors = false;
        },
        foundErrors(value: any) {
            return (value.length > 0) ? true : false;
        }
    },
});

