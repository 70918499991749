
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      searchTerm: "",
    };
  },
  computed: {
    gridItems() {
      let items = [
        {
          label: this.$t('satellite', "customer"),
          icon: "pi pi-folder",
          description: this.$t('satellite', "customer_desc"),
          to: "/customers",
        },
        {
          label: this.$t('satellite', "kickstarts"),
          description: this.$t('satellite', "kickstarts_desc"),
          icon: "pi pi-server",
          to: "/satellite-kickstarts",
        },
        {
          label: this.$t('satellite', "update"),
          description: this.$t('satellite', "update_desc"),
          icon: "pi pi-table",
          to: "/satellite-update",
        }
      ];

      if (this.searchTerm.length > 0) {
        items = items.filter((i) =>
          JSON.stringify(i)
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase())
        );
      }

      return items;
    },
  },
});
