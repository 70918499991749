import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cx-loc-dev-picker" }
const _hoisted_2 = { class: "flex w-full" }
const _hoisted_3 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_cx_location_device_tree_table = _resolveComponent("cx-location-device-tree-table")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$cx.tF('common', 'selected_devices', [_ctx.selectedCount])), 1),
      _createVNode(_component_Button, {
        icon: "pi pi-pencil",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = true)),
        disabled: _ctx.disabled
      }, null, 8, ["disabled"])
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
      modal: true,
      style: { width: '95vw' }
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$c('selected_devices_title')), 1)
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$c('apply'),
          icon: "pi pi-check",
          onClick: _ctx.onApply
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_cx_location_device_tree_table, {
          modelValue: _ctx.localValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue) = $event)),
          height: "calc(100vh - 300px)"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}