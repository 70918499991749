import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-2" }
const _hoisted_3 = { class: "cx-chips" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "cx-chip" }
const _hoisted_6 = { class: "cx-chips" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "cx-chip" }
const _hoisted_9 = { class: "col-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_cx_table_filter = _resolveComponent("cx-table-filter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_cx_label, { label: "Bereich" }, {
        default: _withCtx(() => [
          _createVNode(_component_MultiSelect, {
            modelValue: _ctx.areas,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.areas) = $event)),
            options: _ctx.areaOptions,
            filter: true,
            "filter-fields": ['name'],
            placeholder: _ctx.$t('role', 'ph_pl'),
            filterPlaceholder: _ctx.$c('filter'),
            dataKey: "value",
            onChange: _ctx.onFilterChanged
          }, {
            value: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.value, (option) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "cx-chip",
                    key: option.value
                  }, [
                    _createTextVNode(_toDisplayString(option.name), 1),
                    _createElementVNode("i", {
                      "aria-hidden": "true",
                      class: "pi pi-times",
                      onClick: ($event: any) => (_ctx.onRemoveFilter(_ctx.areas, option))
                    }, null, 8, _hoisted_4)
                  ]))
                }), 128))
              ]),
              (!slotProps.value || slotProps.value.length === 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(slotProps.placeholder), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            option: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_5, _toDisplayString(slotProps.option.name), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "placeholder", "filterPlaceholder", "onChange"])
        ]),
        _: 1
      }),
      _createVNode(_component_cx_label, { label: "Level" }, {
        default: _withCtx(() => [
          _createVNode(_component_MultiSelect, {
            modelValue: _ctx.levels,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.levels) = $event)),
            options: _ctx.levelOptions,
            filter: true,
            "filter-fields": ['name'],
            placeholder: _ctx.$t('role', 'ph_pl'),
            filterPlaceholder: _ctx.$c('filter'),
            dataKey: "value",
            onChange: _ctx.onFilterChanged
          }, {
            value: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.value, (option) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "cx-chip",
                    key: option.value
                  }, [
                    _createTextVNode(_toDisplayString(option.name), 1),
                    _createElementVNode("i", {
                      "aria-hidden": "true",
                      class: "pi pi-times",
                      onClick: ($event: any) => (_ctx.onRemoveFilter(_ctx.levels, option))
                    }, null, 8, _hoisted_7)
                  ]))
                }), 128))
              ]),
              (!slotProps.value || slotProps.value.length === 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(slotProps.placeholder), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            option: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_8, _toDisplayString(slotProps.option.name), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "placeholder", "filterPlaceholder", "onChange"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_cx_table_filter, {
        stateKey: "locationDeviceLog",
        data: _ctx.logs,
        config: _ctx.tableConfig,
        ref: "tbl",
        onFilter: _ctx.load,
        tableStyle: "height: calc(100vh - 160px)",
        defaultFilters: { 's_time': 'desc' },
        loading: false
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_Column, {
            header: _ctx.$c('actions'),
            frozen: "",
            alignFrozen: "right",
            headerStyle: "width:110px"
          }, {
            filter: _withCtx(() => [
              _createVNode(_component_Button, {
                icon: "pi pi-filter-slash",
                class: "align-self-end p-button-text",
                onClick: _ctx.onClearFilters
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["data", "config", "onFilter"])
    ])
  ]))
}