import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_Button = _resolveComponent("Button")!
  const _component_cx_save_button = _resolveComponent("cx-save-button")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.approvalState.enabled)
      ? (_openBlock(), _createBlock(_component_SplitButton, {
          key: 0,
          label: _ctx.label,
          onClick: _ctx.onClick,
          class: _normalizeClass(_ctx.classes),
          icon: _ctx.icons,
          model: _ctx.items
        }, null, 8, ["label", "onClick", "class", "icon", "model"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.approvalState.dataExists)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.approvalState.isReview)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      disabled: !_ctx.approvalState.isViewingData,
                      icon: "pi pi-save",
                      label: _ctx.$t('approval', 'request'),
                      class: "mr-2",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onOpenDialog('request')))
                    }, null, 8, ["disabled", "label"]))
                  : _createCommentVNode("", true),
                (_ctx.approvalState.canApprove)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(_component_Button, {
                        disabled: !_ctx.approvalState.isViewingData,
                        icon: "pi pi-check",
                        label: _ctx.$t('approval', 'approve'),
                        class: "mr-2 p-button-success",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onOpenDialog('approve')))
                      }, null, 8, ["disabled", "label"]),
                      (!_ctx.isNewDataset)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            disabled: !_ctx.approvalState.isViewingData,
                            icon: "pi pi-arrow-right",
                            label: _ctx.$t('approval', 'review'),
                            class: "mr-2 p-button-danger",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onOpenDialog('review')))
                          }, null, 8, ["disabled", "label"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Button, {
                        disabled: !_ctx.approvalState.isViewingData,
                        icon: "pi pi-times",
                        label: _ctx.$t('approval', 'reject'),
                        class: "mr-2 p-button-danger",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onOpenDialog('reject')))
                      }, null, 8, ["disabled", "label"])
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (!_ctx.isEditing && _ctx.canCreate)
                  ? (_openBlock(), _createBlock(_component_cx_save_button, {
                      key: 0,
                      isEditing: _ctx.isEditing,
                      onSave: _cache[4] || (_cache[4] = (ev) => _ctx.$emit('save', ev)),
                      onCancel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$cx.goTo('deviceModel'))),
                      grant: _ctx.grant
                    }, null, 8, ["isEditing", "grant"]))
                  : (_openBlock(), _createBlock(_component_Button, {
                      key: 1,
                      icon: "pi pi-save",
                      label: _ctx.$t('approval', 'request'),
                      class: "mr-2",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onOpenDialog('request')))
                    }, null, 8, ["label"]))
              ], 64))
        ], 64)),
    _createVNode(_component_Dialog, {
      header: _ctx.approvalDialogHeader,
      visible: _ctx.approvalDialogVisible,
      "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.approvalDialogVisible) = $event)),
      modal: true,
      style: { width: '50vw' }
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          icon: _ctx.approvalDialogIcon,
          class: _normalizeClass(_ctx.approvalDialogSaveClass),
          label: _ctx.approvalDialogSaveLabel,
          onClick: _ctx.onSaveApproval
        }, null, 8, ["icon", "class", "label", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.$c('cancel'),
          icon: "pi pi-times",
          class: "p-button-secondary",
          onClick: _ctx.onCancel
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", null, [
          _createElementVNode("pre", null, _toDisplayString(_ctx.approvalDialogText), 1)
        ]),
        (_ctx.prevCommentVisible)
          ? (_openBlock(), _createBlock(_component_cx_label, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_Textarea, {
                  modelValue: _ctx.prevComment,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.prevComment) = $event)),
                  placeholder: _ctx.$c('comment_ph'),
                  disabled: true
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_cx_label, null, {
          default: _withCtx(() => [
            _createVNode(_component_Textarea, {
              modelValue: _ctx.comment,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.comment) = $event)),
              placeholder: _ctx.$c('comment_ph')
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ]))
}