import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cisco_aci_form = _resolveComponent("cisco-aci-form")!
  const _component_cx_crud_table = _resolveComponent("cx-crud-table")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    title: _ctx.$t('aci', 'APIC_settings')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_cx_crud_table, {
        onLoad: _ctx.load,
        data: _ctx.acis,
        entityName: "ciscoAci",
        config: _ctx.tableConfig,
        loading: false,
        deleteStore: "/deleteAci"
      }, {
        editor: _withCtx(({ props }) => [
          _createVNode(_component_cisco_aci_form, { editorApi: props }, null, 8, ["editorApi"])
        ]),
        _: 1
      }, 8, ["onLoad", "data", "config"])
    ]),
    _: 1
  }, 8, ["title"]))
}