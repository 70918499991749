import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplitButton = _resolveComponent("SplitButton")!

  return (_openBlock(), _createBlock(_component_SplitButton, {
    label: _ctx.label,
    onClick: _ctx.onClick,
    class: _normalizeClass(_ctx.classes),
    icon: _ctx.icons,
    model: _ctx.items
  }, null, 8, ["label", "onClick", "class", "icon", "model"]))
}