import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    icon: ('pi ' + _ctx.$auth.cssEditIcon(_ctx.grant.perm, _ctx.grant.lvl)),
    onClick: _ctx.click,
    disabled: _ctx.disabled
  }, null, 8, ["icon", "onClick", "disabled"]))
}