
import { ScheduleDto, CxApprovalState, CxEntityType } from "@/types/dto";
import { defineComponent } from "vue";
import { CxFormApi } from "@/types/cx-form-api";
import { ApprovalState } from "@/types/approval-state";

export default defineComponent({
  props: {
    id: String,
  },
  data() {
    return {
      entity: {} as ScheduleDto,
      initialEntity: {} as ScheduleDto,
      scheduleApi: {} as CxFormApi,
      approvalState: new ApprovalState(CxEntityType.SCHEDULE),
      isDeactivating: false
    };
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
  },
  mounted() {
    this.load();

    try {
      // check if text payload is provided
      if ("args" in this.$route.query) {
        let payload = JSON.parse(atob(this.$route.query.args!.toString()));
        if (payload != null) {
          this.entity.name = payload.name;
          this.entity.codeTemplate = payload.codeTemplate;
          this.entity.locationDevices = payload.devices;
          (this.$refs as any)['scheduleForm'].applyEntity(this.entity);
          this.$cx.goTo('scheduleEditorNew');
        }
      }
    } catch (err) {
      console.error(err)
    }
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.scheduleApi.notifyUnsavedChanges(next)
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.scheduleApi.load(this.id);
        this.approvalState.setStore("schedule", this.id!)
        this.approvalState.fetchApprovalData()
      }
    },
    async save(goBack: Boolean) {
      this.scheduleApi.onSave((newEntity: ScheduleDto) => {
        if (this.isEditing) {
          if (goBack) this.$cx.goTo("schedule");
        } else {
          if (goBack) this.$cx.goTo("schedule");
          else this.$cx.goToById("scheduleEditor", newEntity.id!);
        }
      })
    },
    onApprovalViewChanged(data: any, isViewingChanges: boolean) {
      if (isViewingChanges) {
        (this.$refs as any)['approval'].apply(this.entity, data)
      } else {
        (this.$refs as any)['approval'].apply(this.entity, (this.$refs as any)['scheduleForm'].initialEntity)
      }
    },

    onApprovalChanged(state: CxApprovalState) {
      (this.$refs as any)['approval'].reset()
      this.load()
    },

    onEntityChanged(entity: any, initialEntity: any) {
      this.entity = entity
      this.initialEntity = initialEntity
    },

    onActivate() {
      this.$store.dispatch("schedule/activate", {
        scheduleId: this.entity.id,
        activated: true
      })
        .then(() => {
          this.load();
          this.$cx.success(this.$t('schedule', 'successActivated'), '');
          (this.$refs as any)["scheduleForm"].onActivationChanged();
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("error")));
    },
    onDeactivate() {
      this.isDeactivating = true
      this.$store.dispatch("schedule/activate", {
        scheduleId: this.entity.id,
        activated: false
      })
        .then(() => {
          this.load()
          this.$cx.success(this.$t('schedule', 'successDeactivated'), '')
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("error")))
        .finally(() => {
          this.isDeactivating = false
        })
    }

  },
});
