import { DevicePortTypeDto } from "@/types/dto";
import CxApi from "@/types/cx-api";
import { api } from "@/_config/api";

const entityName = "devicePortType";
const cxApi = new CxApi<DevicePortTypeDto>(entityName);

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    async getWithFilter(context: any, props: any) {
      try {
        context.state.loading = true;
        let response = await api.get(`${entityName}/filter`, {
          params: {
            ...{
              page: props.page,
              size: props.size,
            },
            ...props.filters,
          },
        });
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async getAll(context: any) {
      context.state.loading = true;
      try {
        let response = await cxApi.getAll();
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getById(context: any, id: number) {
      context.state.loading = true;
      try {
        let response = await cxApi.getById(id);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async save(context: any, entity: DevicePortTypeDto) {
      context.state.loading = true;
      try {
        let response = await cxApi.save(entity);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async create(context: any, entity: DevicePortTypeDto) {
      context.state.loading = true;
      try {
        let response = await cxApi.create(entity);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async delete(context: any, id: number) {
      context.state.loading = true;
      try {
        let response = await cxApi.delete(id);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async getImportTemplate(context: any) {
      context.state.loading = true;
      try {
        let response = await api.get(entityName + `/importTemplate`);
        let attachment =
          response.headers["content-disposition"] ||
          response.headers["Content-Disposition"];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(
          attachment!
        )![0];
        filename = filename.split("=")[1].replaceAll('"', "");
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        context.state.loading = false;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async getExport(context: any) {
      context.state.loading = true;
      try {
        let response = await api.get(entityName + `/export?type=CSV`);
        let attachment =
          response.headers["content-disposition"] ||
          response.headers["Content-Disposition"];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(
          attachment!
        )![0];
        filename = filename.split("=")[1].replaceAll('"', "");
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        context.state.loading = false;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async importData(context: any, payload: any) {
      context.state.loading = true;
      try {
        let url = `/import/verify?delimiter=${payload.delimiter}`;
        if (payload.save) {
          url = `/import/save?delimiter=${payload.delimiter}`;
        }
        let response = await api.post(entityName + url, payload.file);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
  },
};
