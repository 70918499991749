import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-title" }
const _hoisted_2 = { class: "cx-grid-items" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: false,
    title: "Meraki"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gridItems, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "cx-grid-page",
          key: i
        }, [
          _createElementVNode("div", _hoisted_1, _toDisplayString(item.title), 1),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.elements, (el, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                onClick: ($event: any) => (_ctx.$router.push(el.to))
              }, [
                _createElementVNode("i", {
                  "aria-hidden": "true",
                  class: _normalizeClass(el.icon)
                }, null, 2),
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(el.label), 1),
                  _createElementVNode("p", null, _toDisplayString(el.description), 1)
                ])
              ], 8, _hoisted_3))
            }), 128))
          ])
        ]))
      }), 128))
    ]),
    _: 1
  }))
}