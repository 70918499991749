
import { defineComponent } from "vue";

export default defineComponent({
    props: ['json'],
    data() {
        return {
            result: {} as any
        }
    }
});
