import { RouteRecordRaw } from "vue-router"

import ConfigurationEditor from "./ConfigurationEditor.vue";
import ConfigurationWizard from "./ConfigurationWizard.vue";
import ConfigurationOverview from "./ConfigurationOverview.vue";

const routes: Array<RouteRecordRaw> = [
    // Configurations
    {
        path: "/configuration",
        name: "configuration",
        component: ConfigurationOverview,
        meta: { menu: "configuration" },
    },
    {
        path: "/configuration/new",
        name: "configurationEditorNew",
        component: ConfigurationEditor,
        meta: { menu: "configuration" },
    },
    {
        path: "/configuration/wizard",
        name: "configurationWizard",
        component: ConfigurationWizard,
        meta: { menu: "configuration" },
    },
    {
        path: "/configuration/:id",
        name: "configurationEditor",
        component: ConfigurationEditor,
        meta: { menu: "configuration" },
        props: true,
    },
]

export default routes