
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        modelValue: [Array, Object],
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        selectedCount() {
            if (this.modelValue != null) return (this.modelValue! as any).length
            return 0
        }
    },
    mounted() {
        this.onModelValueChanged()
    },
    watch: {
        modelValue: function (v: any) {
            this.onModelValueChanged()
        },
        visible: function () {
            this.onModelValueChanged()
        }
    },
    data() {
        return {
            visible: false,
            localValue: [] as any
        }
    },
    methods: {
        onApply() {
            this.$emit('update:modelValue', this.localValue)
            this.visible = false
        },
        onModelValueChanged() {
            if (this.modelValue)
                this.localValue = [...(this.modelValue as any)]
        }
    }
});
