
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";
import { CxExportType } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      virtualNetworks: [],
      filters: { global: {} } as any,
    };
  },
  computed: {
    tableConfig() {
      let tc = new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("description", this.$c("description")),
        new CxTableColumn("asn", this.$t('virtualNetwork', "asn")),
        new CxTableColumn("vpnNumber", this.$t('virtualNetwork', "vpnNumber")),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
      tc.filters = this.filters;
      return tc;
    },
    importTableConfig(): CxTableConfig {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("description", this.$c("description")),
        new CxTableColumn("asn", this.$t('virtualNetwork', "asn")),
        new CxTableColumn("vpnNumber", this.$t('virtualNetwork', "vpnNumber")),
        new CxTableColumn("meta", "", CxDataType.Custom)
      ])
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$store
        .dispatch("virtualNetwork/getAll")
        .then((virtualNetworks) => {
          this.virtualNetworks = virtualNetworks;
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onDelete(data: any) {
      this.$cx.confirmDeleteDetail(
        data.name,
        () => {
          this.$store
            .dispatch("virtualNetwork/delete", data.id)
            .then(() => {
              this.$cx.notifyDeleted(this.$t("virtualNetwork", "lb"))
              this.load()
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
        })
    }
  },
});
