
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        queryLocDevId: {
            type: Number,
            default: -1
        },
        queryLogId: {
            type: Number,
            default: -1
        }
    },
    emits: ['logChanged'],
    data() {
        return {
            logText: { logbuf: "" },
            selectedDev: null as any,
            logs: [] as any[],
            selectedLog: null as any,
        };
    },
    methods: {
        getLogsByLocationDeviceId(id: number) {
            this.$store.dispatch("log/getLogsByLocationDeviceId", id)
                .then((data) => {
                    if (data.length == 0) {
                        this.selectedLog = null
                        this.logText = { logbuf: "" }
                    }
                    this.logs = data.map((l: any) => {
                        let message = {
                            logbuf: l.logMessage,
                            status: '',
                            version: '',
                            serial: l.serialNumber.serialNumber
                        }

                        try {
                            message = JSON.parse(l.logMessage)
                        } catch (err) {
                            console.log(err)
                        }

                        return {
                            createdAt: l.createdAt,
                            logMessage: message,
                            id: l.id
                        }
                    })

                    if (this.queryLocDevId == id) {
                        this.selectedLog = this.logs.find(l => l.id == this.queryLogId)
                        if (this.selectedLog == null && this.logs.length > 0) {
                            this.selectedLog = this.logs[0]
                        }
                        this.onLogChanged({ value: this.selectedLog })
                    }
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },
        onLogChanged(ev: any) {
            if (ev.value == null) return
            if ("logMessage" in ev.value) {
                this.logText = ev.value.logMessage
                this.$emit('logChanged', ev.value)
            }
        },
        getStatusStyle(s: string) {
            let style = "margin-right:5px;padding: 7px;border-radius: 50%;color: white;background-color:"
            switch (s.toLowerCase()) {
                case "finished":
                    style += "#90cd93"
                    break;
                case "renumbered":
                    style += "#61beb5"
                    break;
                case "upgrading":
                    style += "#af50bf"
                    break;
                case "failed":
                    style += "#ff4032"
                    break;
            }
            return style
        }
    },
});
