
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        id: String,
    },
    setup: () => ({ v$: useVuelidate() as any }),
    validations() {
        return {
            entity: {
                // name: { required, autoDirty: true },
            },
        };
    },
    data() {
        return {
            entity: {} as any,
            initialState: {} as any,
            wizardSteps: [{
                icon: 'pi pi-user',
                label: 'Standort-Geräte'
            },
            {
                icon: 'pi pi-calendar',
                label: 'Rollout'
            },
            {
                icon: 'pi pi-check',
                label: 'Logs'
            }],
            locationDevices: [{ "id": 12, "name": "MUC-B18-AS-4DEG-012" }, { "id": 15, "name": "MUC-B18-AS-5C40A-01" }]
        };
    },
    computed: {
        isEditing() {
            return this.id != null;
        }
    },
    beforeRouteLeave(to: any, from: any, next: any) {
        this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
    },
    methods: {
    },
});
