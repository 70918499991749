import { RouteRecordRaw } from "vue-router"

import CiscoMerakiOverview from "./CiscoMerakiOverview.vue"
import CiscoMerakiNetwork from "./CiscoMerakiNetwork.vue"
import CiscoMerakiDevice from "./CiscoMerakiDevice.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: '/meraki',
        name: 'meraki',
        component: CiscoMerakiOverview,
        meta: { menu: "meraki" }
    },
    {
        path: '/meraki/networks',
        name: 'merakiNetworks',
        component: CiscoMerakiNetwork,
        meta: { menu: "meraki" }
    },
    {
        path: '/meraki/devices',
        name: 'merakiDevices',
        component: CiscoMerakiDevice,
        meta: { menu: "meraki" }
    },
]


export default routes