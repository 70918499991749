import { RoleDto, UpdateRoleRequest } from "@/types/dto";
import CxApi from "@/types/cx-api";
import { api } from "@/_config/api";

const entityName = "role";
const cxApi = new CxApi<RoleDto>(entityName);

export default {
    namespaced: true,
    state: {
        loading: false,
        loadingUsers: false
    },
    actions: {
        async getAll(context: any) {
            context.state.loading = true;
            try {
                let response = await cxApi.getAll();
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async getAllUsers(context: any) {
            context.state.loadingUsers = true;
            try {
                let response = await api.get(`${entityName}/user`);
                context.state.loadingUsers = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loadingUsers = false;
                return Promise.reject(error);
            }
        },

        async saveUser(context: any, entity: any) {
            context.state.loadingUsers = true;
            try {
                let response = await api.put(`${entityName}/user`, entity);
                context.state.loadingUsers = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loadingUsers = false;
                return Promise.reject(error);
            }
        },

        async getById(context: any, id: number) {
            context.state.loading = true;
            try {
                let response = await cxApi.getById(id);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async save(context: any, entity: UpdateRoleRequest) {
            context.state.loading = true;
            try {
                let response = await api.put(`${entityName}`, entity);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async create(context: any, entity: UpdateRoleRequest) {
            context.state.loading = true;
            try {
                let response = await api.post(`${entityName}`, entity);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async delete(context: any, entity: any) {
            context.state.loading = true;
            try {
                let response = await api.delete(`${entityName}?name=` + entity.name);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        }
    }
};

