import { RouteRecordRaw } from "vue-router"

import DeviceFamily from "./family/DeviceFamily.vue";
import DeviceFamilyEditor from "./family/DeviceFamilyEditor.vue";

import DeviceModel from "./model/DeviceModel.vue";
import DeviceModelEditor from "./model/DeviceModelEditor.vue";

import DevicePortType from "./port/DevicePortType.vue";
import DevicePortTypeEditor from "./port/DevicePortTypeEditor.vue";

import DevicePortUse from "./port/DevicePortUse.vue";
import DevicePortUseEditor from "./port/DevicePortUseEditor.vue";

import DeviceRole from "./role/DeviceRole.vue";
import DeviceRoleEditor from "./role/DeviceRoleEditor.vue";

import DeviceTemplate from "./template/DeviceTemplate.vue";
import DeviceTemplateEditor from "./template/DeviceTemplateEditor.vue";

import LocationDeviceEditor from "./LocationDeviceEditor.vue";

import OS from "./os/OS.vue";
import OSEditor from "./os/OSEditor.vue";

const deviceRoutes: Array<RouteRecordRaw> = [
    // DeviceFamily
    {
        path: "/deviceFamily",
        name: "deviceFamily",
        component: DeviceFamily,
        meta: { menu: "management" },
    },
    {
        path: "/deviceFamily/new",
        name: "deviceFamilyEditorNew",
        component: DeviceFamilyEditor,
        meta: { menu: "management" },
    },
    {
        path: "/deviceFamily/:id",
        name: "deviceFamilyEditor",
        component: DeviceFamilyEditor,
        meta: { menu: "management" },
        props: true,
    },

    // DeviceTemplate
    {
        path: "/deviceTemplate",
        name: "deviceTemplate",
        component: DeviceTemplate,
        meta: { menu: "management" },
    },
    {
        path: "/deviceTemplate/new",
        name: "deviceTemplateEditorNew",
        component: DeviceTemplateEditor,
        meta: { menu: "management" }
    },
    {
        path: "/deviceTemplate/:id",
        name: "deviceTemplateEditor",
        component: DeviceTemplateEditor,
        meta: { menu: "management" },
        props: true,
    },

    // DeviceModel
    {
        path: "/deviceModel",
        name: "deviceModel",
        component: DeviceModel,
        meta: { menu: "management" },
    },
    {
        path: "/deviceModel/new",
        name: "deviceModelEditorNew",
        component: DeviceModelEditor,
        meta: { menu: "management" },
    },
    {
        path: "/deviceModel/:id",
        name: "deviceModelEditor",
        component: DeviceModelEditor,
        meta: { menu: "management" },
        props: true,
    },
    {
        path: "/devicePortType",
        name: "devicePortType",
        component: DevicePortType,
        meta: { menu: "management" },
    },
    // DeviceRole
    {
        path: "/deviceRole",
        name: "deviceRole",
        component: DeviceRole,
        meta: { menu: "management" },
    },
    {
        path: "/deviceRole/new",
        name: "deviceRoleEditorNew",
        component: DeviceRoleEditor,
        meta: { menu: "management" },
    },
    {
        path: "/deviceRole/:id",
        name: "deviceRoleEditor",
        component: DeviceRoleEditor,
        meta: { menu: "management" },
        props: true,
    },
    {
        path: "/devicePortType/new",
        name: "devicePortTypeEditorNew",
        component: DevicePortTypeEditor,
        meta: { menu: "management" },
    },
    {
        path: "/devicePortType/:id",
        name: "devicePortTypeEditor",
        component: DevicePortTypeEditor,
        meta: { menu: "management" },
        props: true,
    },
    {
        path: "/devicePortUse",
        name: "devicePortUse",
        component: DevicePortUse,
        meta: { menu: "management" },
    },
    {
        path: "/devicePortUse/new",
        name: "devicePortUseEditorNew",
        component: DevicePortUseEditor,
        meta: { menu: "management" },
    },
    {
        path: "/devicePortUse/:id",
        name: "devicePortUseEditor",
        component: DevicePortUseEditor,
        meta: { menu: "management" },
        props: true,
    },
    {
        path: "/os",
        name: "os",
        component: OS,
        meta: { menu: "management" },
    },
    {
        path: "/os/new",
        name: "osEditorNew",
        component: OSEditor,
        meta: { menu: "management" },
    },
    {
        path: "/os/:id",
        name: "osEditor",
        component: OSEditor,
        meta: { menu: "management" },
        props: true,
    },

    // LocationDevice
    {
        path: "/locationDevice/:id",
        name: "locationDeviceEditor",
        component: LocationDeviceEditor,
        meta: { menu: "location" },
        props: true,
    },
]

export default deviceRoutes