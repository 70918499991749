import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex cx-perm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_SelectButton = _resolveComponent("SelectButton")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "cx-perm-container noselect",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$c('rights')), 1),
      _createVNode(_component_InputText, {
        modelValue: _ctx.permissionSearchText,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.permissionSearchText) = $event)),
        placeholder: _ctx.$c('filter')
      }, null, 8, ["modelValue", "placeholder"])
    ]),
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.containerStyle)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.permissionOptions), (permission) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, _toDisplayString(permission.label), 1),
          _createVNode(_component_SelectButton, {
            modelValue: _ctx.getPermission(permission),
            onChange: ($event: any) => (_ctx.setPermission($event, permission)),
            options: [
                    {
                        i: 'pi-eye-slash',
                        v: 'A',
                        t: _ctx.$c('hidden'),
                        c: '#f44336'
                    },
                    {
                        i: 'pi-eye',
                        v: 'B',
                        t: _ctx.$c('read'),
                        c: '#8FD400'
                    }, {
                        i: 'pi-pencil',
                        v: 'C',
                        t: _ctx.$c('write'),
                        c: '#24387f'
                    }, {
                        i: 'pi-unlock',
                        v: 'D',
                        t: _ctx.$c('approve'),
                        c: '#7f247d'
                    }],
            optionLabel: "i",
            optionValue: "v"
          }, {
            option: _withCtx((slotProps) => [
              _createElementVNode("div", {
                class: "cx-perm-option",
                style: _normalizeStyle(('background-color: ' + slotProps.option.c))
              }, [
                _withDirectives(_createElementVNode("i", {
                  class: _normalizeClass('pi ' + slotProps.option.i)
                }, null, 2), [
                  [
                    _directive_tooltip,
                    slotProps.option.t,
                    void 0,
                    { bottom: true }
                  ]
                ])
              ], 4)
            ]),
            _: 2
          }, 1032, ["modelValue", "onChange", "options"])
        ]))
      }), 256))
    ], 4)
  ], 4))
}