import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "col-6 subnet-row" }
const _hoisted_4 = { class: "grid" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "grid" }
const _hoisted_8 = { class: "col-6" }
const _hoisted_9 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Button = _resolveComponent("Button")!
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_cx_json_editor = _resolveComponent("cx-json-editor")!
  const _component_SubnetCreateDialog = _resolveComponent("SubnetCreateDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_cx_label, {
            label: _ctx.$c('ip'),
            v$: _ctx.v$.entity.ip
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InputText, {
                placeholder: _ctx.$c('ip_ph'),
                modelValue: _ctx.entity.ip,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.ip) = $event)),
                disabled: _ctx.disabled
              }, null, 8, ["placeholder", "modelValue", "disabled"])
            ]),
            _: 1
          }, 8, ["label", "v$"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_cx_label, {
            label: _ctx.$c('subnet'),
            v$: _ctx.v$.entity.subnet,
            class: "subnet-label"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InputText, {
                placeholder: _ctx.$c('subnet_ph'),
                modelValue: _ctx.entity.subnet,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.subnet) = $event)),
                disabled: _ctx.disabled
              }, null, 8, ["placeholder", "modelValue", "disabled"])
            ]),
            _: 1
          }, 8, ["label", "v$"]),
          _createVNode(_component_Button, {
            icon: "pi pi-plus",
            onClick: _ctx.onCreateSubnet,
            class: "create-subnet-button"
          }, null, 8, ["onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_cx_label, {
            label: _ctx.$c('port')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cx_dropdown, {
                modelValue: _ctx.entity.devicePort,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.devicePort) = $event)),
                store: "locationDeviceIp",
                optionLabel: "name",
                filters: {
                        mode: 'physical',
                        locationDeviceId: parseInt(_ctx.locationDeviceId!)
                    },
                placeholder: _ctx.$c('port_ph')
              }, null, 8, ["modelValue", "filters", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_cx_label, {
            label: _ctx.$t('logicalInterface', 'lb')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cx_dropdown, {
                modelValue: _ctx.entity.logicalInterface,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.logicalInterface) = $event)),
                store: "locationDeviceIp",
                optionLabel: "name",
                filters: {
                        mode: 'logical',
                        locationDeviceId: parseInt(_ctx.locationDeviceId!)
                    },
                placeholder: _ctx.$t('logicalInterface', 'ph')
              }, null, 8, ["modelValue", "filters", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_cx_label, {
            label: _ctx.$t('virtualNetwork', 'lb')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cx_dropdown, {
                modelValue: _ctx.entity.virtualNetwork,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.entity.virtualNetwork) = $event)),
                store: "locationDeviceIp",
                optionLabel: "name",
                filters: {
                        mode: 'vrf',
                        locationDeviceId: parseInt(_ctx.locationDeviceId!)
                    },
                placeholder: _ctx.$t('virtualNetwork', 'ph')
              }, null, 8, ["modelValue", "filters", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_cx_label, {
            label: _ctx.$t('vlan', 'lb')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cx_dropdown, {
                modelValue: _ctx.entity.vlan,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.entity.vlan) = $event)),
                store: "locationDeviceIp",
                optionLabel: "name",
                filters: {
                        mode: 'vlan',
                        locationDeviceId: parseInt(_ctx.locationDeviceId!)
                    },
                placeholder: _ctx.$t('vlan', 'ph')
              }, null, 8, ["modelValue", "filters", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"])
        ])
      ]),
      _createVNode(_component_cx_label, {
        label: _ctx.$c('description'),
        v$: _ctx.v$.entity.description
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Textarea, {
            rows: "4",
            autoResize: false,
            modelValue: _ctx.entity.description,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.entity.description) = $event)),
            placeholder: _ctx.$c('description_ph')
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _: 1
      }, 8, ["label", "v$"]),
      _createVNode(_component_cx_label, {
        label: _ctx.$c('meta')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_cx_json_editor, {
            modelValue: _ctx.entity.meta,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.entity.meta) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _createElementVNode("template", null, [
      _createVNode(_component_SubnetCreateDialog, {
        visible: _ctx.createSubnetVisible,
        "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => (_ctx.createSubnetVisible = $event))
      }, null, 8, ["visible"])
    ])
  ], 64))
}