import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import Settings from "./views/Settings.vue";
import Dashboard from "./views/Dashboard.vue";
import Management from "./views/Management.vue";
import UniversalExtender from "./extender/universal/UniversalExtender.vue";

let routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: { "menu": "dashboard" }
  },
  {
    path: "/management",
    name: "Management",
    component: Management,
    meta: { menu: "management" },
  },

  // Settings
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: { menu: "management" },
  },

  // Extender
  {
    path: "/" + ((window as any).env?.VUE_APP_EXTENDER_CUSTOMER || process.env.VUE_APP_EXTENDER_CUSTOMER),
    name: "extenderHome",
    component: require("./extender/" +
      ((window as any).env?.VUE_APP_EXTENDER_CUSTOMER || process.env.VUE_APP_EXTENDER_CUSTOMER) +
      "/Home.vue").default,
  },

  {
    path: "/universal",
    name: "universalExtender",
    component: UniversalExtender,
  },
];

// auto load and append sub-routes
const autoRoutes = require.context("../", true, /.*-routes\.ts$/)

autoRoutes.keys().map((file) => [file.replace(/(^.\/.*\/)|(\.ts$)/g, ""), autoRoutes(file)])
  .reduce((r: any, [name, route]) => {
    routes = routes.concat(route.default)
  }, {})

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
