import { RouteRecordRaw } from "vue-router"

import SoftwareManagement from "./SoftwareManagement.vue";


const dashboardRoutes: Array<RouteRecordRaw> = [
    // SoftwareManagement
    {
        path: "/softwareManagement",
        name: "softwareManagement",
        component: SoftwareManagement,
        meta: { "menu": "software" }
    }
]

export default dashboardRoutes