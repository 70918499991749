import { api } from "@/_config/api";

const entityName = "ztp";

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    async getLogsByLocationDeviceId(context: any, locationDeviceId: Number) {
      context.state.loading = true;
      try {
        let response = await api.get(`${entityName}/log/${locationDeviceId}`);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
  },
};
