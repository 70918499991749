import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "cx-form-h-30" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_save_button = _resolveComponent("cx-save-button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Password = _resolveComponent("Password")!
  const _component_Button = _resolveComponent("Button")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: _ctx.$store.state.apiParam.loading,
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'apiParam'),
    detail: _ctx.entity.name
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_save_button, {
        isEditing: _ctx.isEditing,
        onSave: _ctx.save,
        onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('apiParam')))
      }, null, 8, ["isEditing", "onSave"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('name'),
          v$: _ctx.v$.entity.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('name_ph'),
              modelValue: _ctx.entity.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.name) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('apiParam', 'apiType'),
          v$: _ctx.v$.entity.type
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Dropdown, {
              placeholder: _ctx.$t('apiParam', 'apiType_ph'),
              modelValue: _ctx.entity.type,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.type) = $event)),
              options: _ctx.apiTypeOptions,
              optionLabel: "label",
              optionValue: "value"
            }, null, 8, ["placeholder", "modelValue", "options"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('apiParam', 'url'),
          v$: _ctx.v$.entity.url
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$t('apiParam', 'url_ph'),
              modelValue: _ctx.entity.url,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.url) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('apiParam', 'username'),
          v$: _ctx.v$.entity.username
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$t('apiParam', 'username_ph'),
              modelValue: _ctx.entity.username,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.entity.username) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('apiParam', 'password'),
          v$: _ctx.v$.entity.password
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Password, {
              placeholder: _ctx.$t('apiParam', 'password_ph'),
              modelValue: _ctx.entity.password,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.entity.password) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        (_ctx.isEditing && _ctx.entity.type == 'CATALYST_CENTER')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "float-right",
              label: _ctx.$t('apiParam', 'testConnection'),
              icon: "pi pi-bolt",
              loading: _ctx.$store.state.apiParam.testConnectionLoading,
              onClick: _ctx.testConnection
            }, null, 8, ["label", "loading", "onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["loading", "title", "detail"]))
}