
import {defineComponent, PropType} from "vue";
import useVuelidate from "@vuelidate/core";
import {IPPoolDto, IPPoolSubnetDto, VlanDto} from "@/types/dto";
import CxLabel from "@/components/CxLabel.vue";
import CxDeleteButton from "@/components/CxDeleteButton.vue";
import CxCreateButton from "@/components/CxCreateButton.vue";
import {between, helpers, required} from "@vuelidate/validators";
import CxDropdown from "@/components/CxDropdown.vue";

export default defineComponent({
  components: {CxDropdown, CxCreateButton, CxDeleteButton, CxLabel},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      errors: [] as string[],
      subnet: {} as IPPoolSubnetDto,
      ipPool: {} as IPPoolDto
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    if (!this.visible) {
      return {};
    }
    const ipAddress = helpers.withMessage(this.$t('ipPool', "subnetInvalidIp"), (value) => {
      const ipPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^$/
      return ipPattern.test(<string>value)
    })

    return {
      subnet: {
        subnetIp: {required, ipAddress},
        subnetPrefix: {required, between: between(1, 31)},
      },
    };
  },
  methods: {
    save() {
      if(this.visible) {
        if (this.v$.$invalid) {
          this.$cx.notifyValidationError(this.v$);
          return;
        }
        this.$store
            .dispatch("ipPool/createSingleSubnet", {id: this.ipPool.id, entity: this.subnet})
            .then(() => {
              this.$cx.success(this.$t('ipPool', "subnetCreated"), this.$t('ipPool', "subnetCreatedDesc"))
              this.close();
            })
            .catch((error) => this.$cx.error(error, this.$t('ipPool', "subnetCreatedError")));
      }
    },
    close() {
      this.$emit("update:visible", false);
    },
  },
});
