
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            ztpDevices: [] as any[]
        }
    },
    computed: {
        gridItems() {
            return [
                {
                    label: this.$t('log', 'ztp_lb'),
                    description: this.$t('log', 'ztp_desc'),
                    meta: "ztp",
                    icon: "pi pi-sitemap",
                    to: "/logs/ztp",
                }
            ]
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.$store.dispatch("log/getZTPLogStatus").then((data) => {
                this.ztpDevices = data
            })
        },
        goToLog(dev: any) {
            this.$router.push({
                name: "logsZtp", query: {
                    locationDeviceId: dev.locationDeviceId,
                    logId: dev.logId
                }
            })
        },
        getStatusStyle(s: string) {
            let style = "padding: 7px;border-radius: 50%;color: white;background-color:"
            switch (s.toLowerCase()) {
                case "finished":
                    style += "#90cd93"
                    break;
                case "renumbered":
                    style += "#61beb5"
                    break;
                case "upgrading":
                    style += "#af50bf"
                    break;
                case "failed":
                    style += "#ff4032"
                    break;
            }
            return style
        }
    }
});
