
import { ConfigurationDto, LocationDeviceDto } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            configurations: [] as any[],
            selectedLocationDevice: {} as LocationDeviceDto,
            selectedConfiguration: { text: '' } as any,
            newConfigurationText: ''
        };
    },
    created() {
        if ("locationDeviceId" in this.$route.query) {
            this.selectedLocationDevice = { id: parseInt(this.$route.query.locationDeviceId!.toString()) }
            this.onLocationDeviceChanged({
                value: {
                    id: this.selectedLocationDevice.id
                }
            })
        }
    },
    methods: {
        onLocationDeviceChanged(ev: any) {
            if (ev.value == null) {
                this.configurations = [] as any[]
                this.selectedConfiguration = { text: '' }
                return
            }
            this.$store
                .dispatch("locationDevice/configs", ev.value.id)
                .then((configs: ConfigurationDto[]) => {
                    this.selectedConfiguration = { text: '' }
                    this.configurations.length = 0;
                    this.configurations = configs;
                    this.configurations.forEach((c) => {
                        c.version = "V" + c.version;
                    });

                    if ("configId" in this.$route.query) {
                        let configId = parseInt(this.$route.query.configId!.toString())
                        this.selectedConfiguration = this.configurations.find(c => c.id == configId)
                        this.$cx.goTo('configurationWizard')
                    }
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },
        onConfigurationSelected(ev: any) {
            this.selectedConfiguration = ev.value
        },
        onCopySelection() {
            let text = (this.$refs as any)["configText"].getSelectedText();
            if (this.$cx.isNullOrEmpty(text)) return
            (this.$refs as any)["newConfigText"].pasteText(text)
        },
        undo() {
            (this.$refs as any)["newConfigText"].undo()
        },
        openConfig() {
            this.$cx.goToNewTabWithArgs('configurationEditorNew', {
                text: btoa(JSON.stringify({
                    text: this.newConfigurationText
                }))
            })
        }
    },
});
