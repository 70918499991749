
import { CxTableConfig, CxTableColumn, CxDataType } from "@/types/cx-table-config";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        id: {
            type: Number
        }
    },
    data() {
        return {
            areas: [] as any[],
            areaOptions: [{ name: 'LocationDevice', value: 'LOC_DEV' }, { name: 'ZTP', value: 'LOC_DEV_ZTP' }, { name: 'Catalyst Center', value: 'LOC_DEV_CATALYST' }],
            levels: [] as any[],
            levelOptions: [{ name: 'Info', value: 'INFO' }, { name: 'Warn', value: 'WARN' }, { name: 'Error', value: 'ERROR' }],
            logs: {} as any
        };
    },
    computed: {
        tableConfig() {
            return new CxTableConfig([
                new CxTableColumn("time", "Time", CxDataType.DateTime),
                new CxTableColumn("level", "Level", CxDataType.Text, "width:160px"),
                new CxTableColumn("area", "Area", CxDataType.Text, "width:160px"),
                new CxTableColumn("message", "Message")
            ]);
        },
    },
    created() {

    },
    methods: {
        load(filters: any) {
            if (this.areas.length > 0)
                filters.filters["d_areas"] = this.areas.map(a => a.value).join(',')
            else
                delete filters.filters["d_areas"]
            if (this.levels.length > 0)
                filters.filters["d_levels"] = this.levels.map(a => a.value).join(',')
            else
                delete filters.filters["d_levels"]

            filters.id = this.id
            this.$store
                .dispatch("log/getLogsWithFilter", filters)
                .then((logs) => {
                    this.logs = logs
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },
        onClearFilters() {
            (<any>this.$refs)["tbl"].clearFilters();
        },
        onFilterChanged() {
            (<any>this.$refs)["tbl"].refreshFilters()
        },
        onRemoveFilter(arr: any[], value: any) {
            this.$cx.removeFrom(arr as any[], value, "value");
        }
    },
});
