import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-form-h-30" }
const _hoisted_2 = { class: "cx-summary" }
const _hoisted_3 = { class: "cx-summary" }
const _hoisted_4 = { class: "flex align-items-center" }
const _hoisted_5 = { class: "ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_save_button = _resolveComponent("cx-save-button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: _ctx.$store.state.appContext.settingsLoading,
    title: _ctx.$t('management', 'settings')
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_save_button, {
        isEditing: true,
        onSave: _ctx.save,
        onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('Management'))),
        disableSaveAndBack: true,
        grant: {
                    perm: _ctx.$perm.APP_CONTEXT,
                    lvl: _ctx.$lvl.WRITE
                }
      }, null, 8, ["onSave", "grant"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('appcontext', 'lb')), 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('appcontext', 'lb_desc')), 1),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('appcontext', 'ztpUrl')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$t('appcontext', 'ztpUrl_ph'),
              modelValue: _ctx.entity.props!.ztpUrl,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.props!.ztpUrl) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('appcontext', 'osUrl')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$t('appcontext', 'osUrl_ph'),
              modelValue: _ctx.entity.props!.osUrl,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.props!.osUrl) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('appcontext', 'apiUrl')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$t('appcontext', 'apiUrl_ph'),
              modelValue: _ctx.entity.props!.apiUrl,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.props!.apiUrl) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('management', 'approval')), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('management', 'approval_desc')), 1),
        (_ctx.entity.props?.approvalEntities)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.approvalKeys, (key, e) => {
              return (_openBlock(), _createElementBlock("div", {
                key: e,
                class: "mb-2"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_InputSwitch, {
                    modelValue: _ctx.getApprovalValue(key),
                    "onUpdate:modelValue": ($event: any) => (_ctx.onChangeApprovalValue($event, key)),
                    disabled: !_ctx.$auth.permits(_ctx.$perm.APP_CONTEXT, _ctx.$lvl.WRITE)
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
                  _createElementVNode("label", _hoisted_5, _toDisplayString(key), 1)
                ])
              ]))
            }), 128))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["loading", "title"]))
}