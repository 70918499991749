import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    icon: _ctx.classes,
    label: _ctx.$c('create'),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo(_ctx.route))),
    disabled: !_ctx.$auth.permits(_ctx.grant.perm, _ctx.grant.lvl)
  }, null, 8, ["icon", "label", "disabled"]))
}