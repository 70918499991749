import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_cx_label, {
      label: _ctx.$t('deviceRole', 'lb'),
      v$: _ctx.v$.entity.deviceRole
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_dropdown, {
          modelValue: _ctx.entity.deviceRole,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.deviceRole) = $event)),
          store: "deviceRole",
          optionLabel: "name",
          placeholder: _ctx.$t('deviceRole', 'ph_pl'),
          dropdownOptions: {
          getId: (en) => en?.id,
          route: 'deviceRoleEditor',
          editable: true
        }
        }, null, 8, ["modelValue", "placeholder", "dropdownOptions"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$c('minCount'),
      v$: _ctx.v$.entity.minCount
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputNumber, {
          placeholder: _ctx.$c('minCount_ph'),
          modelValue: _ctx.entity.minCount,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.minCount) = $event)),
          mode: "decimal",
          minFractionDigits: 0,
          showButtons: ""
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$c('maxCount'),
      v$: _ctx.v$.entity.maxCount
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputNumber, {
          placeholder: _ctx.$c('maxCount_ph'),
          modelValue: _ctx.entity.maxCount,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.maxCount) = $event)),
          mode: "decimal",
          minFractionDigits: 0,
          max: 100,
          showButtons: ""
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label", "v$"])
  ]))
}