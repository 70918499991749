import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createBlock(_component_Calendar, {
    modelValue: _ctx.localValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
    showTime: true,
    placeholder: _ctx.$c('moment'),
    disabled: _ctx.disabled
  }, null, 8, ["modelValue", "placeholder", "disabled"]))
}