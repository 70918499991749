import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_cx_json_editor = _resolveComponent("cx-json-editor")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_cx_label, {
      label: _ctx.$c('name'),
      v$: _ctx.v$.entity.name
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          placeholder: _ctx.$c('name_ph'),
          modelValue: _ctx.entity.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.name) = $event))
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('virtualNetwork', 'vlanId')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputNumber, {
          placeholder: _ctx.$t('virtualNetwork', 'vlanId_ph'),
          modelValue: _ctx.entity.vlanId,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.vlanId) = $event)),
          showButtons: ""
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('virtualNetwork', 'hsrpGroupNumber')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          placeholder: _ctx.$t('virtualNetwork', 'hsrpGroupNumber_ph'),
          modelValue: _ctx.entity.hsrpGroup,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.hsrpGroup) = $event))
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('virtualNetwork', 'configurationText')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Textarea, {
          placeholder: _ctx.$t('virtualNetwork', 'configurationText_ph'),
          rows: "7",
          autoResize: false,
          modelValue: _ctx.entity.configurationText,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.configurationText) = $event))
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$c('meta')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_json_editor, {
          modelValue: _ctx.entity.meta,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.entity.meta) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}