
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        source: {
            type: Object,
            default: {}
        },
        target: {
            type: Object,
            default: {}
        },
        isSource: Boolean,
        errors: Array,
        matches: Array,
        skipChildren: Boolean,
        default: [] as any[]
    },
    data() {
        return {
            entity: {} as any,
            initialState: {} as any
        };
    },
    methods: {
        getErrs(child: any) {
            return this.errors?.filter((e: any) => e.text == child.text) || []

        },
        getClass(child: any) {
            var err = this.errors?.filter((e: any) => e.text == child.text) || []
            if (err.length > 0) return 'cx-compliance-line-errors'
        }
    }
});
