import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-form-h-30" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_save_button = _resolveComponent("cx-save-button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_cx_json_editor = _resolveComponent("cx-json-editor")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: _ctx.$store.state.logicalInterface.loading,
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'logicalInterface')
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_save_button, {
        isEditing: _ctx.isEditing,
        onSave: _ctx.save,
        onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('logicalInterface')))
      }, null, 8, ["isEditing", "onSave"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('name'),
          v$: _ctx.v$.entity.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('name_ph'),
              modelValue: _ctx.entity.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.name) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$c('description'),
          v$: _ctx.v$.entity.description
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Textarea, {
              placeholder: _ctx.$c('description_ph'),
              modelValue: _ctx.entity.description,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.description) = $event)),
              rows: "5",
              cols: "30"
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$c('weight'),
          v$: _ctx.v$.entity.weight
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputNumber, {
              placeholder: _ctx.$c('weight_ph'),
              modelValue: _ctx.entity.weight,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.weight) = $event)),
              showButtons: true,
              maxFractionDigits: 0
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('locationType', 'lb')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cx_dropdown, {
              modelValue: _ctx.entity.locationType,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.entity.locationType) = $event)),
              store: "locationType",
              optionLabel: "name",
              placeholder: _ctx.$t('locationType', 'ph')
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('ipPool', 'lb')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cx_dropdown, {
              modelValue: _ctx.entity.ipPool,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.entity.ipPool) = $event)),
              store: "ipPool",
              optionLabel: "name",
              placeholder: _ctx.$t('ipPool', 'ph')
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$c('meta')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cx_json_editor, {
              modelValue: _ctx.entity.meta,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.entity.meta) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"])
      ])
    ]),
    _: 1
  }, 8, ["loading", "title"]))
}