
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";
import { CxExportType } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      deviceTemplates: []
    };
  },
  computed: {
    tableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("description", this.$c("description")),
        new CxTableColumn(
          "deviceModels",
          this.$t("deviceModel", "lb"),
          CxDataType.GetNameFromArray
        ),
        new CxTableColumn(
          "deviceRoles",
          this.$t("deviceRole", "lb_pl"),
          CxDataType.GetNameFromArray
        ),
        new CxTableColumn(
          "os",
          this.$t("os", "lb"),
          CxDataType.GetName
        ),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
    importTableConfig(): CxTableConfig {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("deviceFamily", "", CxDataType.Custom),
      ])
    },
  },
  methods: {
    load(filters: any) {
      this.$store
        .dispatch("deviceTemplate/getWithFilter", filters)
        .then((deviceTemplates) => {
          deviceTemplates.content = deviceTemplates.content
            .map((d: any) => ({
              ...d,
              ...{ deviceModels: d.deviceTemplateModels.map((f: any) => f.deviceModel) }
            }))

          this.deviceTemplates = deviceTemplates
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onExport() {
      this.$store.dispatch("deviceTemplate/export", CxExportType.CSV);
    },
    onDelete(data: any) {
      (<any>this.$refs)["confirmDelete"].onDelete(data.id, () => {
        this.$cx.notifyDeleted(this.$t("deviceTemplate", "lb"));
        (<any>this.$refs)['tbl'].reload()
      })
    },
    onClearFilters() {
      (<any>this.$refs)['tbl'].clearFilters()
    }
  },
});
