
import { CxTableConfig, CxTableColumn, CxDataType } from "@/types/cx-table-config";
import { defineComponent } from "vue";
import { CxImportResult, LocationDto } from "../types/dto";

export default defineComponent({
  props: {
    initials: {
      type: String,
      default: "CX",
    },
    dialogHeader: {
      type: String,
      default: "CSV Import",
    },
    acceptedFileType: {
      type: String,
      default: ".csv",
    },
    store: {
      type: String,
      default: "",
    },
    importTableConfig: {
      type: CxTableConfig
    }
  },

  computed: {
    errorTableConfig(): CxTableConfig {
      return new CxTableConfig([
        new CxTableColumn("line", this.$c("line")),
        new CxTableColumn("errors", "", CxDataType.Custom),
        new CxTableColumn("successful", this.$c("successful"))])
    }
  },

  data() {
    return {
      files: [] as any,
      display: false,
      delimiter: "",
      showTable: false,
      previewData: [] as any,
      importData: [] as CxImportResult<any>[],
      importErrors: [] as CxImportResult<any>[],
      test: [] as LocationDto[],
      canSave: false
    }
  },
  methods: {
    onFileChanged($ev: any) {
      if ((<any>this.$refs["file"]).files.length > 0) {
        this.files = (<any>this.$refs["file"]).files;
      }
    },

    removeFile(file: any) {
      var index = this.files.indexOf(file);
      this.showTable = false;
      this.canSave = false;
      this.importData = [];
      this.importErrors = [];
      this.files.splice(index, 1);
    },

    formatSize(bytes: any) {
      if (bytes === 0) {
        return "0 B";
      }
      let k = 1000,
        dm = 3,
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    importFile(file: any, save: boolean) {
      let f = this.files.find((x: any) => x !== undefined);
      let formData = new FormData();
      formData.append("file", f);

      if (formData) {
        let importPayload = {
          file: formData,
          save,
          delimiter: this.delimiter,
        }
        this.$store.dispatch(this.store + "/importData", importPayload)
          .then((data: CxImportResult<any>[]) => {
            this.importData = data;
            this.importErrors = data.filter(d => d.successful != true);
            if (this.importErrors.length <= 0) {
              this.canSave = true;
            }
          })
      }
      this.showTable = true;
    }
  }
})


