import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createBlock(_component_TabView, {
    activeIndex: _ctx.index,
    "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.index) = $event)),
    onTabChange: _ctx.onTabChanged
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, i) => {
        return (_openBlock(), _createBlock(_component_TabPanel, {
          key: i,
          header: header.title,
          disabled: header.disabled || false
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, header.key)
          ]),
          _: 2
        }, 1032, ["header", "disabled"]))
      }), 128))
    ]),
    _: 3
  }, 8, ["activeIndex", "onTabChange"]))
}