

/** 

This script was used to generate this tree:

var arr = []
var blacklist = ['createdAt', 'createdBy', 'changedAt', 'changedBy', 'locked', 'deleted', 'id']
var id = 0

function getValues(root, value) {
    Object.keys(value).forEach(key => {
        if (!blacklist.includes(key)) {
            let label = key
            if (Array.isArray(value[key])) {
                label += '[]'
            }

            let node = {
                key: ++id,
                label: label,
                children: []
            }

            if (Array.isArray(value[key]) && value[key].length > 0) {
                getValues(node.children, value[key][0])
            }

            if (typeof value[key] === 'object' &&
                !Array.isArray(value[key]) &&
                value[key] !== null) {
                getValues(node.children, value[key])
            }
            root.push(node)
        }
    })
}

getValues(arr, t)

console.log(arr)

*/

const configScopeParameters = [
    {
        "key": 1,
        "label": "hostname",
        "children": []
    },
    {
        "key": 2,
        "label": "managementIp",
        "children": []
    },
    {
        "key": 1231231232,
        "label": "meta",
        "children": []
    },
    {
        "key": 7609234,
        "label": "stack",
        "children": [
            {
                "key": 1,
                "label": "memberId",
                "children": []
            },
            {
                "key": 2,
                "label": "priority",
                "children": []
            },
            {
                "key": 3,
                "label": "serialNumber",
                "children": []
            },
        ]
    },
    {
        "key": 3,
        "label": "ports[]",
        "children": [
            {
                "key": 4,
                "label": "name",
                "children": []
            },
            {
                "key": 6,
                "label": "type",
                "children": []
            },
            {
                "key": 623,
                "label": "portDesc",
                "children": []
            },
            {
                "key": 723,
                "label": "templDesc",
                "children": []
            },
            {
                "key": 611,
                "label": "ip",
                "children": []
            },
            {
                "key": 622,
                "label": "subnet",
                "children": []
            },
            {
                "key": 624,
                "label": "meta {}",
                "children": []
            },
            {
                "key": 10,
                "label": "vrf",
                "children": [
                    {
                        "key": 11,
                        "label": "name",
                        "children": []
                    },
                    {
                        "key": 12,
                        "label": "asn",
                        "children": []
                    },
                    {
                        "key": 13,
                        "label": "vpnNumber",
                        "children": []
                    },
                    {
                        "key": 14,
                        "label": "description",
                        "children": []
                    },
                    {
                        "key": 15,
                        "label": "ip",
                        "children": []
                    },
                    {
                        "key": 13,
                        "label": "subnet",
                        "children": []
                    },
                    {
                        "key": 16,
                        "label": "meta {}",
                        "children": []
                    }
                ]
            }, {
                "key": 17,
                "label": "vlan",
                "children": [
                    {
                        "key": 18,
                        "label": "name",
                        "children": []
                    },
                    {
                        "key": 19,
                        "label": "hsrpGroup",
                        "children": []
                    },
                    {
                        "key": 20,
                        "label": "configurationText",
                        "children": []
                    },
                    {
                        "key": 21,
                        "label": "ip",
                        "children": []
                    },
                    {
                        "key": 22,
                        "label": "subnet",
                        "children": []
                    },
                    {
                        "key": 23,
                        "label": "meta {}",
                        "children": []
                    }
                ]
            }
        ]
    },
    {
        "key": 7,
        "label": "logicalPorts[]",
        "children": [
            {
                "key": 8,
                "label": "name",
                "children": []
            },
            {
                "key": 9,
                "label": "description",
                "children": []
            }
        ]
    },
    {
        "key": 10,
        "label": "vrfs[]",
        "children": [
            {
                "key": 11,
                "label": "name",
                "children": []
            },
            {
                "key": 12,
                "label": "asn",
                "children": []
            },
            {
                "key": 13,
                "label": "vpnNumber",
                "children": []
            },
            {
                "key": 14,
                "label": "description",
                "children": []
            },
            {
                "key": 15,
                "label": "ip",
                "children": []
            },
            {
                "key": 13,
                "label": "subnet",
                "children": []
            },
            {
                "key": 16,
                "label": "meta {}",
                "children": []
            }
        ]
    }, {
        "key": 17,
        "label": "vlans[]",
        "children": [
            {
                "key": 18,
                "label": "name",
                "children": []
            },
            {
                "key": 19,
                "label": "hsrpGroup",
                "children": []
            },
            {
                "key": 20,
                "label": "configurationText",
                "children": []
            },
            {
                "key": 21,
                "label": "ip",
                "children": []
            },
            {
                "key": 22,
                "label": "subnet",
                "children": []
            },
            {
                "key": 23,
                "label": "meta {}",
                "children": []
            }
        ]
    }
] as any[]

const codeTemplateScopeParameters = [
    {
        "key": 1,
        "label": "hostname",
        "children": []
    },
    {
        "key": 2,
        "label": "managementIp",
        "children": []
    },
    {
        "key": 2487,
        "label": "workerToken",
        "children": []
    },
    {
        "key": 20,
        "label": "apiUrl",
        "children": []
    },
    {
        "key": 22,
        "label": "codeTemplateName",
        "children": []
    },
    {
        "key": 21,
        "label": "deviceId",
        "children": []
    },
    {
        "key": 3,
        "label": "config",
        "children": [{
            "key": 9992,
            "label": "text",
            "children": []
        },
        {
            "key": 999,
            "label": "lines[]",
            "children": []
        },
        {
            "key": 1000,
            "label": "parts[]",
            "children": [
                {
                    "key": 1100,
                    "label": "id",
                    "children": []
                },
                {
                    "key": 1200,
                    "label": "name",
                    "children": []
                },
                {
                    "key": 1201,
                    "label": "weight",
                    "children": []
                },
                {
                    "key": 1202,
                    "label": "text",
                    "children": []
                },
                {
                    "key": 1203,
                    "label": "lines[]",
                    "children": []
                }
            ]
        }]
    },
    {
        "key": 4,
        "label": "profile",
        "children": [{
            "key": 9,
            "label": "name",
            "children": []
        },
        {
            "key": 10,
            "label": "loginType",
            "children": []
        },
        {
            "key": 11,
            "label": "username",
            "children": []
        },
        {
            "key": 12,
            "label": "password",
            "children": []
        }]
    },
    {
        "key": 5,
        "label": "osUpdate",
        "children": [
            {
                "key": 5,
                "label": "filename",
                "children": []
            },
            {
                "key": 6,
                "label": "checksum",
                "children": []
            },
            {
                "key": 7,
                "label": "url",
                "children": []
            },
            {
                "key": 8,
                "label": "osId",
                "children": []
            }
        ]
    },
] as any[]

export default configScopeParameters
export { codeTemplateScopeParameters }