const ipv4NetworkMaskOptions = [
    { name: "255.0.0.0/8", value: 8 },
    { name: "255.128.0.0/9", value: 9 },
    { name: "255.192.0.0/10", value: 10 },
    { name: "255.224.0.0/11", value: 11 },
    { name: "255.240.0.0/12", value: 12 },
    { name: "255.248.0.0/13", value: 13 },
    { name: "255.252.0.0/14", value: 14 },
    { name: "255.254.0.0/15", value: 15 },
    { name: "255.255.0.0/16", value: 16 },
    { name: "255.255.128.0/17", value: 17 },
    { name: "255.255.192.0/18", value: 18 },
    { name: "255.255.224.0/19", value: 19 },
    { name: "255.255.240.0/20", value: 20 },
    { name: "255.255.248.0/21", value: 21 },
    { name: "255.255.252.0/22", value: 22 },
    { name: "255.255.254.0/23", value: 23 },
    { name: "255.255.255.0/24", value: 24 },
    { name: "255.255.255.128/25", value: 25 },
    { name: "255.255.255.192/26", value: 26 },
    { name: "255.255.255.224/27", value: 27 },
    { name: "255.255.255.240/28", value: 28 },
    { name: "255.255.255.248/29", value: 29 },
    { name: "255.255.255.252/30", value: 30 },
    { name: "255.255.255.254/31", value: 31 },
    { name: "255.255.255.255/32", value: 32 }
]

const ipv6NetworkMaskOptions = [
    { name: "/8 (1 subnet)", value: 8 },
    { name: "/9 (2 subnets)", value: 9 },
    { name: "/10 (4 subnets)", value: 10 },
    { name: "/11 (8 subnets)", value: 11 },
    { name: "/12 (16 subnets)", value: 12 },
    { name: "/13 (32 subnets)", value: 13 },
    { name: "/14 (64 subnets)", value: 14 },
    { name: "/15 (128 subnets)", value: 15 },
    { name: "/16 (256 subnets)", value: 16 },
    { name: "/17 (512 subnets)", value: 17 },
    { name: "/18 (1024 subnets)", value: 18 },
    { name: "/19 (2048 subnets)", value: 19 },
    { name: "/20 (4096 subnets)", value: 20 },
    { name: "/21 (8192 subnets)", value: 21 },
    { name: "/22 (16384 subnets)", value: 22 },
    { name: "/23 (32768 subnets)", value: 23 },
    { name: "/24 (65536 subnets)", value: 24 },
    { name: "/25 (131072 subnets)", value: 25 },
    { name: "/26 (262144 subnets)", value: 26 },
    { name: "/27 (524288 subnets)", value: 27 },
    { name: "/28 (1048576 subnets)", value: 28 },
    { name: "/29 (2097152 subnets)", value: 29 },
    { name: "/30 (4194304 subnets)", value: 30 },
    { name: "/31 (8388608 subnets)", value: 31 },
    { name: "/32 (16777216 subnets)", value: 32 },
    { name: "/33 (33554432 subnets)", value: 33 },
    { name: "/34 (67108864 subnets)", value: 34 },
    { name: "/35 (134217728 subnets)", value: 35 },
    { name: "/36 (268435456 subnets)", value: 36 },
    { name: "/37 (536870912 subnets)", value: 37 },
    { name: "/38 (1073741824 subnets)", value: 38 },
    { name: "/39 (2147483648 subnets)", value: 39 },
    { name: "/40 (4294967296 subnets)", value: 40 },
    { name: "/41 (8589934592 subnets)", value: 41 },
    { name: "/42 (17179869184 subnets)", value: 42 },
    { name: "/43 (34359738368 subnets)", value: 43 },
    { name: "/44 (68719476736 subnets)", value: 44 },
    { name: "/45 (137438953472 subnets)", value: 45 },
    { name: "/46 (274877906944 subnets)", value: 46 },
    { name: "/47 (549755813888 subnets)", value: 47 },
    { name: "/48 (1099511627776 subnets)", value: 48 },
    { name: "/49 (2199023255552 subnets)", value: 49 },
    { name: "/50 (4398046511104 subnets)", value: 50 },
    { name: "/51 (8796093022208 subnets)", value: 51 },
    { name: "/52 (17592186044416 subnets)", value: 52 },
    { name: "/53 (35184372088832 subnets)", value: 53 },
    { name: "/54 (70368744177664 subnets)", value: 54 },
    { name: "/55 (140737488355328 subnets)", value: 55 },
    { name: "/56 (281474976710656 subnets)", value: 56 },
    { name: "/57 (562949953421312 subnets)", value: 57 },
    { name: "/58 (1125899906842624 subnets)", value: 58 },
    { name: "/59 (2251799813685248 subnets)", value: 59 },
    { name: "/60 (4503599627370496 subnets)", value: 60 },
    { name: "/61 (9007199254740992 subnets)", value: 61 },
    { name: "/62 (18014398509481984 subnets)", value: 62 },
    { name: "/63 (36028797018963970 subnets)", value: 63 },
    { name: "/64 (72057594037927940 subnets)", value: 64 }
]

export { ipv4NetworkMaskOptions, ipv6NetworkMaskOptions }