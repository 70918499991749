
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        modelValue: [Date, String, Object],
        disabled: Boolean
    },
    data() {
        return {
            localValue: null as any
        };
    },
    mounted() {
        if (this.modelValue == null)
            this.localValue = null;
        else
            this.localValue = new Date(this.modelValue!.toString())
    },
    watch: {
        modelValue: function (newValue) {
            if (newValue == null)
                this.localValue = null;
            else
                this.localValue = new Date(newValue.toString())
        },
        localValue: function (newValue) {
            if (newValue == null)
                this.$emit('update:modelValue', null);
            else
                this.$emit('update:modelValue', newValue.toISOString().replace('Z', '+00:00'));
        },
    },
});
