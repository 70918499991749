import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "editor",
    style: _normalizeStyle('height:' + _ctx.height + ';opacity:' + (_ctx.disabled ? '0.8' : '')),
    id: _ctx.uid
  }, null, 12, _hoisted_1))
}