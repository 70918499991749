import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "cx-data-type-cell" }
const _hoisted_2 = {
  key: 0,
  class: "pi pi-check",
  style: {"color":"#22c55e"}
}
const _hoisted_3 = {
  key: 1,
  class: "pi pi-times",
  style: {"color":"#ff3d32"}
}
const _hoisted_4 = { class: "cx-chips" }
const _hoisted_5 = { class: "p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_cx_audit = _resolveComponent("cx-audit")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    onPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('page', $event))),
    rows: 15,
    stripedRows: "",
    value: _ctx.data,
    showGridlines: "",
    paginator: true,
    scrollable: true,
    loading: _ctx.loading,
    lazy: _ctx.config!.lazy,
    resizableColumns: true,
    class: _normalizeClass({ 'cx-table-large': _ctx.isLarge }),
    totalRecords: _ctx.config!.totalRecords,
    filters: _ctx.config!.filters,
    "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.config!.filters) = $event)),
    globalFilterFields: _ctx.config!.filterFields,
    selection: _ctx.selection,
    "onUpdate:selection": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selection) = $event)),
    rowsPerPageOptions: [5, 15, 25, 50, 100, 200]
  }, {
    empty: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$c('noData')), 1)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config!.columns, (col, c) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: c }, [
          (col.type == _ctx.CxDataType.Text)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 0,
                sortable: _ctx.sortable,
                field: col.field,
                header: col.label,
                headerStyle: "width:auto",
                columnKey: col.field
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(slotProps.data[col.field]), 1)
                ]),
                _: 2
              }, 1032, ["sortable", "field", "header", "columnKey"]))
            : _createCommentVNode("", true),
          (col.type == _ctx.CxDataType.Date)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 1,
                dataType: "date",
                sortable: _ctx.sortable,
                field: col.field,
                filterField: "Date",
                header: col.label,
                columnKey: col.field
              }, {
                body: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(_ctx.$cx.toDate(slotProps.data[col.field])), 1)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" > ")
                ]),
                _: 2
              }, 1032, ["sortable", "field", "header", "columnKey"]))
            : _createCommentVNode("", true),
          (col.type == _ctx.CxDataType.DateTime)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 2,
                dataType: "date",
                sortable: _ctx.sortable,
                field: col.field,
                filterField: "Date",
                header: col.label,
                columnKey: col.field,
                headerStyle: "width:130px"
              }, {
                body: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(_ctx.$cx.toTime(slotProps.data[col.field])), 1)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" > ")
                ]),
                _: 2
              }, 1032, ["sortable", "field", "header", "columnKey"]))
            : _createCommentVNode("", true),
          (col.type == _ctx.CxDataType.Checkbox)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 3,
                sortable: _ctx.sortable,
                field: col.field,
                header: col.label,
                headerStyle: "width:300px",
                bodyStyle: "text-align:center !important",
                columnKey: col.field
              }, {
                body: _withCtx((slotProps) => [
                  (slotProps.data[col.field])
                    ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                    : (_openBlock(), _createElementBlock("i", _hoisted_3))
                ]),
                _: 2
              }, 1032, ["sortable", "field", "header", "columnKey"]))
            : _createCommentVNode("", true),
          (col.type == _ctx.CxDataType.Audit)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 4,
                field: col.field,
                header: col.label,
                headerStyle: "width:200px",
                columnKey: col.field,
                showFilterMenu: false
              }, {
                body: _withCtx((slotProps) => [
                  _createVNode(_component_cx_audit, {
                    entity: slotProps.data
                  }, null, 8, ["entity"])
                ]),
                _: 2
              }, 1032, ["field", "header", "columnKey"]))
            : _createCommentVNode("", true),
          (col.type == _ctx.CxDataType.GetType)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 5,
                sortable: _ctx.sortable,
                field: col.field,
                header: col.label,
                headerStyle: "width:300px",
                columnKey: col.field
              }, {
                body: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(slotProps.data[col.field].type), 1)
                ]),
                _: 2
              }, 1032, ["sortable", "field", "header", "columnKey"]))
            : _createCommentVNode("", true),
          (col.type == _ctx.CxDataType.GetName)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 6,
                sortable: _ctx.sortable,
                field: col.field,
                header: col.label,
                headerStyle: "width:300px",
                sortField: col.field + '.name',
                columnKey: col.field
              }, {
                body: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(slotProps.data[col.field]?.name), 1)
                ]),
                _: 2
              }, 1032, ["sortable", "field", "header", "sortField", "columnKey"]))
            : _createCommentVNode("", true),
          (col.type == _ctx.CxDataType.GetNameFromArray)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 7,
                sortable: _ctx.sortable,
                field: col.field,
                header: col.label,
                headerStyle: "width:300px",
                columnKey: col.field
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data[col.field] || [], (item, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "cx-chip",
                        key: i
                      }, _toDisplayString(item.name), 1))
                    }), 128))
                  ])
                ]),
                _: 2
              }, 1032, ["sortable", "field", "header", "columnKey"]))
            : _createCommentVNode("", true),
          (col.type == _ctx.CxDataType.Custom)
            ? _renderSlot(_ctx.$slots, col.field, {
                key: 8,
                columnKey: col.field
              })
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      _renderSlot(_ctx.$slots, "columns")
    ]),
    _: 3
  }, 8, ["value", "loading", "lazy", "class", "totalRecords", "filters", "globalFilterFields", "selection"]))
}