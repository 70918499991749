import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "grid",
  style: {"height":"calc(100vh - 70px)"}
}
const _hoisted_2 = { class: "col-4 flex flex-column" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "cx-ztp-leaf"
}
const _hoisted_5 = { class: "mt-2 flex justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tree = _resolveComponent("Tree")!
  const _component_Button = _resolveComponent("Button")!
  const _component_cx_ztp_log_viewer = _resolveComponent("cx-ztp-log-viewer")!
  const _component_cx_page = _resolveComponent("cx-page")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    title: _ctx.$t('log', 'management')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Tree, {
            class: "flex-1 cx-ztp-loc-tree",
            filter: true,
            filterPlaceholder: _ctx.$cx.tF('common', 'search', [_ctx.$t('locationDevice', 'lb')]),
            value: _ctx.locationTree,
            selectionMode: "single",
            filterMode: "lenient",
            expandedKeys: _ctx.expandedKeys,
            scrollHeight: "calc(100vh - 195px)",
            selectionKeys: _ctx.selectedDevKey,
            "onUpdate:selectionKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDevKey) = $event)),
            onNodeSelect: _ctx.onLocationDeviceChanged
          }, {
            default: _withCtx((slotProps) => [
              (!slotProps.node.isDevice)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(slotProps.node.label), 1))
                : _createCommentVNode("", true),
              (slotProps.node.isDevice)
                ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("span", null, _toDisplayString(slotProps.node.label), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$cx.toTime(slotProps.node.ztpContactTime)), 1)
                  ])), [
                    [
                      _directive_tooltip,
                      'SN: ' + (slotProps.node.serials || '-') + '\nIMG: ' + (slotProps.node.filename || '-'),
                      void 0,
                      { bottom: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["filterPlaceholder", "value", "expandedKeys", "selectionKeys", "onNodeSelect"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-plus",
              label: _ctx.$c('expand'),
              class: "flex-1",
              onClick: _ctx.expandAll
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-minus",
              label: _ctx.$c('collapse'),
              class: "flex-1 ml-1",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expandedKeys = {}))
            }, null, 8, ["label"])
          ])
        ]),
        _createVNode(_component_cx_ztp_log_viewer, {
          ref: "viewer",
          queryLocDevId: _ctx.queryLocDevId,
          queryLogId: _ctx.queryLogId,
          onLogChanged: _ctx.onLogChanged
        }, null, 8, ["queryLocDevId", "queryLogId", "onLogChanged"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}