import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_cx_label, {
      label: _ctx.$t('deviceModel', 'lb'),
      v$: _ctx.v$.entity.deviceModel
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_dropdown, {
          modelValue: _ctx.entity.deviceModel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.deviceModel) = $event)),
          store: "deviceModel",
          optionLabel: "name",
          disabled: _ctx.isEditing,
          placeholder: _ctx.$t('deviceModel', 'ph'),
          dropdownOptions: {
                    route: 'deviceModelEditor',
                    getId: (en) => en?.id,
                    editable: true
                }
        }, null, 8, ["modelValue", "disabled", "placeholder", "dropdownOptions"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('deviceTemplateModel', 'stackPriority')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputNumber, {
          modelValue: _ctx.entity.stackPriority,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.stackPriority) = $event)),
          "show-buttons": true,
          format: false,
          min: 0,
          max: 999999
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}