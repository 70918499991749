import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_3 = { class: "cx-summary mt-1" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "cx-summary mt-3" }
const _hoisted_6 = {
  key: 0,
  class: "cx-summary-warn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: false,
    title: _ctx.$t('comparison', 'lb')
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('comparison', 'importTitle'),
        class: "align-self-end",
        icon: "pi pi-upload",
        onClick: _ctx.onShowBulkImportDialog
      }, null, 8, ["label", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        visible: _ctx.isBulkImportVisible,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isBulkImportVisible) = $event)),
        modal: "",
        header: _ctx.$t('comparison', 'importTitle'),
        style: { width: '42vw' }
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_FileUpload, {
              multiple: false,
              chooseLabel: _ctx.$t('comparison', 'importPickFile'),
              ref: "files",
              mode: "basic",
              accept: ".zip",
              auto: false
            }, null, 8, ["chooseLabel"]),
            _createVNode(_component_Button, {
              class: "p-button-secondary ml-1",
              icon: "pi pi-times",
              onClick: _ctx.onClearBulkImportFile
            }, null, 8, ["onClick"]),
            _createVNode(_component_Button, {
              class: "align-self-end p-button-secondary ml-1",
              label: _ctx.$c('upload'),
              icon: "pi pi-upload",
              onClick: _ctx.onUploadBulkImportFile
            }, null, 8, ["label", "onClick"])
          ]),
          (_ctx.$store.state.comparison.bulkImportLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_ProgressBar, {
                  value: _ctx.$store.state.comparison.bulkImportProgress
                }, null, 8, ["value"]),
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('comparison', 'importProgress')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.bulkImportFinished)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$cx.tF('comparison', 'importResultCount', [_ctx.bulkImportResult.count])), 1),
                (!_ctx.$cx.isNullOrEmpty(_ctx.bulkImportResult.error))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.bulkImportResult.error), 1))
                  : _createCommentVNode("", true),
                _withDirectives(_createElementVNode("p", null, _toDisplayString(_ctx.$t('comparison', 'importHostnames')), 513), [
                  [_vShow, _ctx.bulkImportResult.count > 0]
                ]),
                _withDirectives(_createVNode(_component_Listbox, {
                  options: _ctx.bulkImportResult.names,
                  listStyle: "height:250px"
                }, null, 8, ["options"]), [
                  [_vShow, _ctx.bulkImportResult.count > 0]
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["visible", "header"])
    ]),
    _: 1
  }, 8, ["title"]))
}