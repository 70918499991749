
import { CxDropdownOptions, CxDropdownOptionsApi } from "@/types/cx-dropdown-options";
import { placeholder } from "@babel/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
    },
    options: {
      type: Array,
      default: [],
    },
    placeholder: {
      type: String,
    },
    store: {
      type: String,
    },
    dropdownOptions: {
      type: Object as PropType<CxDropdownOptionsApi>,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loadFn: {
      type: Function,
      default: undefined
    }
  },
  data() {
    return {
      id: this.$cx.createUid(),
      selectableOptions: [],
      isLoading: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.isLoading = true
      if (this.loadFn != null) {
        this.loadFn().then((options: any) => {
          this.selectableOptions = options;
        }).finally(() => {
          this.isLoading = false
        })
      } else {
        this.$store.dispatch(this.store + "/getAll").then((options) => {
          this.selectableOptions = options;
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
    onRemove(value: any) {
      this.$cx.removeFrom(this.selectedValue as any[], value, "id");
    },
    onDropdownOptions($event: any) {
      this.$store.state.ui.dropdownOptions = {
        reloadFn: () => {
          this.selectedValue = undefined
          this.load()
        },
        value: this.selectedValue,
        options: this.dropdownOptions,
        event: $event
      } as CxDropdownOptions
    }
  },
  computed: {
    selectedValue: {
      get() {
        return this.modelValue;
      },
      set(v: any) {
        this.$emit("update:modelValue", v);
        this.$emit("change", v)
      },
    },
  },
});
