
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            isBulkImportVisible: false,
            bulkImportFinished: false,
            bulkImportResult: {
                count: 0,
                successful: true,
                names: [],
                error: ""
            }
        };
    },
    methods: {
        onShowBulkImportDialog() {
            this.bulkImportFinished = false
            this.isBulkImportVisible = true
        },
        onClearBulkImportFile() {
            (this.$refs as any)["files"].clear()
        },
        onUploadBulkImportFile() {
            this.bulkImportFinished = false
            let files = (this.$refs as any)["files"].files;
            if (files.length < 1) {
                this.$cx.warn(this.$cx.t('comparison', 'importFileRequired'), this.$cx.t('comparison', 'importNoFileSelected'))
                return
            }
            this.$store
                .dispatch("comparison/bulkImportZip", { files: files })
                .then((result) => {
                    this.$cx.success(this.$cx.t("notify", "saved"), '');
                    this.bulkImportResult = result
                    this.bulkImportFinished = true
                })
                .catch((error) => {
                    this.bulkImportFinished = true
                    this.$cx.error(error, this.$cx.t('comparison', 'importError'))
                });
        }
    },
});
