import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_cx_approval_data_button = _resolveComponent("cx-approval-data-button")!
  const _component_cx_create_button = _resolveComponent("cx-create-button")!
  const _component_cx_edit_button = _resolveComponent("cx-edit-button")!
  const _component_cx_delete_button = _resolveComponent("cx-delete-button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_cx_table_filter = _resolveComponent("cx-table-filter")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    title: _ctx.$t('configuration', 'lb')
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_Button, {
        icon: "pi pi-book",
        class: "align-self-end",
        label: _ctx.$t('configuration', 'openWizard'),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('configurationWizard')))
      }, null, 8, ["label"]),
      _createVNode(_component_cx_approval_data_button, {
        entityType: _ctx.$type.CONFIGURATION,
        route: "configurationEditor"
      }, null, 8, ["entityType"]),
      _createVNode(_component_cx_create_button, {
        route: "configurationEditorNew",
        grant: {
        perm: _ctx.$perm.CONFIGURATION,
        lvl: _ctx.$lvl.WRITE
      }
      }, null, 8, ["grant"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_TabView, null, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, { header: "All" }, {
            default: _withCtx(() => [
              _createVNode(_component_cx_table_filter, {
                stateKey: "config",
                data: _ctx.globalConfigurations,
                config: _ctx.globalConfigurationTableConfig,
                ref: "config-tbl",
                loading: _ctx.$store.state.configuration.loading,
                onFilter: _ctx.load,
                tableStyle: "height: calc(100vh - 135px)"
              }, {
                columns: _withCtx(() => [
                  _createVNode(_component_Column, {
                    header: _ctx.$c('actions'),
                    frozen: "",
                    alignFrozen: "right",
                    headerStyle: "width:110px"
                  }, {
                    filter: _withCtx(() => [
                      _createVNode(_component_Button, {
                        icon: "pi pi-filter-slash",
                        class: "align-self-end p-button-text",
                        onClick: _ctx.onClearFilters
                      }, null, 8, ["onClick"])
                    ]),
                    body: _withCtx((props) => [
                      _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_cx_edit_button, {
                          route: "configurationEditor",
                          id: props.data.id,
                          grant: {
                    perm: _ctx.$perm.CONFIGURATION,
                    lvl: _ctx.$lvl.WRITE
                  }
                        }, null, 8, ["id", "grant"]),
                        _createVNode(_component_cx_delete_button, {
                          onClick: ($event: any) => (_ctx.onDelete(props.data)),
                          grant: {
                    perm: _ctx.$perm.CONFIGURATION,
                    lvl: _ctx.$lvl.WRITE
                  }
                        }, null, 8, ["onClick", "grant"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["header"])
                ]),
                _: 1
              }, 8, ["data", "config", "loading", "onFilter"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}