import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Password = _resolveComponent("Password")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_cx_label, {
      label: _ctx.$c('groupRef'),
      v$: _ctx.v$.entity.groupRef
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          placeholder: _ctx.$c('groupRef_ph'),
          modelValue: _ctx.entity.groupRef,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.groupRef) = $event))
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$c('hostname'),
      v$: _ctx.v$.entity.hostname
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          placeholder: _ctx.$c('hostname_ph'),
          modelValue: _ctx.entity.hostname,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.hostname) = $event))
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$c('ipAddress'),
      v$: _ctx.v$.entity.ipAddress
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          placeholder: _ctx.$c('ipAddress_ph'),
          modelValue: _ctx.entity.ipAddress,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.ipAddress) = $event))
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$c('protocol'),
      v$: _ctx.v$.entity.protocol
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          placeholder: _ctx.$c('protocol_ph'),
          modelValue: _ctx.entity.protocol,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.protocol) = $event))
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$c('username'),
      v$: _ctx.v$.entity.username
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          placeholder: _ctx.$c('username_ph'),
          modelValue: _ctx.entity.username,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.entity.username) = $event))
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$c('password'),
      v$: _ctx.v$.entity.password
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Password, {
          placeholder: _ctx.$c('password_ph'),
          modelValue: _ctx.entity.password,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.entity.password) = $event))
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label", "v$"])
  ]))
}