
import { CreateLocationDeviceRequest, CxEntityType, LocationDeviceDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { CxFormApi } from "@/types/cx-form-api";
import { defineComponent, PropType } from "vue";
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import { ApprovalState } from "@/types/approval-state";

export default defineComponent({
  props: {
    editorApi: {
      type: Object as PropType<CxFormApi>,
    },
    locationId: String,
    approvalState: {
      type: Object as PropType<ApprovalState>,
      default: new ApprovalState(CxEntityType.GLOBAL)
    }
  },
  data() {
    return {
      id: -1 as number,
      entity: {} as LocationDeviceDto,
      initialEntity: {} as LocationDeviceDto,
      serials: ""
    };
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    let v = {
      entity: {
        name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true },
        deviceRole: { required, $autoDirty: true },
        deviceTemplate: { required, $autoDirty: true }
      }
    }
    if (!this.isEditing) {
      v = {
        ...v, ...{
          serials: {
            required
          }
        }
      }
    }
    return v
  },
  watch: {
    entity: {
      handler(updatedEntity) {
        this.entity = updatedEntity;
      },
      deep: true
    }
  },
  computed: {
    isEditing() {
      return this.id > 0;
    },
  },
  created() {
    this.editorApi!.onSave = async (closeFn: any) => {
      if (await this.$cx.notifyValidationError(this.v$)) return false;

      if (this.isEditing) {
        this.$store
          .dispatch("locationDevice/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("locationDevice", "lb"));
            this.initialEntity = this.$cx.getState(this.entity);
            closeFn();
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.entity.location = { id: parseInt(this.locationId!) };
        let createEntity = Object.assign({}, this.entity) as CreateLocationDeviceRequest
        createEntity.serials = this.serials.replaceAll(/ /g, "").split(/[\n,]+/).filter(f => f.trim().length > 0)
        this.$store
          .dispatch("locationDevice/create", createEntity)
          .then((newEntity) => {
            this.$cx.notifyCreated(this.$t("locationDevice", "lb"));
            this.initialEntity = this.$cx.getState(this.entity);
            closeFn(newEntity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    };
    this.editorApi!.load = (id: number) => {
      this.id = id;
      this.$store.dispatch("locationDevice/getById", id).then((entity) => {
        this.entity = entity;
        this.initialEntity = this.$cx.getState(this.entity);
        this.$emit("entity", entity, this.initialEntity);
      });
    };
    this.editorApi!.delete = (id: number) => {
      this.$store.dispatch("locationDevice/delete", id).then(() => {
        this.$cx.notifyDeleted(this.$t("locationDevice", "lb"));
      });
    };
    this.editorApi!.notifyUnsavedChanges = (next: any) => {
      this.$cx.notifyUnsavedChanges(next, this.initialEntity, this.entity);
    }
    this.editorApi!.reset = () => {
      this.entity = {} as LocationDeviceDto;
    };
    this.editorApi!.isEditing = () => {
      return this.id > 0;
    };
  },
  methods: {
    getModels(entity: any) {
      if (entity.deviceTemplateModels == null) return ''
      if (entity.deviceTemplateModels.length < 1) return ''
      return entity.deviceTemplateModels.map((dtm: any) => dtm.deviceModel)
    }
  }
});
