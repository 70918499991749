
import { ApprovalDto, CxApprovalState, CxEntityType } from "./dto"
import { authInstance } from "@/_config/auth"
import globalStore from "@/store-modules"
import { CxPermissionLevel, CxPermission } from "./cx-permission"

class ApprovalState {

    id?: string
    store?: string

    entityType: CxEntityType

    isViewingData = false

    approvalData: ApprovalDto = {
        data: null
    }

    changes: { [key: string]: any } = {}

    constructor(entityType: CxEntityType) {
        this.entityType = entityType
    }

    setStore(store: string, id: string) {
        this.store = store
        this.id = id
    }

    changeState(state: CxApprovalState) {
        this.approvalData.state = state
    }

    setData(data: any) {
        this.approvalData.data = data
    }

    approve() {
        this.changes = {}
        this.approvalData = {
            data: null
        }
    }

    async commit() {
        let response = null
        if (this.approvalData.id == null) {
            if (this.id != null)
                this.approvalData.entityId = parseInt(this.id!)
            this.approvalData.entityType = this.entityType
            response = await globalStore.dispatch("approval/create", this.approvalData)
        } else {
            response = await globalStore.dispatch("approval/save", this.approvalData)
        }

        this.approvalData = response

        if (this.approvalData.state == CxApprovalState.REJECTED) {
            this.changes = {}
            this.approvalData = {
                data: null
            }
        }

        return response
    }

    get enabled() {
        return authInstance.approvalRequired(this.entityType)
    }

    async fetchApprovalData() {
        let response = await globalStore.dispatch(this.store + "/getApproval", { id: this.id })
        this.approvalData = response
        return response
    }

    async fetchNewDataset() {
        try {
            (globalStore.state as any)[this.store!].loading = true;
            let response = await globalStore.dispatch(`approval/getById`, this.id)
            this.approvalData = response
            this.isViewingData = true;
            (globalStore.state as any)[this.store!].loading = false;
            return response
        } catch {
            (globalStore.state as any)[this.store!].loading = false;
        }

    }

    get dataExists() {
        if (!this.enabled) return false
        return this.approvalData.data != null
    }

    get isReview() {
        if (!this.enabled) return false
        if (!this.dataExists) return false
        return this.approvalData.state == CxApprovalState.REVIEW
    }

    get formEnabled() {
        if (!this.enabled) return true
        if (!this.dataExists) return true
        if (this.isReview && this.isViewingData) return true
        return false
    }

    /**
     * Checks if the current user can approve and if the approval is active
     * on this entity
     */
    get canApprove() {
        if (!this.enabled) return false
        if (this.isReview) return false

        switch (this.entityType) {
            case CxEntityType.CODE_TEMPLATE:
                return authInstance.permits(CxPermission.CODE_TEMPLATE, CxPermissionLevel.APPROVAL)
            case CxEntityType.CONFIGURATION:
                return authInstance.permits(CxPermission.CONFIGURATION, CxPermissionLevel.APPROVAL)
            case CxEntityType.LOCATION_DEVICE:
                return authInstance.permits(CxPermission.LOCATION_DEVICE, CxPermissionLevel.APPROVAL)
            case CxEntityType.LOGIN_PROFILE:
                return authInstance.permits(CxPermission.LOGIN_PROFILE, CxPermissionLevel.APPROVAL)
            case CxEntityType.SCHEDULE:
                return authInstance.permits(CxPermission.SCHEDULER_JOB, CxPermissionLevel.APPROVAL)
        }

        return true
    }
}

export { ApprovalState }