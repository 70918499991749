import { ApprovalDto, LocationDto } from "@/types/dto";
import CxApi from "@/types/cx-api";
import { api } from "@/_config/api";

const entityName = "location";
const cxApi = new CxApi<LocationDto>(entityName);

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    async getWithFilter(context: any, props: any) {
      context.state.loading = true
      try {
        let response = await api.get(`${entityName}/filter`, {
          params: {
            ...{
              page: props.page,
              size: props.size,
            },
            ...props.filters,
          },
        });
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getLocationDevicesByLocationId(context: any, locationId: number) {
      try {
        let response = await api.get(`${entityName}/${locationId}/devices`);
        return response.data;
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    async getLocationConfigDiffs(context: any, locationId: number) {
      context.state.loading = true;
      try {
        let response = await api.get(`${entityName}/${locationId}/configs`);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getAll(context: any) {
      context.state.loading = true;
      try {
        let response = await cxApi.getAll();
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getById(context: any, id: number) {
      context.state.loading = true;
      try {
        let response = await cxApi.getById(id);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async save(context: any, entity: LocationDto) {
      context.state.loading = true;
      try {
        let response = await cxApi.save(entity);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async create(context: any, entity: LocationDto) {
      context.state.loading = true;
      try {
        let response = await cxApi.create(entity);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async delete(context: any, props: any) {
      context.state.loading = true;
      try {
        let response = await api.delete(
          `${entityName}/${props.id}?confirm=${props.confirm}`
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    // config
    async generate(context: any, id: number) {
      context.state.loading = true;
      try {
        let response = await api.get(`${entityName}/${id}/generate`);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    // asignment state
    async getState(context: any, id: number) {
      try {
        let response = await api.get(`${entityName}/${id}/state`);
        return response.data;
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },

    // subnets
    async getLocationSubnets(context: any, params: any) {
      try {
        let response = await api.get(
          `${entityName}/${params.locationId}/subnets`,
          {
            params: {
              locationTypeId: params.locationTypeId,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },

    async getImportTemplate(context: any) {
      context.state.loading = true;
      try {
        let response = await api.get(entityName + `/importTemplate`);
        let attachment =
          response.headers["content-disposition"] ||
          response.headers["Content-Disposition"];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(
          attachment!
        )![0];
        filename = filename.split("=")[1].replaceAll('"', "");
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        context.state.loading = false;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async getExport(context: any) {
      context.state.loading = true;
      try {
        let response = await api.get(entityName + `/export?type=CSV`);
        let attachment =
          response.headers["content-disposition"] ||
          response.headers["Content-Disposition"];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(
          attachment!
        )![0];
        filename = filename.split("=")[1].replaceAll('"', "");
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        context.state.loading = false;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async importCSV(context: any, payload: any) {
      try {
        let data = new FormData()
        data.append('delimiter', payload.delimiter)
        data.append('charset', payload.charset)
        data.append('file', payload.file)
        let response = await api.post(`${entityName}/import/csv?verify=${payload.verify}`, data);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },

    async importData(context: any, payload: any) {
      context.state.loading = true;
      try {
        let url = `/import/verify?delimiter=${payload.delimiter}`;
        if (payload.save) {
          url = `/import/save?delimiter=${payload.delimiter}`;
        }
        let response = await api.post(entityName + url, payload.file);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
  },
};
