import { RouteRecordRaw } from "vue-router"

import Location from "./Location.vue";
import LocationEditor from "./LocationEditor.vue";

import LocationCategory from "./category/LocationCategory.vue";
import LocationCategoryEditor from "./category/LocationCategoryEditor.vue";

import LocationType from "./type/LocationType.vue";
import LocationTypeEditor from "./type/LocationTypeEditor.vue";

const locationRoutes: Array<RouteRecordRaw> = [
    // Location
    {
        path: "/location",
        name: "location",
        component: Location,
        meta: { menu: "location" },
    },
    {
        path: "/location/new",
        name: "locationEditorNew",
        component: LocationEditor,
        meta: { menu: "location" },
    },
    {
        path: "/location/:id",
        name: "locationEditor",
        component: LocationEditor,
        meta: { menu: "location" },
        props: true,
    },

    // LocationCategory
    {
        path: "/location/category",
        name: "locationCategory",
        component: LocationCategory,
        meta: { menu: "management" },
    },
    {
        path: "/location/category/new",
        name: "locationCategoryEditorNew",
        component: LocationCategoryEditor,
        meta: { menu: "management" },
    },
    {
        path: "/location/category/:id",
        name: "locationCategoryEditor",
        component: LocationCategoryEditor,
        meta: { menu: "management" },
        props: true,
    },

    // LocationType
    {
        path: "/locationType",
        name: "locationType",
        component: LocationType,
        meta: { menu: "management" },
    },
    {
        path: "/locationType/new",
        name: "locationTypeEditorNew",
        component: LocationTypeEditor,
        meta: { menu: "management" },
    },
    {
        path: "/locationType/:id",
        name: "locationTypeEditor",
        component: LocationTypeEditor,
        meta: { menu: "management" },
        props: true,
    }
]

export default locationRoutes