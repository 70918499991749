import { BLBExtenderDto, StringExistDto } from "@/types/dto";
import { apiExtender } from "@/_config/api-extender";
import { AxiosResponse } from "axios";

const entityName = "extender/api/v1/locationDevice";
const existUri = "extender/api/v1";

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    async getAll(context: any) {
      context.state.loading = true;
      try {
        let response = await apiExtender.get<any, AxiosResponse<any[]>>(
          entityName
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getById(context: any, id: number) {
      context.state.loading = true;
      try {
        let response = await apiExtender.get<any, AxiosResponse<any>>(
          entityName + "/" + id
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async save(context: any, entity: BLBExtenderDto) {
      context.state.loading = true;
      try {
        let response = await apiExtender.put<any, AxiosResponse<any[]>>(
          entityName + "/" + (<any>entity).id,
          entity
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async create(context: any, entity: BLBExtenderDto): Promise<any> {
      context.state.loading = true;
      try {
        let response = await apiExtender.post<any, AxiosResponse<any[]>>(
          entityName,
          entity
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async delete(context: any, id: number) {
      context.state.loading = true;
      try {
        let response = await apiExtender.delete<any, AxiosResponse<any[]>>(
          entityName + "/" + id
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async import(context: any, payload: any) {
      context.state.loading = true;

      let requestParam = new URLSearchParams();
      let uri = "/import";

      if (payload.variable || payload.delimiter) {
        uri = uri + "?";
      }

      if (payload.variable) {
        requestParam.append("variable", payload.variable);
      }

      if (payload.delimiter) {
        requestParam.append("delimiter", payload.delimiter);
      }

      try {
        let response = await apiExtender.post<any, AxiosResponse<any[]>>(
          entityName + uri + requestParam,
          payload.file
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async checkImport(context: any, payload: any): Promise<any> {
      context.state.loading = true;

      let requestParam = new URLSearchParams();
      let uri = "/import";

      if (payload.variable || payload.delimiter) {
        uri = uri + "?";
      }

      if (payload.variable) {
        requestParam.append("variable", payload.variable);
      }

      if (payload.delimiter) {
        requestParam.append("delimiter", payload.delimiter);
      }

      try {
        let response = await apiExtender.post<any, AxiosResponse<any[]>>(
          existUri + "/import",
          payload.file
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
  },
};
