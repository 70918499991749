import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_cx_multi_chip_select = _resolveComponent("cx-multi-chip-select")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_cx_label, {
      label: _ctx.$t('variable', 'lb'),
      v$: _ctx.v$.entity.variable
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_dropdown, {
          placeholder: _ctx.$t('variable', 'ph'),
          modelValue: _ctx.entity.variable,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.variable) = $event)),
          store: "variable",
          optionLabel: "name",
          disabled: _ctx.disabled
        }, null, 8, ["placeholder", "modelValue", "disabled"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('variableLevel', 'lb'),
      v$: _ctx.v$.entity.variableType
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_dropdown, {
          placeholder: _ctx.$t('variableLevel', 'ph'),
          modelValue: _ctx.entity.variableType,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.variableType) = $event)),
          store: "variableType",
          optionLabel: "name",
          onChange: _ctx.onVariableTypeChanged,
          disabled: _ctx.disabled
        }, null, 8, ["placeholder", "modelValue", "onChange", "disabled"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    (_ctx.variableTypeIs(_ctx.variableTypes.DeviceRole))
      ? (_openBlock(), _createBlock(_component_cx_label, {
          key: 0,
          label: _ctx.$t('deviceRole', 'lb_pl')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cx_multi_chip_select, {
              modelValue: _ctx.entity.variableValueRels,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.variableValueRels) = $event)),
              store: "deviceRole",
              placeholder: _ctx.$t('deviceRole', 'ph_pl'),
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "placeholder", "disabled"])
          ]),
          _: 1
        }, 8, ["label"]))
      : (_ctx.variableTypeIs(_ctx.variableTypes.DeviceModel))
        ? (_openBlock(), _createBlock(_component_cx_label, {
            key: 1,
            label: _ctx.$t('deviceModel', 'lb_pl')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cx_multi_chip_select, {
                modelValue: _ctx.entity.variableValueRels,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.variableValueRels) = $event)),
                store: "deviceModel",
                placeholder: _ctx.$t('deviceModel', 'ph_pl'),
                disabled: _ctx.disabled
              }, null, 8, ["modelValue", "placeholder", "disabled"])
            ]),
            _: 1
          }, 8, ["label"]))
        : (_ctx.variableTypeIs(_ctx.variableTypes.DeviceFamily))
          ? (_openBlock(), _createBlock(_component_cx_label, {
              key: 2,
              label: _ctx.$t('deviceFamily', 'lb_pl')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_cx_multi_chip_select, {
                  modelValue: _ctx.entity.variableValueRels,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.entity.variableValueRels) = $event)),
                  store: "deviceFamily",
                  placeholder: _ctx.$t('deviceFamily', 'ph_pl'),
                  disabled: _ctx.disabled
                }, null, 8, ["modelValue", "placeholder", "disabled"])
              ]),
              _: 1
            }, 8, ["label"]))
          : (_ctx.variableTypeIs(_ctx.variableTypes.Manufacturer))
            ? (_openBlock(), _createBlock(_component_cx_label, {
                key: 3,
                label: _ctx.$t('manufacturer', 'lb_pl')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cx_multi_chip_select, {
                    modelValue: _ctx.entity.variableValueRels,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.entity.variableValueRels) = $event)),
                    store: "manufacturer",
                    placeholder: _ctx.$t('manufacturer', 'ph_pl'),
                    disabled: _ctx.disabled
                  }, null, 8, ["modelValue", "placeholder", "disabled"])
                ]),
                _: 1
              }, 8, ["label"]))
            : (_ctx.variableTypeIs(_ctx.variableTypes.Location))
              ? (_openBlock(), _createBlock(_component_cx_label, {
                  key: 4,
                  label: _ctx.$t('location', 'lb_pl')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_cx_multi_chip_select, {
                      modelValue: _ctx.entity.variableValueRels,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.entity.variableValueRels) = $event)),
                      store: "location",
                      placeholder: _ctx.$t('location', 'ph_pl'),
                      disabled: _ctx.disabled
                    }, null, 8, ["modelValue", "placeholder", "disabled"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : (_ctx.variableTypeIs(_ctx.variableTypes.LocationType))
                ? (_openBlock(), _createBlock(_component_cx_label, {
                    key: 5,
                    label: _ctx.$t('locationType', 'lb_pl')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_cx_multi_chip_select, {
                        modelValue: _ctx.entity.variableValueRels,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.entity.variableValueRels) = $event)),
                        store: "locationType",
                        placeholder: _ctx.$t('locationType', 'ph_pl'),
                        disabled: _ctx.disabled
                      }, null, 8, ["modelValue", "placeholder", "disabled"])
                    ]),
                    _: 1
                  }, 8, ["label"]))
                : (_ctx.variableTypeIs(_ctx.variableTypes.LocationDevice))
                  ? (_openBlock(), _createBlock(_component_cx_label, {
                      key: 6,
                      label: _ctx.$t('locationDevice', 'lb_pl')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_cx_multi_chip_select, {
                          modelValue: _ctx.entity.variableValueRels,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.entity.variableValueRels) = $event)),
                          store: "locationDevice",
                          placeholder: _ctx.$t('locationDevice', 'ph_pl'),
                          disabled: _ctx.disabled
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"]))
                  : (_ctx.variableTypeIs(_ctx.variableTypes.LocationCategory))
                    ? (_openBlock(), _createBlock(_component_cx_label, {
                        key: 7,
                        label: _ctx.$t('locationCategory', 'lb_pl')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_cx_multi_chip_select, {
                            modelValue: _ctx.entity.variableValueRels,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.entity.variableValueRels) = $event)),
                            store: "locationCategory",
                            placeholder: _ctx.$t('locationCategory', 'ph_pl'),
                            disabled: _ctx.disabled
                          }, null, 8, ["modelValue", "placeholder", "disabled"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('variableValue', 'lb'),
      v$: _ctx.v$.entity.value
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Textarea, {
          placeholder: _ctx.$t('variableValue', 'ph'),
          modelValue: _ctx.entity.value,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.entity.value) = $event)),
          disabled: _ctx.disabled
        }, null, 8, ["placeholder", "modelValue", "disabled"])
      ]),
      _: 1
    }, 8, ["label", "v$"])
  ]))
}