import { CxEntityType, ComplianceDto } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";
import { api } from "@/_config/api";

class ComplianceStore extends CxBaseStoreModule<ComplianceDto> {

    constructor() {
        super("compliance")
        this.state.entityType = CxEntityType.COMPLIANCE
    }

    defineState(state: any) {
        return super.defineState({

        })
    }

    defineActions(actions: any) {
        return super.defineActions({
            run: async (context: any, id: any) => {
                context.state.loading = true;
                try {
                    let response = await api.get(context.state.entityName + `/${id}/run`);
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            runTest: async (context: any, params: any) => {
                context.state.loading = true;
                try {
                    let response = await api.get(context.state.entityName + `/rule/${params.ruleId}/run/${params.deviceId}`, {
                        params: {
                            sourceId: params.sourceId,
                            targetId: params.targetId
                        }
                    });
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
        })
    }
}

export default new ComplianceStore().createStore()