import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_ctx.approvalState.enabled && _ctx.approvalState.dataExists)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.isNewDataset)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              type: "button",
              label: _ctx.$cx.ternary(_ctx.approvalState.isViewingData, _ctx.$t('approval', 'hide'), _ctx.$t('approval', 'show')) + _ctx.changeCount,
              icon: 'pi pi-' + _ctx.$cx.ternary(_ctx.approvalState.isViewingData, 'eye-slash', 'eye'),
              class: "p-button-warning",
              onClick: _ctx.onToggleApproval
            }, null, 8, ["label", "icon", "onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          onVnodeMounted: _ctx.onQmButtonVisible,
          id: _ctx.qmBtnId,
          icon: "pi pi-question",
          onClick: _ctx.showPanel
        }, null, 8, ["onVnodeMounted", "id", "onClick"]),
        _createVNode(_component_OverlayPanel, {
          class: "cx-approval-dlg",
          ref: "approvalPanel"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('approval', 'required')), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.approvalState.approvalData.comment), 1)
          ]),
          _: 1
        }, 512)
      ]))
    : _createCommentVNode("", true)
}