import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "cx-approval-cards" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "cx-approval-new"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.approvalData.length > 0)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          icon: "pi pi-shield",
          label: _ctx.buttonLabel,
          class: "mr-2 p-button-warning",
          onClick: _ctx.showOverlay
        }, null, 8, ["label", "onClick"]))
      : _createCommentVNode("", true),
    _createVNode(_component_OverlayPanel, {
      class: "cx-approval-dlg",
      ref: "pendingDatasets"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.headerLabel), 1)
        ]),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.approvalData, (approval, a) => {
            return (_openBlock(), _createElementBlock("div", {
              key: a,
              class: _normalizeClass(_ctx.$cx.ternary(approval.entityId == null, 'cx-approval-new-border', ''))
            }, [
              _createElementVNode("p", null, [
                _createElementVNode("b", null, _toDisplayString(_ctx.getTitle(approval)), 1)
              ]),
              (approval.comment)
                ? (_openBlock(), _createElementBlock("p", _hoisted_2, "\"" + _toDisplayString(approval.comment) + "\"", 1))
                : _createCommentVNode("", true),
              _createElementVNode("p", null, _toDisplayString(_ctx.$c('by')) + " " + _toDisplayString(approval.createdBy), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$c('at')) + " " + _toDisplayString(_ctx.$cx.toDate(approval.createdAt)), 1),
              _createVNode(_component_Button, {
                class: "p-button-text",
                icon: "pi pi-window-maximize",
                onClick: ($event: any) => (_ctx.onOpenDataset(approval))
              }, null, 8, ["onClick"]),
              (approval.entityId == null)
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$c('new')), 1))
                : _createCommentVNode("", true)
            ], 2))
          }), 128))
        ])
      ]),
      _: 1
    }, 512)
  ], 64))
}