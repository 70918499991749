import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "cx-json-ed" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.objArr, (obj, k) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "flex mb-2",
        key: k
      }, [
        _createVNode(_component_InputText, {
          modelValue: obj.key,
          "onUpdate:modelValue": ($event: any) => ((obj.key) = $event),
          onChange: _ctx.updateValue,
          disabled: _ctx.disabled
        }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "disabled"]),
        _createVNode(_component_InputText, {
          modelValue: obj.value,
          "onUpdate:modelValue": ($event: any) => ((obj.value) = $event),
          onChange: _ctx.updateValue,
          class: "ml-2",
          disabled: _ctx.disabled
        }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "disabled"]),
        _createVNode(_component_Button, {
          class: "ml-2 align-self-end p-button-primary",
          icon: "pi pi-trash",
          onClick: ($event: any) => (_ctx.onRemove(_ctx.objArr, obj)),
          disabled: _ctx.disabled
        }, null, 8, ["onClick", "disabled"])
      ]))
    }), 128)),
    _createVNode(_component_Button, {
      class: "align-self-end p-button-primary",
      icon: "pi pi-plus",
      label: _ctx.$c('addValue'),
      onClick: _ctx.onAdd,
      disabled: _ctx.disabled
    }, null, 8, ["label", "onClick", "disabled"])
  ]))
}