
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import { ComplianceDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";
import { CxTableConfig, CxTableColumn, CxDataType } from "@/types/cx-table-config";

export default defineComponent({
    props: {
        id: String,
    },
    setup: () => ({ v$: useVuelidate() as any }),
    validations() {
        return {
            entity: {
                name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true }
            },
        };
    },
    data() {
        return {
            entity: {} as ComplianceDto,
            initialState: {} as ComplianceDto,
            expandedRows: {} as any,
            complianceResults: [] as any[]
        };
    },
    mounted() {
        this.load();
    },
    watch: {
        id: function () {
            this.load();
        },
    },
    computed: {
        isEditing() {
            return this.id != null;
        }
    },
    beforeRouteLeave(to: any, from: any, next: any) {
        this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
    },
    methods: {
        load() {
            if (this.isEditing) {
                this.$store
                    .dispatch("compliance/getById", this.id)
                    .then((entity) => {
                        this.entity = entity;
                        this.initialState = this.$cx.getState(this.entity);
                        this.loadComplianceResults()
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
            }
        },
        loadComplianceResults() {
            this.$store
                .dispatch("compliance/run", this.id)
                .then((results) => {
                    this.complianceResults = results
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },
        async save(goBack: Boolean) {
            if (await this.$cx.notifyValidationError(this.v$)) return;

            if (this.isEditing) {
                this.$store
                    .dispatch("compliance/save", this.entity)
                    .then(() => {
                        this.$cx.notifySaved(this.$t("compliance", "lb"));
                        this.initialState = this.$cx.getState(this.entity);
                        if (goBack)
                            this.$cx.goTo("compliance")
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
            } else {
                this.$store
                    .dispatch("compliance/create", this.entity)
                    .then((newEntity: ComplianceDto) => {
                        this.$cx.notifyCreated(this.$t("compliance", "lb"));
                        this.entity = newEntity;
                        this.initialState = this.$cx.getState(this.entity);
                        if (goBack)
                            this.$cx.goTo("compliance")
                        else
                            this.$cx.goToById("complianceEditor", newEntity.id!);
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
            }
        }
    },
});
